import { useState } from "react";
import { Button, Container, Image, Nav, Navbar, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Icon from "@mdi/react";
import { mdiLogout, mdiMenuClose, mdiMenuOpen } from "@mdi/js";

import { screens } from "../../config/screens";
import { useAuth } from "../../context/AuthContext";

type SidebarProps = {
	collapse?: boolean;
};

export default function Sidebar(props: SidebarProps) {
	const { user, handleLogout, routeActive } = useAuth();
	const navigate = useNavigate();

	const [collapse, setCollapse] = useState(props.collapse !== undefined ? props.collapse : false);

	const handleLink = async (url: string) => {
		navigate(url);
	};

	async function handleSair() {
		handleLogout();
		navigate("/");
	}

	return (
		<Navbar expand="md" className="d-flex align-self-stretch p-2 sticky-top" style={{ alignItems: "unset", minWidth: collapse ? "" : 300, maxHeight: "100dvh" }}>
			<Container className="flex-md-column align-items-start px-3 py-1 rounded-3 bg-card mx-0" style={{ maxWidth: "unset" }}>
				<Navbar.Brand href="/" onClick={() => handleLink("/dashboard")} className="d-flex align-items-center pb-md-3 gap-2 m-0">
					<OverlayTrigger trigger={undefined} overlay={!collapse ? <></> : <Tooltip>ReHabi</Tooltip>} placement="right">
						<Image
							fluid
							alt="ReHabi"
							src={process.env.PUBLIC_URL + "/logo.webp"}
							style={!collapse ? { height: 40 } : { height: 40, width: 39, objectFit: "cover", objectPosition: "left" }}
						/>
					</OverlayTrigger>
					<Button
						variant="light"
						className="rounded-circle shadow-sm p-1 position-absolute d-none d-md-inline-block"
						onClick={(e) => {
							e.preventDefault();
							e.stopPropagation();
							setCollapse(!collapse);
						}}
						style={{ right: 0, marginRight: "-0.5rem" }}
					>
						<Icon path={collapse ? mdiMenuClose : mdiMenuOpen} size={1} />
					</Button>
				</Navbar.Brand>
				<Navbar.Toggle className="align-self-center" />
				<Navbar.Collapse className="w-100">
					<Nav className="flex-column mb-auto w-100 h-100 gap-2">
						{screens
							.filter((screen) => screen.showSidebar)
							.filter(
								(screen) =>
									screen.module === null ||
									user?.modules?.some((module) => {
										return module.id === screen.module;
									})
							)
							.map((screen, screenKey) => {
								return (
									<OverlayTrigger key={screenKey} overlay={!collapse ? <></> : <Tooltip>{screen.name}</Tooltip>} placement="right">
										<Nav.Item>
											<Nav.Link
												className={`d-flex gap-2 rounded ${routeActive === screen.route ? "fw-medium" : ""}`}
												active={routeActive === screen.route}
												onClick={() => handleLink(screen.route)}
												style={{ background: routeActive === screen.route ? "#EFEFEF" : "unset" }}
											>
												<Icon path={screen.icon} size={1} /> {!collapse && screen.name}
											</Nav.Link>
										</Nav.Item>
									</OverlayTrigger>
								);
							})}

						<OverlayTrigger overlay={!collapse ? <></> : <Tooltip>Sair</Tooltip>} placement="right">
							<Nav.Item className="d-flex justify-content-between align-items-center mt-auto">
								{!collapse && user?.usuario.nome}
								<Button variant="light" size="sm" onClick={handleSair}>
									<Icon path={mdiLogout} size={1} />
								</Button>
							</Nav.Item>
						</OverlayTrigger>
					</Nav>
				</Navbar.Collapse>
			</Container>
		</Navbar>
	);
}
