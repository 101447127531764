import ApiService from "./ApiService";
import { DependencyDeleteResponse, DependencyGetResponse, DependencyPostRequest, DependencyPostResponse, DependencyPutRequest, DependencyPutResponse } from "../entities/DependencyEntity";

export default class DependencyService extends ApiService {
	public async get(id: number) {
		return await this.request.get<DependencyGetResponse>(`/api/v1/Dependency/${id}`);
	}
	public async post(id: number, data: DependencyPostRequest) {
		return await this.request.post<DependencyPostResponse>(`/api/v1/Dependency`, data, { params: { dependencyGroupId: id } });
	}
	public async put(id: number, data: DependencyPutRequest) {
		return await this.request.put<DependencyPutResponse>(`/api/v1/Dependency/${id}`, data);
	}
	public async delete(id: number) {
		return await this.request.delete<DependencyDeleteResponse>(`/api/v1/Dependency/${id}`);
	}
}
