import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Modal, Spinner } from "react-bootstrap";
import { useQueryClient } from "react-query";
import Icon from "@mdi/react";
import { mdiChevronRight, mdiTrashCanOutline } from "@mdi/js";

import { useToast } from "../../context/ToastContext";
import { DomainEntityGetResponse } from "../../entities/DomainEntity";
import DomainService from "../../services/DomainService";

import ListCard from "../ListCard";

type DominioCardPropsContent = {
	data: DomainEntityGetResponse;
	skeleton?: never;
};

type DominioCardPropsSkeleton = {
	data?: never;
	onClick?: never;
	onRemove?: never;
	skeleton: true;
};

type DominioCardProps = DominioCardPropsContent | DominioCardPropsSkeleton;

const toastTitle = "Domínio";

export default function DominioCard(props: DominioCardProps) {
	const domainService = new DomainService();

	const navigate = useNavigate();
	const queryClient = useQueryClient();
	const { handleToast } = useToast();

	const [removeModal, setRemoveModal] = useState(false);
	const [removeLoadingModal, setRemoveLoadingModal] = useState(false);
	const [removed, setRemoved] = useState(false);

	function handleClick() {
		if (!props.skeleton && props.data) {
			navigate("/dominioFormulario/" + props.data.id);
		}
	}

	function handleClickRemove(e: any) {
		e.stopPropagation();
		setRemoveModal(true);
	}

	async function handleRemove() {
		if (!props.skeleton && props.data) {
			setRemoveLoadingModal(true);
			let resp = await domainService.delete(props.data.id!);
			if (resp.status === 200) {
				queryClient.invalidateQueries(["dominios"]);
				setRemoved(true);
			} else {
				handleToast(toastTitle, resp.statusText, 5000, "danger");
			}
			setRemoveModal(false);
			setRemoveLoadingModal(false);
		}
	}

	function handleCloseModal() {
		if (!removeLoadingModal) {
			setRemoveModal(false);
		}
	}

	let skeletonButton = "";
	if (props.skeleton) {
		skeletonButton = "skeleton skeleton-button";
	}

	if (removed) {
		return <></>;
	}

	return (
		<>
			<ListCard onClick={handleClick}>
				<ListCard.Content>
					<ListCard.Item>
						<ListCard.Title skeleton={props.skeleton}>Nome</ListCard.Title>
						<ListCard.Text skeleton={props.skeleton}>{props.data?.title ?? "..."}</ListCard.Text>
					</ListCard.Item>
					<ListCard.Item>
						<ListCard.Title skeleton={props.skeleton}>Opções</ListCard.Title>
						<ListCard.Text skeleton={props.skeleton}>
							{props.data?.domainOptions
								.flatMap((item) => {
									return item.title;
								})
								.join(", ") ?? "..."}
						</ListCard.Text>
					</ListCard.Item>
				</ListCard.Content>
				<ListCard.Action>
					<Button variant="light" className={`me-2 ${skeletonButton}`} onClick={handleClickRemove}>
						<Icon path={mdiTrashCanOutline} size={1} className="text-danger" />
					</Button>
					<Icon path={mdiChevronRight} size={1} className={`text-muted m-2 ${skeletonButton}`} />
				</ListCard.Action>
			</ListCard>

			<Modal show={removeModal} onHide={handleCloseModal} centered>
				<Modal.Header closeButton>
					<Modal.Title>Remover Domínio</Modal.Title>
				</Modal.Header>
				<Modal.Body className="text-center">{!removeLoadingModal ? "Deseja realmente remover este domínio?" : <Spinner />}</Modal.Body>
				{!removeLoadingModal && (
					<Modal.Footer>
						<Button variant="secondary" onClick={handleCloseModal}>
							Cancelar
						</Button>
						<Button variant="danger" onClick={handleRemove}>
							Remover
						</Button>
					</Modal.Footer>
				)}
			</Modal>
		</>
	);
}
