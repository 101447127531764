import { useState } from "react";
import { Button, Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import Icon from "@mdi/react";
import { mdiCheckboxBlankCircleOutline, mdiCheckboxMarkedCircleOutline, mdiInformation } from "@mdi/js";

import { RespostaType } from "../../entities/QuestionnaireEntity";
import { QuestionEntity } from "../../entities/QuestionEntity";

type FormRadioProps = {
	pergunta: QuestionEntity;
	resposta: RespostaType | null;
	onChange: (value: RespostaType) => void;
};

export default function FormRadio(props: FormRadioProps) {
	const [value, setValue] = useState(props.resposta);

	function handleChange(optionId: number, index: number) {
		let data = { questionId: props.pergunta.id!, optionResponses: value?.optionResponses ?? [] };
		let option = { optionId: optionId, index: index };
		let valueIndex = data.optionResponses.findIndex((item) => {
			return item.index === index;
		});
		if (valueIndex >= 0) {
			data.optionResponses[valueIndex] = option;
		} else {
			data.optionResponses.push(option);
		}
		setValue(data);
		props.onChange(data);
	}

	return (
		<Row>
			{props.pergunta.optionGroups?.map((optionGroup, optionGroupIndex) => {
				return (
					<Col key={optionGroupIndex}>
						{optionGroup.options.map((option) => {
							let checked = value?.optionResponses?.some((item) => {
								return item.optionId === option.id;
							});
							return (
								<Col key={option.id} md={12} className="mb-2">
									<Button
										variant="outline-dark"
										className={`d-flex align-items-center w-100 gap-3 rounded-3 border text-start h-100 ${checked ? "active" : ""}`}
										onClick={() => {
											handleChange(option.id!, optionGroupIndex);
										}}
									>
										<div>
											<Icon path={checked ? mdiCheckboxMarkedCircleOutline : mdiCheckboxBlankCircleOutline} size={1} />
										</div>
										{option.title}
										{option.info && (
											<OverlayTrigger overlay={<Tooltip>{option.info}</Tooltip>}>
												<div className="ms-auto">
													<Icon path={mdiInformation} size={1} />
												</div>
											</OverlayTrigger>
										)}
									</Button>
								</Col>
							);
						})}
					</Col>
				);
			})}
		</Row>
	);
}
