import { ReactNode } from "react";
import { Container } from "react-bootstrap";

import Body from "../Body";
import Main from "../Main";
import Sidebar from "../Sidebar";

type LayoutProps = {
	header?: { exibirAplicativos?: boolean };
	sidebar?: boolean;
	children: ReactNode;
};

export default function Layout({ header, sidebar, children }: LayoutProps) {
	return (
		<Container fluid className="layout p-0" style={{ minHeight: "100vh" }}>
			<Body>
				<Sidebar collapse={sidebar} />
				<Main>{children}</Main>
			</Body>
		</Container>
	);
}
