import { ApiResponseEntity } from "./ApiResponseEntity";
import { QuestionGroupEntity } from "./QuestionGroupEntity";

// QUESTIONNAIRE
export type QuestionnaireEntity = {
	id?: number;
	title: string;
	purpose: string;
	questionGroups: QuestionGroupEntity[];
};

export type QuestionnaireGetQuestionnairesResponse = Omit<QuestionnaireEntity, "questionGroups">;

export type QuestionnaireGetResponse = QuestionnaireEntity;

export type QuestionnairePostRequest = QuestionnaireEntity;

export type QuestionnairePostResponse = QuestionnaireEntity;

export type QuestionnairePutRequest = {
	title: string;
	purpose: string;
};

export type QuestionnairePutResponse = {
	message: string;
};

export type QuestionnaireDeleteResponse = {
	message: string;
};

// OUTROS
export enum PerguntaTipo {
	text = 1,
	textarea = 2,
	number = 3,
	radio = 4,
	checkbox = 5,
	select = 6,
	file = 7,
	image = 8,
	date = 9,
	objective = 10,
	geo = 11,
	parametro = 12,
}

export type PerguntaType = {
	id: number;
	titulo: string;
	texto: string;
	tipo: PerguntaTipo;
	multiple?: boolean;
	opcoes?: PerguntaOpcaoType[][];
	dependencia?: number[][];
	parametroId?: number;
};

export type PerguntaOpcaoType = {
	id: number | null;
	titulo: string;
	valor: string;
	informacoes?: string;
	dominioOptionId?: number;
};

export type RespostaType = {
	perguntaId?: any;
	opcaoId?: any;
	titulo?: string;
	valor?: any;
	contentType?: string;
	lat?: number;
	lng?: number;
	dominioOptionId?: number;

	index?: number;

	questionId: number;
	value?: number;
	text?: string;
	complement?: string;
	optionResponses?: {
		index?: number;
		optionId: number;
	}[];
	files?: {
		name: string;
		file: string;
		contentType: string;
	}[];
};

export type AvaliacaoGetAvaliacaoResponseEntity = Omit<ApiResponseEntity, "Data"> & {
	Data?: {
		id: number;
		titulo: string;
		texto: string;
		grupos: {
			id: number;
			titulo: string;
			texto: string;
			cor: string;
			perguntas: PerguntaType[];
		}[];
	};
};

export type AvaliacaoGetAvaliacaoDetalhesResponseEntity = Omit<ApiResponseEntity, "Data"> & {
	Data?: {
		id: number;
		titulo: string;
		appId: number;
		empresaId: number;
		parametros: {
			id: number;
			secao: string;
			nome: string;
			valor: string;
		}[];
	}[];
};

export type AvaliacaoPostAvaliacaoRespostaRequestEntity = {
	id?: number;
	titulo: string;
	appId: number;
	empresaId: number;
	parametros: {
		id?: number;
		secao: string;
		nome: string;
		valor: string;
	}[];
};

export type AvaliacaoPostAvaliacaoRespostaResponseEntity = Omit<ApiResponseEntity, "Data"> & {
	Data?: {
		id: number;
		titulo: string;
		appId: number;
		empresaId: number;
		parametros: {
			id: number;
			secao: string;
			nome: string;
			valor: string;
		}[];
	}[];
};

// PARAMETRO
export type AvaliacaoParametroEntity = {
	id: number;
	titulo: string;
	importanciaTecnica: number | null;
	importanciaFuncional: number | null;
	valorMaximo: number | null;
	opcoes: PerguntaOpcaoType[][];
};

export type AvaliacaoGetParametrosResponseEntity = AvaliacaoParametroEntity[];

export type AvaliacaoGetParametroResponseEntity = AvaliacaoParametroEntity;

export type AvaliacaoPostParametroRequestEntity = AvaliacaoParametroEntity;

export type AvaliacaoPostParametroResponseEntity = AvaliacaoParametroEntity;

// DOMINIO
export type AvaliacaoDominioEntity = {
	id: number;
	titulo: string;
	opcoes: PerguntaOpcaoType[];
};

export type AvaliacaoGetDominiosResponseEntity = AvaliacaoDominioEntity[];

export type AvaliacaoGetDominioResponseEntity = AvaliacaoDominioEntity;

export type AvaliacaoPostDominioRequestEntity = AvaliacaoDominioEntity;

export type AvaliacaoPostDominioResponseEntity = AvaliacaoDominioEntity;
