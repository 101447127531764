import { useState } from "react";
import { Form } from "react-bootstrap";

import { RespostaType } from "../../entities/QuestionnaireEntity";
import { QuestionEntity } from "../../entities/QuestionEntity";

type FormTextProps = {
	pergunta: QuestionEntity;
	resposta: RespostaType | null;
	onChange: (value: RespostaType) => void;
};

export default function FormText(props: FormTextProps) {
	const [value, setValue] = useState(props.resposta);

	function handleChange(value: string) {
		let data = { questionId: props.pergunta.id!, text: value };
		setValue(data);
		props.onChange(data);
	}

	return (
		<Form.Control
			type="text"
			className="bg-white"
			value={value?.text}
			onChange={(e) => {
				handleChange(e.currentTarget.value);
			}}
			autoFocus={true}
		/>
	);
}
