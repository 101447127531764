import ApiService from "./ApiService";
import { PersonDeleteResponse, PersonGetPersonsResponse, PersonGetResponse, PersonPostRequest, PersonPostResponse, PersonPutRequest, PersonPutResponse } from "../entities/PersonEntity";

export default class PersonService extends ApiService {
	public async getPersons() {
		return await this.request.get<PersonGetPersonsResponse>(`/api/v1/Persons`);
	}
	public async get(id: number) {
		return await this.request.get<PersonGetResponse>(`/api/v1/Person/${id}`);
	}
	public async post(data: PersonPostRequest) {
		return await this.request.post<PersonPostResponse>(`/api/v1/Person`, data);
	}
	public async put(id: number, data: PersonPutRequest) {
		return await this.request.put<PersonPutResponse>(`/api/v1/Person/${id}`, data);
	}
	public async delete(id: number) {
		return await this.request.delete<PersonDeleteResponse>(`/api/v1/Person/${id}`);
	}
}
