import { useEffect, useRef, useState } from "react";
import { Alert, Button, Card, Col, Container, FloatingLabel, Form, Image, InputGroup, Row, Spinner } from "react-bootstrap";
import { Navigate, useLocation, useNavigate } from "react-router-dom";

import { useAuth } from "../../context/AuthContext";
import { useToast } from "../../context/ToastContext";
import Icon from "@mdi/react";
import { mdiEye, mdiEyeOff } from "@mdi/js";

export default function Login() {
	const { user, handleLogin } = useAuth();
	const { clearToast } = useToast();

	const location = useLocation();
	const navigate = useNavigate();

	const emailRef = useRef<HTMLInputElement>() as React.RefObject<HTMLInputElement>;
	const passwordRef = useRef<HTMLInputElement>() as React.RefObject<HTMLInputElement>;

	const [loading, setLoading] = useState(false);
	const [message, setMessage] = useState("");
	const [senhaExibir, setSenhaExibir] = useState(false);

	useEffect(() => {
		clearToast();
		// eslint-disable-next-line
	}, []);

	async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
		event.preventDefault();

		if (!emailRef.current || !passwordRef.current) {
			return false;
		}

		setLoading(true);
		setMessage("");

		let response = await handleLogin(emailRef.current.value, passwordRef.current.value);
		if (response === true) {
			navigate("/dashboard");
		} else {
			setLoading(false);
			setMessage(response);
		}
	}

	if (user) {
		return <Navigate to="/dashboard" state={{ from: location }} replace />;
	}

	return (
		<Container fluid id="login-container">
			<Row className="justify-content-center align-items-center" style={{ minHeight: "100vh" }}>
				<Col lg={6} style={{ maxWidth: 500 }}>
					<Card className="p-4 shadow bg-white rounded-3">
						<Card.Body>
							<Image fluid className="pb-5 ps-4 pe-4" src={process.env.PUBLIC_URL + "/logo.webp"} />
							<Form onSubmit={handleSubmit}>
								<FloatingLabel controlId="usuario" label="Usuário" className="mb-3">
									<Form.Control type="text" placeholder="Informe aqui o usuário" ref={emailRef} required />
								</FloatingLabel>

								<InputGroup className="mb-3">
									<FloatingLabel controlId="senha" label="Senha">
										<Form.Control type={senhaExibir ? "text" : "password"} placeholder="Informe aqui a senha" ref={passwordRef} required />
									</FloatingLabel>
									<Button
										variant="light"
										className="border"
										onClick={() => {
											setSenhaExibir(!senhaExibir);
										}}
									>
										<Icon path={senhaExibir ? mdiEye : mdiEyeOff} size={1} />
									</Button>
								</InputGroup>

								{message && (
									<Alert variant="danger" className="text-center">
										{message}
									</Alert>
								)}

								<Button type="submit" variant="primary" size="lg" className="w-100" disabled={loading}>
									{loading ? (
										<>
											<Spinner animation="border" size="sm" className="me-2" /> Entrando
										</>
									) : (
										"Entrar"
									)}
								</Button>
							</Form>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</Container>
	);
}
