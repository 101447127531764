import { PerguntaTipo } from "../entities/QuestionnaireEntity";

export type Unpacked<T> = T extends (infer U)[] ? U : T;

export const FormTipos = [
	{ label: "Texto", value: PerguntaTipo.text },
	{ label: "Texto múltiplas linhas", value: PerguntaTipo.textarea },
	{ label: "Número", value: PerguntaTipo.number },
	{ label: "Seleção única", value: PerguntaTipo.radio },
	{ label: "Seleção múltipla", value: PerguntaTipo.checkbox },
	{ label: "Seleção", value: PerguntaTipo.select },
	{ label: "Arquivo", value: PerguntaTipo.file },
	{ label: "Imagem", value: PerguntaTipo.image },
	{ label: "Data", value: PerguntaTipo.date },
	{ label: "Objetiva", value: PerguntaTipo.objective },
	{ label: "Geolocalização", value: PerguntaTipo.geo },
	{ label: "Parametro", value: PerguntaTipo.parametro },
];

export const SkeletonCount = 10;
export const PageSize = 10;
export const Location = { lat: -30.033056, lng: -51.23 };
export const DateFormat = "DD/MMM/YYYY";
export const DateTimeFormat = "DD/MMM/YYYY HH:mm:ss";

export enum ModuleEntity {
	acessos = 1001,
	configuracoes = 1002,
	configuracoesAvaliacoes = 1003,
	configuracoesParametros = 1004,
	configuracoesDominios = 1005,
	avaliacoes = 1006,
}

export enum ModulePermissionEntity {
	visualizar = 9991,
	editar = 9992,
	teste = 9999,
}
