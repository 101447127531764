import { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { screens } from "../config/screens";
import { UserLoginPostResponseEntity } from "../entities/UserEntity";
import UserService from "../services/UserService";
import axios, { AxiosError } from "axios";

type AuthContextProps = {
	user: UserLoginPostResponseEntity | undefined;
	aplicativo: any;
	routeActive: string;
	geolocation: GeolocationPosition | undefined;
	handleLogin: (email: string, password: string) => Promise<true | string>;
	handleLogout: () => void;
	handleAplicativo: (id: number) => void;
};

const AuthContext = createContext<AuthContextProps>({} as AuthContextProps);

export function useAuth() {
	return useContext(AuthContext);
}

type AuthProviderProps = {
	children: ReactNode;
};

const locations = screens
	.filter((item) => {
		return item.showSidebar;
	})
	.map((item) => {
		return item.route;
	});

export default function AuthProvider({ children }: AuthProviderProps) {
	const userService = new UserService();

	const location = useLocation();
	const navigate = useNavigate();

	const [loading, setLoading] = useState(true);
	const [user, setUser] = useState<UserLoginPostResponseEntity | undefined>();
	const [aplicativo, setAplicativo] = useState<number>(1);
	const [routeActive, setRouteActive] = useState<string>("");
	const [geolocation, setGeolocation] = useState<GeolocationPosition>();

	useEffect(() => {
		if (locations.includes(location.pathname)) {
			setRouteActive(location.pathname);
		}
	}, [location]);

	useEffect(() => {
		let sessionUser = sessionStorage.getItem("user");
		if (sessionUser) {
			let userJson = JSON.parse(sessionUser);
			setUser(userJson);
		}

		let sessionAplicativo = sessionStorage.getItem("aplicativo");
		if (sessionAplicativo) {
			let aplicativoInt = parseInt(sessionAplicativo);
			setAplicativo(aplicativoInt);
		}

		setLoading(false);
	}, []);

	useEffect(() => {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition((position) => {
				setGeolocation(position);
			});
			navigator.geolocation.watchPosition((position) => {
				setGeolocation(position);
			});
		}
	}, []);

	async function handleLogin(email: string, password: string) {
		let data = {
			login: email,
			senha: password,
			appVersion: "0.0.1",
			deviceToken: "",
			deviceType: 0,
			osVersion: "",
		};

		try {
			let response = await userService.login(data);
			if (response.status === 200 && response.data) {
				sessionStorage.setItem("user", JSON.stringify(response.data));
				setUser(response.data);
				return true;
			}
		} catch (error: any) {
			if (axios.isAxiosError(error)) {
				const axiosError = error as AxiosError<any>;
				if (axiosError.response) {
					return axiosError.response.data.Message;
				}
				return axiosError.message;
			}
			return error.message;
		}
	}

	function handleLogout() {
		setUser(undefined);
		sessionStorage.removeItem("user");
		navigate("/");
	}

	async function handleAplicativo(id: number) {
		sessionStorage.setItem("aplicativo", id.toString());
		setAplicativo(id);
	}

	const value = {
		user,
		aplicativo,
		routeActive,
		geolocation,
		handleLogin,
		handleLogout,
		handleAplicativo,
	};

	if (loading) {
		return <></>;
	}

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
