import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Form, Button, ButtonGroup, Card, Col, OverlayTrigger, Row, Spinner, Tooltip } from "react-bootstrap";
import { AxiosError, AxiosResponse } from "axios";
import Icon from "@mdi/react";
import { mdiAsterisk, mdiCheck, mdiCheckCircle, mdiChevronLeft, mdiCircleOutline, mdiCircleSlice8, mdiOfficeBuildingOutline, mdiRayStartArrow, mdiRayStartEnd, mdiRayStartVertexEnd } from "@mdi/js";
import { useMutation, useQuery } from "react-query";

import { useToast } from "../../context/ToastContext";
import { useAuth } from "../../context/AuthContext";

import { QuestionnaireResponseEntity, QuestionnaireResponsePostRequest, QuestionnaireResponsePostResponse } from "../../entities/QuestionnaireResponseEntity";
import { PerguntaTipo, QuestionnaireGetResponse, RespostaType } from "../../entities/QuestionnaireEntity";
import { QuestionGroupEntity } from "../../entities/QuestionGroupEntity";
import { QuestionEntity } from "../../entities/QuestionEntity";
import QuestionnaireResponseService from "../../services/QuestionnaireResponseService";
import QuestionnaireService from "../../services/QuestionnaireService";

import FormObjective from "../../components/FormObjective";
import FormParametro from "../../components/FormParametro";
import FormTextarea from "../../components/FormTextarea";
import FormNumber from "../../components/FormNumber";
import FormRadio from "../../components/FormRadio";
import FormCheck from "../../components/FormCheck";
import FormImage from "../../components/FormImage";
import FormText from "../../components/FormText";
import FormFile from "../../components/FormFile";
import FormDate from "../../components/FormDate";
import FormGeo from "../../components/FormGeo";
import Layout from "../../components/Layout";

const toastTitle = "Avaliação";

export default function AvaliacaoFormulario() {
	const questionnaireService = new QuestionnaireService();
	const questionnaireResponseService = new QuestionnaireResponseService();

	const navigate = useNavigate();
	const { handleToast } = useToast(toastTitle);
	const { geolocation } = useAuth();
	const { questionnaireId, id } = useParams();

	const [formStatus, setFormStatus] = useState(false);
	const [formSaving, setFormSaving] = useState(false);
	const [formStage, setFormStage] = useState<{ grupo: number | null; pergunta: number | null }>({ grupo: null, pergunta: null });
	const [formRespostas, setFormRespostas] = useState<RespostaType[]>([]);

	const { data, isLoading, isFetching, isRefetching } = useQuery<QuestionnaireGetResponse>(["avaliacao", questionnaireId], () => fetchData(Number(questionnaireId)), {
		refetchOnWindowFocus: false,
	});
	const queryResponse = useQuery<QuestionnaireResponseEntity>(["avaliacaoResposta", id], () => fetchDataResponse(Number(id)), { enabled: id !== undefined, refetchOnWindowFocus: false });

	const mutationPost = useMutation(mutatePost, { onSuccess: mutatePostSuccess, onError: mutateError });

	async function fetchData(id: number) {
		let resp = await questionnaireService.get(id);
		return resp.data;
	}

	async function fetchDataResponse(id: number) {
		let resp = await questionnaireResponseService.get(id);

		for (let i in resp.data.questionResponses) {
			let questionResponse = resp.data.questionResponses[i];
			if (questionResponse.optionResponses) {
				for (let j in questionResponse.optionResponses) {
					let optionResponse = questionResponse.optionResponses[j];
					let index = 0;
					data?.questionGroups.map((questionGroup) => {
						questionGroup.questions.map((question) => {
							if (question.id === questionResponse.questionId) {
								question.optionGroups.map((optionGroup, optionGroupIndex) => {
									if (
										optionGroup.options.some((option) => {
											return option.id === optionResponse.optionId;
										})
									) {
										index = optionGroupIndex;
									}
								});
							}
						});
					});

					resp.data.questionResponses[i].optionResponses![j].index = index;
				}
			}
		}

		setFormRespostas(resp.data.questionResponses);

		return resp.data;
	}

	async function mutatePost(data: QuestionnaireResponsePostRequest) {
		setFormSaving(true);
		return await questionnaireResponseService.post(Number(questionnaireId), data);
	}

	function mutatePostSuccess(resp: AxiosResponse<QuestionnaireResponsePostResponse, any>) {
		handleToast(toastTitle, "Informações salvas com sucesso!", 5000);
		navigate("/avaliacaoDetalhes/" + resp.data.id);
		setFormStatus(false);
		setFormSaving(false);
	}

	function mutateError(resp: AxiosError) {
		handleToast(toastTitle, `Problema na operação: ${resp.message}`, 5000, "danger");
		setFormSaving(false);
	}

	function handlePrevious() {
		if (formStage.grupo === null) {
			return;
		}
		if (formStage.pergunta === null) {
			if (formStage.grupo === 0) {
				setFormStage({ grupo: null, pergunta: null });
			} else {
				setFormStage({ grupo: formStage.grupo! - 1, pergunta: data!.questionGroups[formStage.grupo! - 1].questions.length - 1 });
			}
		} else if (formStage.pergunta === 0) {
			setFormStage({ grupo: formStage.grupo, pergunta: null });
		} else {
			setFormStage({ grupo: formStage.grupo, pergunta: formStage.pergunta! - 1 });
		}
	}

	function handleFormNext() {
		let proxima = handleFormDependencies(formStage.grupo!, formStage.pergunta !== null ? formStage.pergunta + 1 : 0);

		if (proxima.grupo === null && proxima.pergunta === null) {
			setFormStatus(true);
		}

		setFormStage({ grupo: proxima.grupo, pergunta: proxima.pergunta });
	}

	function handleFormDependencies(
		grupoIndex: number,
		perguntaIndex: number
	): {
		grupo: number | null;
		pergunta: number | null;
	} {
		let grupo = data!.questionGroups[grupoIndex];
		if (!grupo) {
			return {
				grupo: null,
				pergunta: null,
			};
		}

		let pergunta = grupo.questions[perguntaIndex];
		if (!pergunta) {
			let resp = handleFormDependencies(grupoIndex + 1, 0);
			if (resp.grupo !== null && resp.pergunta !== null) {
				return {
					grupo: resp.grupo,
					pergunta: null,
				};
			}
			return resp;
		}

		let dependenciesCheck = grupo.questions.some((question) => {
			if (question.dependencyGroups) {
				return question.dependencyGroups.every((dependencyGroup) => {
					if (dependencyGroup.dependencies) {
						return dependencyGroup.dependencies.some((dependency) => {
							return formRespostas.some((resposta) => {
								return resposta.optionResponses?.some((response) => {
									return response.optionId === dependency.optionId;
								});
							});
						});
					}
					return true;
				});
			}
			return true;
		});

		if (!dependenciesCheck) {
			return handleFormDependencies(grupoIndex, perguntaIndex + 1);
		}

		return {
			grupo: grupoIndex,
			pergunta: perguntaIndex,
		};
	}

	function handleQuestionGroupDependenciesCheck(grupo: QuestionGroupEntity) {
		return grupo.questions.some((question) => {
			if (question.dependencyGroups) {
				return question.dependencyGroups.every((dependencyGroup) => {
					if (dependencyGroup.dependencies) {
						return dependencyGroup.dependencies.some((dependency) => {
							return formRespostas.some((resposta) => {
								return resposta.optionResponses?.some((response) => {
									return response.optionId === dependency.optionId;
								});
							});
						});
					}
					return true;
				});
			}
			return true;
		});
	}

	function handleQuestionDependenciesCheck(question: QuestionEntity) {
		return question.dependencyGroups.every((dependencyGroup) => {
			if (dependencyGroup.dependencies) {
				return dependencyGroup.dependencies.some((dependency) => {
					return formRespostas.some((resposta) => {
						return resposta.optionResponses?.some((response) => {
							return response.optionId === dependency.optionId;
						});
					});
				});
			}
			return true;
		});
	}

	function handleFormText(value: RespostaType) {
		setFormRespostas((p) => {
			let respostas = [...p];
			let respostaIndex = p.findIndex((item) => {
				return item.questionId === value.questionId;
			});
			if (respostaIndex >= 0) {
				respostas[respostaIndex] = value;
			} else {
				respostas.push(value);
			}
			return respostas;
		});
	}

	function handleFormNumber(value: RespostaType) {
		setFormRespostas((p) => {
			let respostas = [...p];
			let respostaIndex = p.findIndex((item) => {
				return item.questionId === value.questionId;
			});
			if (respostaIndex >= 0) {
				respostas[respostaIndex] = value;
			} else {
				respostas.push(value);
			}
			return respostas;
		});
	}

	function handleFormTextarea(value: RespostaType) {
		setFormRespostas((p) => {
			let respostas = [...p];
			let respostaIndex = p.findIndex((item) => {
				return item.questionId === value.questionId;
			});
			if (respostaIndex >= 0) {
				respostas[respostaIndex] = value;
			} else {
				respostas.push(value);
			}
			return respostas;
		});
	}

	function handleFormRadio(value: RespostaType) {
		setFormRespostas((p) => {
			let respostas = [...p];
			let respostaIndex = p.findIndex((item) => {
				return item.questionId === value.questionId;
			});
			if (respostaIndex >= 0) {
				respostas[respostaIndex] = value;
			} else {
				respostas.push(value);
			}
			return respostas;
		});
		if (value.optionResponses?.length === perguntaAtual!.optionGroups?.length) {
			handleFormNext();
		}
	}

	function handleFormCheck(value: RespostaType) {
		setFormRespostas((p) => {
			let respostas = [...p];
			let respostaIndex = p.findIndex((item) => {
				return item.questionId === value.questionId;
			});
			if (respostaIndex >= 0) {
				respostas[respostaIndex] = value;
			} else {
				respostas.push(value);
			}
			return respostas;
		});
	}

	function handleFormObjective(value: RespostaType) {
		setFormRespostas((p) => {
			let respostas = [...p];
			let respostaIndex = p.findIndex((item) => {
				return item.questionId === value.questionId;
			});
			if (respostaIndex >= 0) {
				respostas[respostaIndex] = value;
			} else {
				respostas.push(value);
			}
			return respostas;
		});
		handleFormNext();
	}

	function handleFormGeoChange(value: RespostaType) {
		setFormRespostas((p) => {
			let respostas = [...p];
			let respostaIndex = p.findIndex((item) => {
				return item.questionId === value.questionId;
			});
			if (respostaIndex >= 0) {
				respostas[respostaIndex] = value;
			} else {
				respostas.push(value);
			}
			return respostas;
		});
	}

	function handleFormParametro(value: RespostaType) {
		setFormRespostas((p) => {
			let respostas = [...p];
			let respostaIndex = p.findIndex((item) => {
				return item.questionId === value.questionId;
			});
			if (respostaIndex >= 0) {
				respostas[respostaIndex] = value;
			} else {
				respostas.push(value);
			}
			return respostas;
		});
		if (value.optionResponses?.length === perguntaAtual!.optionGroups?.length) {
			handleFormNext();
		}
	}

	function handleFormDate(value: RespostaType) {
		setFormRespostas((p) => {
			let respostas = [...p];
			let respostaIndex = p.findIndex((item) => {
				return item.questionId === value.questionId;
			});
			if (respostaIndex >= 0) {
				respostas[respostaIndex] = value;
			} else {
				respostas.push(value);
			}
			return respostas;
		});
	}

	function handleFormFileChange(value: RespostaType) {
		setFormRespostas((p) => {
			let respostas = [...p];
			let respostaIndex = p.findIndex((item) => {
				return item.questionId === value.questionId;
			});
			if (respostaIndex >= 0) {
				respostas[respostaIndex] = value;
			} else {
				respostas.push(value);
			}
			return respostas;
		});
		if (value.files?.length === perguntaAtual!.maxAnswers) {
			handleFormNext();
		}
	}

	function handleFormImageChange(value: RespostaType) {
		setFormRespostas((p) => {
			let respostas = [...p];
			let respostaIndex = p.findIndex((item) => {
				return item.questionId === value.questionId;
			});
			if (respostaIndex >= 0) {
				respostas[respostaIndex] = value;
			} else {
				respostas.push(value);
			}
			return respostas;
		});
		if (value.files?.length === perguntaAtual!.maxAnswers) {
			handleFormNext();
		}
	}

	let grupoAtual = null;
	if (formStage.grupo !== null && data?.questionGroups[formStage.grupo]) {
		grupoAtual = data.questionGroups[formStage.grupo];
	}
	let perguntaAtual: QuestionEntity | null = null;
	let perguntaAtualResposta: RespostaType | null = null;
	let perguntaAtualNext = true;
	if (formStage.grupo !== null && formStage.pergunta !== null && data?.questionGroups[formStage.grupo].questions[formStage.pergunta]) {
		perguntaAtual = data.questionGroups[formStage.grupo].questions[formStage.pergunta];
		let perguntaId = data.questionGroups[formStage.grupo].questions[formStage.pergunta].id;
		perguntaAtualResposta =
			formRespostas.find((item) => {
				return item.questionId === perguntaId;
			}) ?? null;
		if (perguntaAtual.required) {
			perguntaAtualNext = false;
			if (perguntaAtualResposta) {
				perguntaAtualNext = true;
				if (perguntaAtualResposta.optionResponses && perguntaAtual.optionGroups) {
					perguntaAtualNext = perguntaAtualResposta.optionResponses.length === perguntaAtual.optionGroups.length;
					if (perguntaAtual.maxAnswers) {
						perguntaAtualNext = perguntaAtualResposta.optionResponses.length > 0;
					}
				}
			}
		}
	}

	return (
		<Layout sidebar={true}>
			<h5 className="mt-4 mb-4 d-flex align-items-center fw-light">
				<Link to={"/avaliacoes"} className="d-flex align-items-center text-decoration-none">
					<Icon path={mdiChevronLeft} size={1} className="me-1" /> <Icon path={mdiOfficeBuildingOutline} size={1} className="me-1" /> Formulário de Avaliação
				</Link>
				{(isLoading || isFetching || isRefetching) && <Spinner size="sm" className="ms-1" variant="secondary" />}
			</h5>

			<Form
				onSubmit={(e) => {
					e.preventDefault();
					if (id) {
						// mutationPut.mutate(values);
					} else {
						mutationPost.mutate({
							geoLocation: {
								latitude: geolocation!.coords.latitude,
								longitude: geolocation!.coords.longitude,
							},
							personId: 1,
							questionResponses: formRespostas.map((resposta) => {
								return { ...resposta, optionResponses: resposta.optionResponses ?? [] };
							}),
						});
					}
				}}
			>
				{/* FORMULÁRIO */}
				{!formStatus && (
					<>
						{/* AVALIAÇÃO */}
						{grupoAtual === null && (
							<Row className="mb-4">
								<Col md={12}>
									<Card className="mb-4 rounded-3 shadow-sm">
										<Card.Body className="d-flex flex-column justify-content-center px-5 pb-4">
											<div className={`h2 mb-4 text-center ${isLoading ? "skeleton skeleton-text skeleton-text-title skeleton-center" : ""}`}>
												{isLoading ? "..." : data?.title}
											</div>
											<div className={`fw-light text-center fs-5 ${isLoading ? "skeleton skeleton-text skeleton-text-content skeleton-center" : ""}`}>
												{isLoading ? (
													<>
														<br />
														<br />
														<br />
														<br />
													</>
												) : (
													data?.purpose
												)}
											</div>
										</Card.Body>
									</Card>
									{!isLoading && !queryResponse.isLoading && (
										<div className="d-flex justify-content-center">
											<Button
												size="lg"
												className="d-flex justify-content-center align-items-center gap-3 text-uppercase shadow-sm"
												onClick={() => {
													setFormStage({ grupo: 0, pergunta: null });
												}}
												style={{ width: 180 }}
											>
												{id ? "Editar" : "Começar"}
												<Icon path={mdiRayStartVertexEnd} size={1} />
											</Button>
										</div>
									)}
								</Col>
							</Row>
						)}

						{/* AVALIAÇÃO GRUPO / PERGUNTA */}
						{grupoAtual !== null && (
							<Row className="mb-4">
								{/* AVALIAÇÃO CABEÇALHO */}
								<Col md={12}>
									<Card className="mb-4 rounded-3">
										<Card.Body
											className="h2 text-center p-3 mb-0"
											onClick={() => {
												setFormStage({ grupo: null, pergunta: null });
											}}
											style={{ cursor: "pointer" }}
										>
											{data?.title}
										</Card.Body>
									</Card>
								</Col>

								{/* SUMARIO */}
								<Col md={3}>
									<Card className="mb-4 rounded-3 position-sticky overflow-auto" style={{ top: "0.5rem", maxHeight: "calc(100dvh - 1rem)" }}>
										<Card.Body className="d-flex flex-column">
											{data?.questionGroups &&
												data?.questionGroups.map((questionGroup, questionGroupIndex) => {
													let dependenciesCheck = handleQuestionGroupDependenciesCheck(questionGroup);
													if (!dependenciesCheck) {
														return null;
													}

													let response = questionGroup.questions.some((question) => {
														return formRespostas.some((resposta) => {
															return resposta.questionId === question.id;
														});
													});
													let active = questionGroupIndex === formStage.grupo;
													let enabled = questionGroupIndex < formStage.grupo! || response;
													let icon = mdiCircleOutline;
													let text = "text-gray-500";
													if (active) {
														icon = mdiCircleSlice8;
														text = "";
													} else if (enabled) {
														icon = mdiCheckCircle;
														text = "";
													}

													return (
														<div key={questionGroupIndex} className="d-flex flex-column">
															<div
																className={`d-flex align-items-center p-1 rounded-3 text-decoration-none ${active ? "bg-nav-active" : ""} ${text}`}
																onClick={() => {
																	setFormStage({ grupo: questionGroupIndex, pergunta: null });
																}}
																style={{ cursor: "pointer" }}
															>
																<Icon path={icon} size={0.8} style={{ color: questionGroup.color }} className="me-1" />
																<div style={{ flexBasis: 0, flexGrow: 1 }}>{questionGroup.title}</div>
															</div>
															{questionGroup.questions.map((question, questionIndex) => {
																let dependenciesCheck = handleQuestionDependenciesCheck(question);
																if (!dependenciesCheck) {
																	return null;
																}

																let response = formRespostas.some((resposta) => {
																	return resposta.questionId === question.id;
																});
																let active = questionGroupIndex === formStage.grupo && questionIndex === formStage.pergunta;
																let enabled =
																	questionGroupIndex < formStage.grupo! ||
																	(questionGroupIndex === formStage.grupo! && questionIndex <= formStage.pergunta!) ||
																	response;
																let icon = mdiCircleOutline;
																let text = "text-gray-500";
																if (active) {
																	icon = mdiCircleSlice8;
																	text = "";
																} else if (enabled) {
																	icon = mdiCheckCircle;
																	text = "";
																}

																return (
																	<div
																		key={questionIndex}
																		className={`d-flex align-items-center fw-light small p-1 rounded-3 text-decoration-none ${
																			active ? "bg-nav-active" : ""
																		} ${text}`}
																		onClick={() => {
																			setFormStage({ grupo: questionGroupIndex, pergunta: questionIndex });
																		}}
																		style={{ cursor: "pointer" }}
																	>
																		<Icon path={icon} size={0.8} style={{ color: questionGroup.color }} className="me-1" />
																		<div style={{ flexBasis: 0, flexGrow: 1 }}>{question.title}</div>
																	</div>
																);
															})}
														</div>
													);
												})}
										</Card.Body>
									</Card>
								</Col>

								{/* GRUPO */}
								{perguntaAtual === null && (
									<Col>
										<Card className="mb-4 rounded-3 shadow-sm" style={{ borderWidth: 4, borderStyle: "solid", borderColor: grupoAtual.color }}>
											<Card.Body className="d-flex flex-column justify-content-center p-5">
												<div className="h2 mb-4 text-center">{grupoAtual.title}</div>
												<div className="fw-light text-center text-muted mb-3">{grupoAtual.text}</div>
											</Card.Body>
										</Card>
										<div className="d-flex justify-content-center">
											<ButtonGroup size="lg" className="shadow-sm">
												<Button
													variant="light"
													className="d-flex justify-content-center align-items-center gap-3 text-uppercase"
													onClick={handlePrevious}
													style={{ width: 180 }}
												>
													<Icon path={mdiRayStartArrow} size={1} style={{ transform: "scaleX(-1)" }} />
													Voltar
												</Button>
												<Button className="d-flex justify-content-center align-items-center gap-3 text-uppercase" onClick={handleFormNext} style={{ width: 180 }}>
													Continuar
													<Icon path={mdiRayStartArrow} size={1} />
												</Button>
											</ButtonGroup>
										</div>
									</Col>
								)}

								{/* PERGUNTA */}
								{perguntaAtual !== null && (
									<Col>
										{/* PERGUNTA */}
										<Card className="mb-4 rounded-3 shadow-sm" style={{ borderTopWidth: 4, borderTopStyle: "solid", borderTopColor: grupoAtual.color }}>
											<Card.Body className="p-4">
												<div className="d-flex justify-content-center align-items-center h4 mb-2 text-center fw-normal">
													{perguntaAtual.title}
													{perguntaAtual.required === true && (
														<OverlayTrigger overlay={<Tooltip>É obrigatório responder</Tooltip>}>
															<div className="d-flex justify-content-center">
																<Icon path={mdiAsterisk} size={1} />
															</div>
														</OverlayTrigger>
													)}
												</div>
												<div className="fw-light text-center text-muted mb-3">{perguntaAtual.text}</div>
												{/* CAMPO TEXT */}
												{perguntaAtual.type === PerguntaTipo.text && (
													<FormText
														key={perguntaAtual.id}
														pergunta={perguntaAtual}
														resposta={perguntaAtualResposta}
														onChange={(value) => {
															handleFormText(value);
														}}
													/>
												)}
												{/* CAMPO TEXTAREA */}
												{perguntaAtual.type === PerguntaTipo.textarea && (
													<FormTextarea
														key={perguntaAtual.id}
														pergunta={perguntaAtual}
														resposta={perguntaAtualResposta}
														onChange={(value) => {
															handleFormTextarea(value);
														}}
													/>
												)}
												{/* CAMPO NUMBER */}
												{perguntaAtual.type === PerguntaTipo.number && (
													<FormNumber
														key={perguntaAtual.id}
														pergunta={perguntaAtual}
														resposta={perguntaAtualResposta}
														onChange={(value) => {
															handleFormNumber(value);
														}}
													/>
												)}
												{/* CAMPO RADIO */}
												{perguntaAtual.type === PerguntaTipo.radio && (
													<FormRadio
														key={perguntaAtual.id}
														pergunta={perguntaAtual}
														resposta={perguntaAtualResposta}
														onChange={(value) => {
															handleFormRadio(value);
														}}
													/>
												)}
												{/* CAMPO CHECKBOX */}
												{perguntaAtual.type === PerguntaTipo.checkbox && (
													<FormCheck
														key={perguntaAtual.id}
														pergunta={perguntaAtual}
														resposta={perguntaAtualResposta}
														onChange={(value) => {
															handleFormCheck(value);
														}}
													/>
												)}
												{/* CAMPO select */}

												{/* CAMPO DATE */}
												{perguntaAtual.type === PerguntaTipo.date && (
													<FormDate
														key={perguntaAtual.id}
														pergunta={perguntaAtual}
														resposta={perguntaAtualResposta}
														onChange={(value) => {
															handleFormDate(value);
														}}
													/>
												)}

												{/* CAMPO FILE */}
												{perguntaAtual.type === PerguntaTipo.file && (
													<FormFile
														key={perguntaAtual.id}
														pergunta={perguntaAtual}
														resposta={perguntaAtualResposta}
														onChange={(value) => {
															handleFormFileChange(value);
														}}
													/>
												)}
												{/* CAMPO IMAGE */}
												{perguntaAtual.type === PerguntaTipo.image && (
													<FormImage
														key={perguntaAtual.id}
														pergunta={perguntaAtual}
														resposta={perguntaAtualResposta}
														onChange={(value) => {
															handleFormImageChange(value);
														}}
													/>
												)}
												{/* CAMPO OBJECTIVE */}
												{perguntaAtual.type === PerguntaTipo.objective && (
													<FormObjective
														key={perguntaAtual.id}
														pergunta={perguntaAtual}
														resposta={perguntaAtualResposta}
														onChange={(value) => {
															handleFormObjective(value);
														}}
													/>
												)}
												{/* CAMPO GEO */}
												{perguntaAtual.type === PerguntaTipo.geo && (
													<FormGeo
														key={perguntaAtual.id}
														pergunta={perguntaAtual}
														resposta={perguntaAtualResposta}
														onChange={(value) => {
															handleFormGeoChange(value);
														}}
													/>
												)}
												{/* CAMPO PARAMETRO */}
												{perguntaAtual.type === PerguntaTipo.parametro && (
													<FormParametro
														key={perguntaAtual.id}
														pergunta={perguntaAtual}
														resposta={perguntaAtualResposta}
														onChange={(value) => {
															handleFormParametro(value);
														}}
													/>
												)}
											</Card.Body>
										</Card>
										<div className="d-flex justify-content-center">
											<ButtonGroup size="lg" className="shadow-sm">
												<Button
													variant="light"
													className="d-flex justify-content-center align-items-center gap-3 text-uppercase"
													onClick={handlePrevious}
													style={{ width: 180 }}
												>
													<Icon path={mdiRayStartArrow} size={1} style={{ transform: "scaleX(-1)" }} />
													Anterior
												</Button>
												<Button
													className="d-flex justify-content-center align-items-center gap-3 text-uppercase"
													onClick={handleFormNext}
													disabled={!perguntaAtualNext}
													style={{ width: 180 }}
												>
													Próximo
													<Icon path={mdiRayStartArrow} size={1} />
												</Button>
											</ButtonGroup>
										</div>
									</Col>
								)}
							</Row>
						)}
					</>
				)}

				{/* SALVAR */}
				{formStatus && (
					<Row className="mb-4">
						<Col md={12}>
							<Card className="mb-4 rounded-3">
								<Card.Body
									className="h2 text-center p-3 mb-0"
									onClick={() => {
										setFormStage({ grupo: null, pergunta: null });
										setFormStatus(false);
									}}
									style={{ cursor: "pointer" }}
								>
									{data?.title}
								</Card.Body>
							</Card>
						</Col>

						<Col md={12}>
							<Card className="mb-4 rounded-3 shadow-sm">
								<Card.Body className="d-flex flex-column justify-content-center align-items-center text-center px-5 pb-4">
									{!formSaving && (
										<>
											<Icon path={mdiRayStartEnd} size={5} />
											<div className="fw-light text-center fs-4">Avaliação concluída</div>
										</>
									)}
									{formSaving && (
										<>
											<div className="d-flex justify-content-center align-items-center" style={{ width: 120, height: 120 }}>
												<Spinner style={{ width: 100, height: 100, borderWidth: 10 }} />
											</div>
											<div className="fw-light text-center fs-4">Salvando avaliação</div>
										</>
									)}
								</Card.Body>
							</Card>
							{!formSaving && (
								<div className="d-flex justify-content-center">
									<Button size="lg" type="submit" className="d-flex gap-2 align-items-center text-uppercase px-5 rounded-3 shadow-sm">
										Salvar
										<Icon path={mdiCheck} size={1} />
									</Button>
								</div>
							)}
						</Col>
					</Row>
				)}
			</Form>
		</Layout>
	);
}
