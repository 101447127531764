import ApiService from "./ApiService";
import {
	AccessControlListDeleteGrupoResponseEntity,
	AccessControlListGetGrupoResponseEntity,
	AccessControlListGetListResponseEntity,
	AccessControlListGetPermissoesResponseEntity,
	AccessControlListPostGrupoRequestEntity,
	AccessControlListPostGrupoResponseEntity,
	AccessControlListPutGrupoRequestEntity,
	AccessControlListPutGrupoResponseEntity,
} from "../entities/AccessControlListEntity";

export default class AccessControlListService extends ApiService {
	public async getGroups(pagina: number, filtro: string) {
		return await this.request.get<AccessControlListGetListResponseEntity>(`/api/v1/acl/Groups`, { params: { pagina: pagina, filtro: filtro } });
	}
	public async getGroup(id: number) {
		return await this.request.get<AccessControlListGetGrupoResponseEntity>(`/api/v1/acl/Group/${id}`);
	}
	public async getPermissionTree() {
		return await this.request.get<AccessControlListGetPermissoesResponseEntity>(`/api/v1/acl/PermissionTree`);
	}
	public async postGroup(id: number, data: AccessControlListPostGrupoRequestEntity) {
		return await this.request.post<AccessControlListPostGrupoResponseEntity>(`/api/v1/acl/Group`, data, { params: { empresaId: id } });
	}
	public async putGroup(id: number, data: AccessControlListPutGrupoRequestEntity) {
		return await this.request.put<AccessControlListPutGrupoResponseEntity>(`/api/v1/acl/Group/${id}`, data);
	}
	public async deleteGroup(id: number) {
		return await this.request.delete<AccessControlListDeleteGrupoResponseEntity>(`/api/v1/acl/Group/${id}`);
	}
}
