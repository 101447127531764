import { useState } from "react";
import { Form } from "react-bootstrap";

import { RespostaType } from "../../entities/QuestionnaireEntity";
import { QuestionEntity } from "../../entities/QuestionEntity";

type FormNumberProps = {
	pergunta: QuestionEntity;
	resposta: RespostaType | null;
	onChange: (value: RespostaType) => void;
};

export default function FormNumber(props: FormNumberProps) {
	const [value, setValue] = useState(props.resposta);

	function handleChange(value: number) {
		let data = { questionId: props.pergunta.id!, value: value };
		setValue(data);
		props.onChange(data);
	}

	return (
		<Form.Control
			type="number"
			className="bg-white"
			value={value?.value}
			onChange={(e) => {
				handleChange(Number(e.currentTarget.value));
			}}
			autoFocus={true}
		/>
	);
}
