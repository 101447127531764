import { useState } from "react";
import { Button, Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import Icon from "@mdi/react";
import { mdiCheckboxBlankOutline, mdiCheckboxMarkedOutline, mdiInformation } from "@mdi/js";

import { RespostaType } from "../../entities/QuestionnaireEntity";
import { QuestionEntity } from "../../entities/QuestionEntity";
import { useToast } from "../../context/ToastContext";

type FormCheckProps = {
	pergunta: QuestionEntity;
	resposta: RespostaType | null;
	onChange: (value: RespostaType) => void;
};

export default function FormCheck(props: FormCheckProps) {
	const { handleToast } = useToast();

	const [value, setValue] = useState(props.resposta);

	function handleChange(optionId: number) {
		let data = { questionId: props.pergunta.id!, optionResponses: value?.optionResponses ?? [] };
		let valueIndex = data.optionResponses.findIndex((item) => {
			return item.optionId === optionId;
		});
		if (valueIndex >= 0) {
			data.optionResponses!.splice(valueIndex, 1);
		} else {
			if (props.pergunta.maxAnswers && Number(props.pergunta.maxAnswers) === data.optionResponses.length) {
				handleToast("Validação", `Máximo de respostas é ${props.pergunta.maxAnswers}`, 5000, "warning");
				return;
			} else {
				data.optionResponses.push({ optionId: optionId });
			}
		}
		setValue(data);
		props.onChange(data);
	}

	return (
		<Row>
			{props.pergunta.optionGroups?.map((optionGroup, optionGroupIndex) => {
				return (
					<Col key={optionGroupIndex}>
						{optionGroup.options.map((option) => {
							let checked = value?.optionResponses?.some((item) => {
								return item.optionId === option.id;
							});
							return (
								<Col key={option.id} md={12} className="mb-2">
									<Button
										variant="outline-dark"
										className={`d-flex align-items-center w-100 gap-3 rounded-3 border text-start h-100 ${checked ? "active" : ""}`}
										onClick={() => {
											handleChange(option.id!);
										}}
									>
										<div>
											<Icon path={checked ? mdiCheckboxMarkedOutline : mdiCheckboxBlankOutline} size={1} />
										</div>
										{option.title}
										{option.info && (
											<OverlayTrigger overlay={<Tooltip>{option.info}</Tooltip>}>
												<div className="ms-auto">
													<Icon path={mdiInformation} size={1} />
												</div>
											</OverlayTrigger>
										)}
									</Button>
								</Col>
							);
						})}
					</Col>
				);
			})}
		</Row>
	);
}
