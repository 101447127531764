import ApiService from "./ApiService";
import {
	QuestionnaireResponseDeleteResponse,
	QuestionnaireResponseGetResponse,
	QuestionnaireResponsePostRequest,
	QuestionnaireResponsePostResponse,
	QuestionnaireResponsePutRequest,
	QuestionnaireResponsePutResponse,
} from "../entities/QuestionnaireResponseEntity";

export default class QuestionnaireResponseService extends ApiService {
	public async getQuestionnaireResponses(headers: any = {}) {
		return await this.request.get<QuestionnaireResponseGetResponse[]>(`/api/v1/QuestionnaireResponses`, { headers: headers });
	}
	public async get(id: number) {
		return await this.request.get<QuestionnaireResponseGetResponse>(`/api/v1/QuestionnaireResponse/${id}`);
	}
	public async post(id: number, data: QuestionnaireResponsePostRequest) {
		return await this.request.post<QuestionnaireResponsePostResponse>(`/api/v1/QuestionnaireResponse`, data, { params: { questionnaireId: id } });
	}
	public async put(id: number, data: QuestionnaireResponsePutRequest) {
		return await this.request.put<QuestionnaireResponsePutResponse>(`/api/v1/QuestionnaireResponse/${id}`, data);
	}
	public async delete(id: number) {
		return await this.request.delete<QuestionnaireResponseDeleteResponse>(`/api/v1/QuestionnaireResponse/${id}`);
	}
}
