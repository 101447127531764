import { useState } from "react";
import ReactDatePicker from "react-datepicker";
import moment from "moment";

import { DateFormat } from "../../config/defines";
import { RespostaType } from "../../entities/QuestionnaireEntity";
import { QuestionEntity } from "../../entities/QuestionEntity";

type FormDateProps = {
	pergunta: QuestionEntity;
	resposta: RespostaType | null;
	onChange: (value: RespostaType) => void;
};

export default function FormDate(props: FormDateProps) {
	const [value, setValue] = useState(props.resposta ?? { questionId: props.pergunta.id!, text: moment().format(DateFormat) });

	function handleChange(date: string) {
		let data = { ...value, text: date };
		setValue(data);
		props.onChange(data);
	}

	return (
		<ReactDatePicker
			placeholderText="Data"
			className="form-control"
			calendarClassName="shadow-lg border"
			onChange={(data) => {
				handleChange(moment(data ?? "").format(DateFormat));
			}}
			dayClassName={(date) => {
				return moment(date).isSame(moment(value.text, DateFormat), "date") ? "btn btn-primary p-1" : "btn btn-light p-1";
			}}
			selected={moment(value.text, DateFormat).toDate()}
			dateFormat="dd/MM/yyyy"
		/>
	);
}
