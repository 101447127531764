import ApiService from "./ApiService";
import { DependencyGroupDeleteResponse, DependencyGroupGetResponse, DependencyGroupPostRequest, DependencyGroupPostResponse } from "../entities/DependencyGroupEntity";

export default class DependencyGroupService extends ApiService {
	public async get(id: number) {
		return await this.request.get<DependencyGroupGetResponse>(`/api/v1/DependencyGroup/${id}`);
	}
	public async post(id: number, data: DependencyGroupPostRequest) {
		return await this.request.post<DependencyGroupPostResponse>(`/api/v1/DependencyGroup`, data, { params: { questionId: id } });
	}
	public async delete(id: number) {
		return await this.request.delete<DependencyGroupDeleteResponse>(`/api/v1/DependencyGroup/${id}`);
	}
}
