import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { Button, Card, Form, Spinner, InputGroup, Col, Row, Modal } from "react-bootstrap";
import { Control, useFieldArray, useForm, UseFormRegister, UseFormSetValue, UseFormGetValues, UseFormWatch, FormState, UseFormResetField } from "react-hook-form";
import { Link, useParams } from "react-router-dom";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import Select from "react-select";
import { AxiosError, AxiosResponse } from "axios";
import Icon from "@mdi/react";
import { mdiCheck, mdiChevronLeft, mdiClose, mdiMinusThick, mdiOfficeBuildingCogOutline, mdiPencil, mdiPlus, mdiSort, mdiTrashCanOutline } from "@mdi/js";

import { useToast } from "../../context/ToastContext";
import { SelectOptions, SelectStyle } from "../../config/select";
import { FormTipos, ModuleEntity, ModulePermissionEntity } from "../../config/defines";
import {
	QuestionGroupDeleteResponse,
	QuestionGroupEntity,
	QuestionGroupPostRequest,
	QuestionGroupPostResponse,
	QuestionGroupPutRequest,
	QuestionGroupPutResponse,
} from "../../entities/QuestionGroupEntity";
import { PerguntaTipo, QuestionnaireGetResponse, QuestionnairePutRequest, QuestionnairePutResponse } from "../../entities/QuestionnaireEntity";
import { QuestionEntity, QuestionPostRequest, QuestionPostResponse, QuestionPutResponse } from "../../entities/QuestionEntity";
import { DependencyGroupPostRequest, DependencyGroupPostResponse } from "../../entities/DependencyGroupEntity";
import { OptionGroupPostRequest, OptionGroupPostResponse } from "../../entities/OptionGroupEntity";
import { OptionPostRequest, OptionPostResponse } from "../../entities/OptionEntity";
import { ParameterDomainEntity } from "../../entities/ParameterDomainEntity";
import { ParameterEntity } from "../../entities/ParameterEntity";
import DependencyGroupService from "../../services/DependencyGroupService";
import QuestionnaireService from "../../services/QuestionnaireService";
import QuestionGroupService from "../../services/QuestionGroupService";
import OptionGroupService from "../../services/OptionGroupService";
import DependencyService from "../../services/DependencyService";
import ParameterService from "../../services/ParameterService";
import QuestionService from "../../services/QuestionService";
import OptionService from "../../services/OptionService";

import Layout from "../../components/Layout";
import { useAuth } from "../../context/AuthContext";
import { modulePermissionCheck } from "../../config/utils";

type FormTypeOptionsType =
	| {
			label: string;
			value: number;
	  }
	| {
			label: string;
			options: {
				label: string;
				value: string;
				data: ParameterEntity;
			}[];
	  };

const toastTitle = "Avaliação";

interface Item {
	id: number;
	text: string;
	color: string;
}

interface SortableListProps {
	items: Item[];
	onSortEnd: ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => void;
}

const SortableItem = SortableElement<{ item: Item }>(({ item }: any) => {
	return (
		<li className={`d-flex align-items-center border-bottom p-2 sortable-item list-unstyled`} style={{ zIndex: 1060, cursor: "ns-resize" }}>
			<Icon path={mdiMinusThick} size={0.8} style={{ color: item.color }} className="me-1" />
			<div style={{ flexBasis: 0, flexGrow: 1 }}>{item.text}</div>
		</li>
	);
});

const SortableList = SortableContainer<SortableListProps>(({ items }: any) => {
	return (
		<ul className="border rounded-3 list-unstyled user-select-none" style={{ cursor: "ns-resize" }}>
			{items.map((value: any, index: any) => (
				<SortableItem key={`item-${index}`} index={index} item={value} />
			))}
		</ul>
	);
});

export default function AvaliacaoConfiguracoes() {
	const questionnaireService = new QuestionnaireService();
	const questionGroupService = new QuestionGroupService();
	const parameterService = new ParameterService();

	const { user } = useAuth();
	const { id } = useParams();
	const { handleToast } = useToast(toastTitle);

	const [formStatus, setFormStatus] = useState(false);
	const [formSaving, setFormSaving] = useState(false);
	const [formScroll, setFormScroll] = useState("");
	const [formTypeOptions, setFormTypeOptions] = useState<FormTypeOptionsType[]>(
		FormTipos.filter((item) => {
			return item.value !== 12;
		})
	);

	const [formQuestionGroupsOrderModal, setFormQuestionGroupsOrderModal] = useState(false);
	const [formQuestionGroupsOrderSaving, setFormQuestionGroupsOrderSaving] = useState(false);
	const [formQuestionGroupsOrderData, setFormQuestionGroupsOrderData] = useState<QuestionGroupEntity[]>();

	useQuery(["parametros"], () => fetchDataParametros(), { refetchOnWindowFocus: false });

	const { data, isLoading, isFetching, isRefetching } = useQuery<QuestionnaireGetResponse>(["avaliacao", id], () => fetchData(Number(id)), { refetchOnWindowFocus: false });

	const mutation = useMutation(mutateData, { onSuccess: mutateSuccess, onError: mutateError });
	const mutationQuestionGroupsPut = useMutation(mutateQuestionGroupsPut, { onSuccess: mutateQuestionGroupsPutSuccess, onError: mutateError });

	const { register, control, formState, handleSubmit, getValues, setValue, watch, reset, resetField } = useForm({ defaultValues: data });

	const title = watch("title");
	const questionGroups = watch("questionGroups");

	useEffect(() => {
		let elements: HTMLElement[] = [];
		if (questionGroups) {
			questionGroups &&
				questionGroups.forEach((questionGroup) => {
					elements.push(document.getElementById(`grupo-${questionGroup.id}`)!);
					questionGroup.questions.forEach((question) => {
						elements.push(document.getElementById(`pergunta-${question.id}`)!);
					});
				});
		}

		function handleScroll() {
			let elementMin: any;
			let distanceMin = 99999;
			elements.forEach((element: HTMLElement) => {
				let distance = Math.abs(element.getBoundingClientRect().top);
				if (distance < distanceMin) {
					distanceMin = distance;
					elementMin = element;
				}
			});
			if (elementMin) {
				setFormScroll(elementMin.id);
			}
		}

		window.addEventListener("scroll", handleScroll);

		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, [questionGroups]);

	async function fetchDataParametros() {
		let resp = await parameterService.getParameters();
		if (resp.status === 200) {
			setFormTypeOptions((p) => {
				return [
					...p,
					{
						label: "Parâmetros",
						options: resp.data
							.sort((a, b) => {
								return a.order - b.order;
							})
							.map((item) => {
								return { label: item.title, value: `12-${item.id}`, data: item };
							}),
					},
				];
			});
		}
		return resp.data;
	}

	async function fetchData(id: number) {
		let resp = await questionnaireService.get(id);
		let data = resp.data;
		data.questionGroups.sort((a, b) => {
			return a.order - b.order;
		});
		data.questionGroups.forEach((questionGroup) => {
			questionGroup.questions.sort((a, b) => {
				return a.order - b.order;
			});
		});
		reset(data);
		return data;
	}

	async function mutateData(data: QuestionnairePutRequest) {
		setFormSaving(true);
		return await questionnaireService.put(Number(id), data);
	}

	function mutateSuccess(resp: AxiosResponse<QuestionnairePutResponse, any>) {
		handleToast(toastTitle, "Informações salvas com sucesso!", 5000);
		setFormStatus(false);
		setFormSaving(false);
	}

	async function mutateQuestionGroupsPut(data: QuestionGroupEntity[]) {
		setFormQuestionGroupsOrderSaving(true);

		// QUESTIONS
		let resp;
		for (let i in data) {
			let item = data[Number(i)];
			item.order = Number(i);
			resp = await questionGroupService.put(item.id!, {
				order: item.order,
				title: item.title,
				text: item.text,
				color: item.color,
			});
			data[Number(i)] = item;
		}
		setValue(`questionGroups`, data);
		return resp!;
	}

	function mutateQuestionGroupsPutSuccess(resp: AxiosResponse<QuestionGroupPutResponse, any>) {
		handleToast(toastTitle, "Informações salvas com sucesso!", 5000);
		setFormQuestionGroupsOrderSaving(false);
		setFormQuestionGroupsOrderModal(false);
	}

	function mutateError(resp: AxiosError) {
		handleToast(toastTitle, `Problema na operação: ${resp.message}`, 5000, "danger");
		setFormSaving(false);
	}

	function handleOrderSalvar() {
		mutationQuestionGroupsPut.mutate(formQuestionGroupsOrderData!);
	}

	return (
		<Layout sidebar={true}>
			<Form
				onSubmit={handleSubmit((values) => {
					mutation.mutate({
						title: values.title,
						purpose: values.purpose,
					});
				})}
			>
				<h5 className="mt-4 mb-4 d-flex align-items-center fw-light">
					<Link to={"/configuracoesAvaliacoes"} className="d-flex text-decoration-none">
						<Icon path={mdiChevronLeft} size={1} className="me-1" /> <Icon path={mdiOfficeBuildingCogOutline} size={1} className="me-1" /> Formulário de Avaliação
					</Link>
					{(isLoading || isFetching || isRefetching) && <Spinner size="sm" className="ms-1" variant="secondary" />}
				</h5>

				<Card className="mt-3 mb-4">
					<Card.Header className="d-flex align-items-center bg-white fs-5 fw-light p-3">
						<div className="d-flex flex-column lh-1">
							<div className="fs-6">
								<small className="text-muted fw-light">Avaliação</small>
							</div>
							{title}
						</div>
						<div className="d-flex gap-2 float-right ms-auto" style={{ marginTop: -10, marginBottom: -10 }}>
							{modulePermissionCheck(user!, ModuleEntity.configuracoesAvaliacoes, ModulePermissionEntity.editar) && (
								<>
									{!formStatus && (
										<>
											<Button
												variant="light"
												className="rounded-3 shadow-sm"
												onClick={() => {
													setFormQuestionGroupsOrderModal(true);
													setFormQuestionGroupsOrderData(questionGroups);
												}}
											>
												<Icon path={mdiSort} size={1} />
											</Button>
											<Button
												variant="light"
												className="rounded-3 shadow-sm"
												onClick={() => {
													setFormStatus(true);
												}}
											>
												<Icon path={mdiPencil} size={1} />
											</Button>
										</>
									)}
									{formStatus && (
										<>
											<Button
												variant="primary"
												className="rounded-3 shadow-sm"
												onClick={() => {
													setFormStatus(false);
												}}
												disabled={formSaving}
											>
												{formSaving ? <Spinner animation="border" size="sm" className="me-2" /> : <Icon path={mdiCheck} size={1} />}
											</Button>
											<Button
												variant="light"
												className="rounded-3 shadow-sm"
												type="button"
												onClick={() => {
													setFormStatus(false);
												}}
												disabled={formSaving}
											>
												<Icon path={mdiClose} size={1} />
											</Button>
										</>
									)}
								</>
							)}
						</div>
					</Card.Header>
					<Card.Body>
						<Form.Group className="mb-3" controlId="title">
							<Form.Label>Titulo</Form.Label>
							<Form.Control type="text" placeholder="Informe aqui" {...register("title", { required: true })} disabled={!formStatus} />
						</Form.Group>
						<Form.Group className="mb-0" controlId="purpose">
							<Form.Label>Texto</Form.Label>
							<Form.Control type="text" as="textarea" rows={6} placeholder="Informe aqui" {...register("purpose", { required: true })} disabled={!formStatus} />
						</Form.Group>
					</Card.Body>
				</Card>

				<Row>
					<Col lg={3}>
						<Card className="mb-4 rounded-3 position-sticky overflow-auto" style={{ top: "0.5rem", maxHeight: "calc(100dvh - 1rem)" }}>
							<Card.Body className="d-flex flex-column">
								{questionGroups &&
									questionGroups.map((questionGroup, questionGroupIndex) => {
										const id = `grupo-${questionGroup.id}`;
										const color = watch(`questionGroups.${questionGroupIndex}.color`);
										return (
											<div key={questionGroupIndex} className="d-flex flex-column">
												<a href={`#${id}`} className={`d-flex align-items-center p-1 rounded-3 text-decoration-none ${formScroll === id ? "bg-nav-active" : ""}`}>
													<Icon path={mdiMinusThick} size={0.8} style={{ color: color }} className="me-1" />
													<div style={{ flexBasis: 0, flexGrow: 1 }}>{questionGroup.title}</div>
												</a>
												{questionGroup.questions.map((question, questionIndex) => {
													const id = `pergunta-${question.id}`;
													return (
														<a
															key={questionIndex}
															href={`#${id}`}
															className={`d-flex align-items-center fw-light small p-1 rounded-3 text-decoration-none ${formScroll === id ? "bg-nav-active" : ""}`}
														>
															<Icon path={mdiMinusThick} size={0.8} style={{ color: questionGroup.color }} className="me-1" />
															<div style={{ flexBasis: 0, flexGrow: 1 }}>{question.title}</div>
														</a>
													);
												})}
											</div>
										);
									})}
							</Card.Body>
						</Card>
					</Col>
					<Col lg={9}>
						<FormQuestionGroups
							control={control}
							register={register}
							watch={watch}
							formState={formState}
							getValues={getValues}
							setValue={setValue}
							resetField={resetField}
							questionnaireId={Number(id)}
							formTiposOptions={formTypeOptions}
						/>
					</Col>
				</Row>
			</Form>

			{formQuestionGroupsOrderData !== undefined && (
				<Modal
					show={formQuestionGroupsOrderModal}
					onHide={() => {
						setFormQuestionGroupsOrderModal(false);
					}}
					centered
					size="lg"
				>
					<Modal.Header closeButton>
						<Modal.Title className="d-flex flex-column lh-1">
							<div className="d-flex flex-column lh-1">
								<div className="fs-6">
									<small className="text-muted fw-light">Ordenar Grupos da Avaliação</small>
								</div>
								{title}
							</div>
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						{formQuestionGroupsOrderSaving && (
							<div className="d-flex justify-content-center align-items-center gap-2 p-2">
								<Spinner /> Salvando...
							</div>
						)}
						{!formQuestionGroupsOrderSaving && (
							<SortableList
								items={formQuestionGroupsOrderData.map((item) => {
									return { id: item.id!, text: item.title, color: item.color };
								})}
								onSortEnd={(values) => {
									let questionGroups = [...formQuestionGroupsOrderData];
									let [questionGroup] = questionGroups.splice(values.oldIndex, 1);
									questionGroups.splice(values.newIndex, 0, questionGroup);
									setFormQuestionGroupsOrderData(questionGroups);
								}}
								lockAxis="y"
								helperClass="sortable-item--dragging"
							/>
						)}
					</Modal.Body>
					{!formQuestionGroupsOrderSaving && (
						<Modal.Footer>
							<Button
								variant="secondary"
								onClick={() => {
									setFormQuestionGroupsOrderModal(false);
								}}
							>
								Cancelar
							</Button>
							<Button
								onClick={() => {
									handleOrderSalvar();
								}}
							>
								Salvar
							</Button>
						</Modal.Footer>
					)}
				</Modal>
			)}
		</Layout>
	);
}

type FormQuestionGroupsProps = {
	control: Control<QuestionnaireGetResponse>;
	register: UseFormRegister<QuestionnaireGetResponse>;
	watch: UseFormWatch<QuestionnaireGetResponse>;
	formState: FormState<QuestionnaireGetResponse>;
	getValues: UseFormGetValues<QuestionnaireGetResponse>;
	setValue: UseFormSetValue<QuestionnaireGetResponse>;
	resetField: UseFormResetField<QuestionnaireGetResponse>;
	questionnaireId: number;
	formTiposOptions: FormTypeOptionsType[];
};

function FormQuestionGroups(props: FormQuestionGroupsProps) {
	const questionGroupService = new QuestionGroupService();
	const questionService = new QuestionService();

	const { user } = useAuth();
	const { handleToast } = useToast();

	const [formStatus, setFormStatus] = useState<number | null>(null);
	const [formSaving, setFormSaving] = useState(false);

	const [formQuestionsOrderModal, setFormQuestionsOrderModal] = useState(false);
	const [formQuestionsOrderSaving, setFormQuestionsOrderSaving] = useState(false);
	const [formQuestionsOrderIndex, setFormQuestionsOrderIndex] = useState<number>();
	const [formQuestionsOrderData, setFormQuestionsOrderData] = useState<QuestionGroupEntity>();

	const mutationPost = useMutation(mutatePost, { onSuccess: mutatePostSuccess, onError: mutateError });
	const mutationPut = useMutation(mutatePut, { onSuccess: mutatePutSuccess, onError: mutateError });
	const mutationQuestionPut = useMutation(mutateQuestionPut, { onSuccess: mutateQuestionPutSuccess, onError: mutateError });
	const mutationDelete = useMutation(mutateDelete, { onSuccess: mutateDeleteSuccess, onError: mutateError });

	const { fields, append, remove } = useFieldArray({
		name: `questionGroups`,
		control: props.control,
		keyName: "key",
	});

	async function mutatePost(data: QuestionGroupPostRequest) {
		setFormSaving(true);
		return await questionGroupService.post(props.questionnaireId, data);
	}

	function mutatePostSuccess(resp: AxiosResponse<QuestionGroupPostResponse, any>) {
		append(resp.data);
		handleToast(toastTitle, "Informações salvas com sucesso!", 5000);
		setFormStatus(null);
		setFormSaving(false);
	}

	async function mutatePut(data: { id: number; data: QuestionGroupPutRequest }) {
		setFormSaving(true);
		return await questionGroupService.put(data.id, data.data);
	}

	function mutatePutSuccess(resp: AxiosResponse<QuestionGroupPutResponse, any>) {
		handleToast(toastTitle, "Informações salvas com sucesso!", 5000);
		setFormStatus(null);
		setFormSaving(false);
	}

	async function mutateQuestionPut(data: { index: number; data: QuestionGroupEntity }) {
		setFormQuestionsOrderSaving(true);

		// QUESTIONS
		let resp;
		for (let i in data.data.questions) {
			let item = data.data.questions[Number(i)];
			item.order = Number(i);
			resp = await questionService.put(item.id!, {
				parameterId: undefined,
				order: item.order,
				title: item.title,
				text: item.text,
				type: item.type,
				required: item.required,
				maxAnswers: item.maxAnswers,
			});
			data.data.questions[Number(i)] = item;
		}
		props.setValue(`questionGroups.${data.index}.questions`, data.data.questions);
		return resp!;
	}

	function mutateQuestionPutSuccess(resp: AxiosResponse<QuestionGroupPutResponse, any>) {
		handleToast(toastTitle, "Informações salvas com sucesso!", 5000);
		setFormQuestionsOrderSaving(false);
		setFormQuestionsOrderModal(false);
	}

	async function mutateDelete({ id, index }: { id: number; index: number }) {
		setFormSaving(true);
		let resp = await questionGroupService.delete(id);
		return { index: index, resp: resp };
	}

	function mutateDeleteSuccess({ index, resp }: { index: number; resp: AxiosResponse<QuestionGroupDeleteResponse, any> }) {
		remove(index);
		handleToast(toastTitle, "Informações salvas com sucesso!", 5000);
		setFormSaving(false);
	}

	function mutateError(resp: AxiosError) {
		handleToast(toastTitle, `Problema na operação: ${resp.message}`, 5000, "danger");
		setFormSaving(false);
	}

	function handleAdicionar() {
		mutationPost.mutate({
			id: undefined,
			order: fields.length,
			title: "",
			text: "",
			color: "",
			questions: [],
		});
	}

	function handleEditar(questionGroupIndex: number) {
		let values = props.getValues(`questionGroups.${questionGroupIndex}`);
		mutationPut.mutate({
			id: values.id!,
			data: {
				order: values.order,
				title: values.title,
				text: values.text,
				color: values.color,
			},
		});
	}

	function handleRemover(id: number, index: number) {
		mutationDelete.mutate({ id, index });
	}

	function handleCancelar(index: number) {
		props.resetField(`questionGroups.${index}`);
		setFormStatus(null);
	}

	function handleOrderSalvar() {
		mutationQuestionPut.mutate({ index: formQuestionsOrderIndex!, data: formQuestionsOrderData! });
	}

	return (
		<>
			{fields.map((questionGroup, questionGroupIndex) => {
				const title = props.watch(`questionGroups.${questionGroupIndex}.title`);
				const color = props.watch(`questionGroups.${questionGroupIndex}.color`);
				const questionsOrderData = props.watch(`questionGroups.${questionGroupIndex}`);

				return (
					<Card id={`grupo-${questionGroup.id}`} key={questionGroupIndex} className="mb-4" style={{ borderTopWidth: 4, borderTopStyle: "solid", borderTopColor: color }}>
						<Card.Header className="d-flex align-items-center bg-white fs-5 fw-light p-3">
							<div className="d-flex flex-column lh-1">
								<div className="fs-6">
									<small className="text-muted fw-light">Grupo</small>
								</div>
								{title}
							</div>
							<div className="d-flex gap-2 float-right ms-auto" style={{ marginTop: -10, marginBottom: -10 }}>
								{modulePermissionCheck(user!, ModuleEntity.configuracoesAvaliacoes, ModulePermissionEntity.editar) && (
									<>
										{formStatus !== questionGroupIndex && (
											<>
												<Button
													variant="light"
													className="rounded-3 shadow-sm"
													onClick={() => {
														setFormQuestionsOrderModal(true);
														setFormQuestionsOrderIndex(questionGroupIndex);
														setFormQuestionsOrderData(questionsOrderData);
													}}
												>
													<Icon path={mdiSort} size={1} />
												</Button>
												<Button
													variant="light"
													className="rounded-3 shadow-sm"
													onClick={() => {
														setFormStatus(questionGroupIndex);
													}}
												>
													<Icon path={mdiPencil} size={1} />
												</Button>
											</>
										)}
										{formStatus === questionGroupIndex && (
											<>
												<Button
													variant="primary"
													className="rounded-3 shadow-sm"
													onClick={() => {
														handleEditar(questionGroupIndex);
													}}
													disabled={formSaving}
												>
													{formSaving ? <Spinner animation="border" size="sm" /> : <Icon path={mdiCheck} size={1} />}
												</Button>
												<Button
													variant="light"
													className="rounded-3 shadow-sm"
													type="button"
													onClick={() => {
														handleCancelar(questionGroupIndex);
													}}
													disabled={formSaving}
												>
													<Icon path={mdiClose} size={1} />
												</Button>
											</>
										)}
										<Button
											type="button"
											variant="light"
											className="d-flex rounded-3 shadow-sm"
											onClick={() => {
												handleRemover(questionGroup.id!, questionGroupIndex);
											}}
										>
											<Icon path={mdiTrashCanOutline} size={1} className="text-danger" />
										</Button>
									</>
								)}
							</div>
						</Card.Header>
						<Card.Body>
							<Row>
								<Form.Group className="mb-3 col-md-8" controlId={`questionGroups.${questionGroupIndex}.title`}>
									<Form.Label>Titulo</Form.Label>
									<Form.Control
										type="text"
										placeholder="Informe aqui"
										{...props.register(`questionGroups.${questionGroupIndex}.title`)}
										disabled={formStatus !== questionGroupIndex}
									/>
								</Form.Group>
								<Form.Group className="mb-3 col-md-4" controlId={`questionGroups.${questionGroupIndex}.color`}>
									<Form.Label>Cor</Form.Label>
									<Form.Control
										type="color"
										placeholder="Informe aqui"
										className="w-100"
										{...props.register(`questionGroups.${questionGroupIndex}.color`)}
										disabled={formStatus !== questionGroupIndex}
									/>
								</Form.Group>
							</Row>
							<Form.Group className="mb-0" controlId={`questionGroups.${questionGroupIndex}.text`}>
								<Form.Label>Texto</Form.Label>
								<Form.Control
									type="text"
									as="textarea"
									rows={3}
									placeholder="Informe aqui"
									{...props.register(`questionGroups.${questionGroupIndex}.text`)}
									disabled={formStatus !== questionGroupIndex}
								/>
							</Form.Group>
						</Card.Body>

						<FormQuestions
							control={props.control}
							register={props.register}
							watch={props.watch}
							formState={props.formState}
							getValues={props.getValues}
							setValue={props.setValue}
							resetField={props.resetField}
							questionGroupIndex={questionGroupIndex}
							questionGroupId={questionGroup.id!}
							formTiposOptions={props.formTiposOptions}
						/>
					</Card>
				);
			})}

			<div className="d-flex mb-4">
				<Button variant="light" className="d-flex gap-2 align-items-center rounded-3 shadow-sm" onClick={handleAdicionar}>
					<Icon path={mdiPlus} size={1} /> Adicionar Grupo
				</Button>
			</div>

			{formQuestionsOrderData !== undefined && (
				<Modal
					show={formQuestionsOrderModal}
					onHide={() => {
						setFormQuestionsOrderModal(false);
					}}
					centered
					size="lg"
				>
					<Modal.Header closeButton>
						<Modal.Title className="d-flex flex-column lh-1">
							<div className="d-flex flex-column lh-1">
								<div className="fs-6">
									<small className="text-muted fw-light">Ordenar Perguntas do Grupo</small>
								</div>
								<div className={`d-flex align-items-center`}>
									<Icon path={mdiMinusThick} size={0.8} style={{ color: formQuestionsOrderData.color }} className="me-1" />
									<div style={{ flexBasis: 0, flexGrow: 1 }}>{formQuestionsOrderData.title}</div>
								</div>
							</div>
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						{formQuestionsOrderSaving && (
							<div className="d-flex justify-content-center align-items-center gap-2 p-2">
								<Spinner /> Salvando...
							</div>
						)}
						{!formQuestionsOrderSaving && (
							<SortableList
								items={formQuestionsOrderData.questions.map((item) => {
									return { id: item.id!, text: item.title, color: formQuestionsOrderData.color };
								})}
								onSortEnd={(values) => {
									let questions = [...formQuestionsOrderData.questions];
									let [question] = questions.splice(values.oldIndex, 1);
									questions.splice(values.newIndex, 0, question);
									setFormQuestionsOrderData({ ...formQuestionsOrderData, questions: questions });
								}}
								lockAxis="y"
								helperClass="sortable-item--dragging"
							/>
						)}
					</Modal.Body>
					{!formQuestionsOrderSaving && (
						<Modal.Footer>
							<Button
								variant="secondary"
								onClick={() => {
									setFormQuestionsOrderModal(false);
								}}
							>
								Cancelar
							</Button>
							<Button
								onClick={() => {
									handleOrderSalvar();
								}}
							>
								Salvar
							</Button>
						</Modal.Footer>
					)}
				</Modal>
			)}
		</>
	);
}

type FormQuestionsProps = {
	control: Control<QuestionnaireGetResponse>;
	register: UseFormRegister<QuestionnaireGetResponse>;
	watch: UseFormWatch<QuestionnaireGetResponse>;
	formState: FormState<QuestionnaireGetResponse>;
	getValues: UseFormGetValues<QuestionnaireGetResponse>;
	setValue: UseFormSetValue<QuestionnaireGetResponse>;
	resetField: UseFormResetField<QuestionnaireGetResponse>;
	questionGroupIndex: number;
	questionGroupId: number;
	formTiposOptions: FormTypeOptionsType[];
};

function FormQuestions(props: FormQuestionsProps) {
	const questionService = new QuestionService();
	const optionGroupService = new OptionGroupService();
	const optionService = new OptionService();
	const dependencyService = new DependencyService();

	const { user } = useAuth();
	const { handleToast } = useToast();

	const [formStatus, setFormStatus] = useState<number | null>(null);
	const [formSaving, setFormSaving] = useState(false);

	const { fields, append, remove } = useFieldArray({
		name: `questionGroups.${props.questionGroupIndex}.questions`,
		control: props.control,
		keyName: "key",
	});

	const mutationPost = useMutation(mutatePost, { onSuccess: mutatePostSuccess, onError: mutateError });
	const mutationPut = useMutation(mutatePut, { onSuccess: mutatePutSuccess, onError: mutateError });
	const mutationDelete = useMutation(mutateDelete, { onSuccess: mutateDeleteSuccess, onError: mutateError });

	async function mutatePost(data: QuestionPostRequest) {
		setFormSaving(true);
		return await questionService.post(props.questionGroupId, data);
	}

	function mutatePostSuccess(resp: AxiosResponse<QuestionPostResponse, any>) {
		append(resp.data);
		handleToast(toastTitle, "Informações salvas com sucesso!", 5000);
		setFormStatus(null);
		setFormSaving(false);
	}

	async function mutatePut(data: QuestionEntity) {
		setFormSaving(true);

		// QUESTION
		let resp = await questionService.put(data.id!, {
			parameterId: data.parameterId,
			order: data.order,
			title: data.title,
			text: data.text,
			type: data.parameterId ? 12 : data.type,
			required: data.required,
			maxAnswers: data.maxAnswers,
		});

		if (data.parameterId === undefined) {
			for (let i in data.optionGroups) {
				// QUESTION OPTION GROUP
				await optionGroupService.put(data.optionGroups[i].id!, {
					title: data.optionGroups[i].title,
					required: data.optionGroups[i].required,
					order: data.optionGroups[i].order,
				});

				for (let j in data.optionGroups[i].options) {
					// QUESTION OPTION
					await optionService.put(data.optionGroups[i].options[j].id!, {
						title: data.optionGroups[i].options[j].title,
						order: data.optionGroups[i].options[j].order,
						value: data.optionGroups[i].options[j].value,
						info: data.optionGroups[i].options[j].info,
					});
				}
			}
		}

		// DEPENDENCIES
		props.formState.dirtyFields.questionGroups?.forEach((questionGroup, questionGroupIndex) => {
			questionGroup.questions?.forEach((question, questionIndex) => {
				question.dependencyGroups?.forEach(async (dependencyGroup, dependencyGroupIndex) => {
					if (dependencyGroup.dependencies) {
						let dependenciesDefault: any[] = [];
						let dependencies = props.getValues(`questionGroups.${questionGroupIndex}.questions.${questionIndex}.dependencyGroups.${dependencyGroupIndex}.dependencies`);
						let dependencyGroup = props.getValues(`questionGroups.${questionGroupIndex}.questions.${questionIndex}.dependencyGroups.${dependencyGroupIndex}`);

						if (
							props.formState.defaultValues!.questionGroups &&
							props.formState.defaultValues!.questionGroups[questionGroupIndex] &&
							props.formState.defaultValues!.questionGroups[questionGroupIndex].questions &&
							props.formState.defaultValues!.questionGroups[questionGroupIndex].questions[questionIndex] &&
							props.formState.defaultValues!.questionGroups[questionGroupIndex].questions[questionIndex].dependencyGroups &&
							props.formState.defaultValues!.questionGroups[questionGroupIndex].questions[questionIndex].dependencyGroups[dependencyGroupIndex] &&
							props.formState.defaultValues!.questionGroups[questionGroupIndex].questions[questionIndex].dependencyGroups[dependencyGroupIndex].dependencies
						) {
							dependenciesDefault = props.formState.defaultValues!.questionGroups![questionGroupIndex]!.questions![questionIndex]!.dependencyGroups![dependencyGroupIndex]!.dependencies!;
						}

						// DELETE
						for (let i in dependenciesDefault) {
							if (dependenciesDefault[i] && dependenciesDefault[i].id) {
								await dependencyService.delete(dependenciesDefault[i].id);
							}
						}

						// POST
						let respDependencies = [];
						for (let i in dependencies) {
							let respDependency = await dependencyService.post(dependencyGroup.id!, { optionId: dependencies[i]!.optionId });
							respDependencies.push(respDependency.data);
						}

						props.setValue(`questionGroups.${questionGroupIndex}.questions.${questionIndex}.dependencyGroups.${dependencyGroupIndex}.dependencies`, respDependencies, {
							shouldDirty: false,
						});
						let defaultValue = props.getValues(`questionGroups`);
						props.resetField(`questionGroups`, {
							keepDirty: false,
							keepTouched: false,
							keepError: false,
							defaultValue: defaultValue,
						});
					}
				});
			});
		});

		return resp;
	}

	function mutatePutSuccess(resp: AxiosResponse<QuestionPutResponse, any>) {
		handleToast(toastTitle, "Informações salvas com sucesso!", 5000);
		setFormStatus(null);
		setFormSaving(false);
	}

	async function mutateDelete({ id, index }: { id: number; index: number }) {
		setFormSaving(true);
		let resp = await questionService.delete(id);
		return { index: index, resp: resp };
	}

	function mutateDeleteSuccess({ index, resp }: { index: number; resp: AxiosResponse<QuestionGroupDeleteResponse, any> }) {
		remove(index);
		handleToast(toastTitle, "Informações salvas com sucesso!", 5000);
		setFormSaving(false);
	}

	function mutateError(resp: AxiosError) {
		handleToast(toastTitle, `Problema na operação: ${resp.message}`, 5000, "danger");
		setFormSaving(false);
	}

	function handleAdicionar() {
		mutationPost.mutate({
			id: undefined,
			parameterId: undefined,
			order: 0,
			title: "",
			text: "",
			type: 0,
			required: false,
			optionGroups: [],
			dependencyGroups: [],
		});
	}

	function handleEditar(questionIndex: number) {
		let values = props.getValues(`questionGroups.${props.questionGroupIndex}.questions.${questionIndex}`);
		mutationPut.mutate(values);
	}

	function handleRemover(id: number, index: number) {
		mutationDelete.mutate({ id, index });
	}

	function handleCancelar(index: number) {
		props.resetField(`questionGroups.${props.questionGroupIndex}.questions.${index}`);
		setFormStatus(null);
	}

	async function handleTipo(value: any, id: number, index: number) {
		setFormSaving(true);
		if (value.data) {
			let parameterDomains: ParameterDomainEntity[] = value.data.parameterDomains;

			props.setValue(`questionGroups.${props.questionGroupIndex}.questions.${index}.parameterId`, value.data.id);
			props.setValue(
				`questionGroups.${props.questionGroupIndex}.questions.${index}.optionGroups`,
				parameterDomains.map((parameterDomain: ParameterDomainEntity, parameterDomainIndex) => {
					return {
						id: parameterDomain.id,
						title: parameterDomain.domain.title,
						required: parameterDomain.required,
						order: parameterDomainIndex,
						options: parameterDomain.parameterDomainOptions.map((parameterDomainOption) => {
							return {
								id: parameterDomainOption.id,
								order: parameterDomainOption.domainOption.order,
								title: parameterDomainOption.domainOption.title,
								value: parameterDomainOption.domainOption.value,
								info: parameterDomainOption.info,
							};
						}),
					};
				})
			);
		} else {
			props.setValue(`questionGroups.${props.questionGroupIndex}.questions.${index}.parameterId`, undefined);
			props.setValue(`questionGroups.${props.questionGroupIndex}.questions.${index}.optionGroups`, []);
		}
		props.setValue(`questionGroups.${props.questionGroupIndex}.questions.${index}.type`, value.value);
		setFormSaving(false);
	}

	return (
		<>
			{fields.map((question, questionIndex) => {
				const title = props.watch(`questionGroups.${props.questionGroupIndex}.questions.${questionIndex}.title`);
				const type = props.watch(`questionGroups.${props.questionGroupIndex}.questions.${questionIndex}.type`);
				const parameterId = props.watch(`questionGroups.${props.questionGroupIndex}.questions.${questionIndex}.parameterId`);
				const color = props.watch(`questionGroups.${props.questionGroupIndex}.color`);
				const formTiposOptionsFlat = props.formTiposOptions.flatMap((item: any) => {
					return item.options ?? item;
				});
				const defaultType = formTiposOptionsFlat.find((item: any) => {
					if (item.data) {
						return item.data.id === parameterId;
					}
					return item.value === question.type;
				});
				const maxAnswers = [PerguntaTipo.file, PerguntaTipo.checkbox, PerguntaTipo.image].includes(type);

				return (
					<React.Fragment key={question.key}>
						<Card.Header
							id={`pergunta-${question.id}`}
							className={`d-flex align-items-center p-3 border-top bg-white`}
							style={{
								borderLeftWidth: 4,
								borderLeftStyle: "solid",
								borderLeftColor: `${color}${formStatus === questionIndex ? "" : "40"}`,
								borderRightWidth: 4,
								borderRightStyle: "solid",
								borderRightColor: `${color}${formStatus === questionIndex ? "" : "40"}`,
							}}
						>
							<div className="d-flex flex-column lh-1">
								<small className="text-muted fw-light">Pergunta</small>
								{title}
							</div>
							<div className="d-flex gap-2 float-right ms-auto" style={{ marginTop: -10, marginBottom: -10 }}>
								{modulePermissionCheck(user!, ModuleEntity.configuracoesAvaliacoes, ModulePermissionEntity.editar) && (
									<>
										{formStatus !== questionIndex && (
											<Button
												variant="light"
												className="rounded-3 shadow-sm"
												onClick={() => {
													setFormStatus(questionIndex);
												}}
											>
												<Icon path={mdiPencil} size={1} />
											</Button>
										)}
										{formStatus === questionIndex && (
											<>
												<Button
													variant="primary"
													className="rounded-3 shadow-sm"
													onClick={() => {
														handleEditar(questionIndex);
													}}
													disabled={formSaving}
												>
													{formSaving ? <Spinner animation="border" size="sm" /> : <Icon path={mdiCheck} size={1} />}
												</Button>
												<Button
													variant="light"
													className="rounded-3 shadow-sm"
													type="button"
													onClick={() => {
														handleCancelar(questionIndex);
													}}
													disabled={formSaving}
												>
													<Icon path={mdiClose} size={1} />
												</Button>
											</>
										)}
										<Button
											type="button"
											variant="light"
											className="d-flex rounded-3 shadow-sm"
											onClick={() => {
												handleRemover(question.id!, questionIndex);
											}}
										>
											<Icon path={mdiTrashCanOutline} size={1} className="text-danger" />
										</Button>
									</>
								)}
							</div>
						</Card.Header>
						<Card.Body
							style={{
								borderLeftWidth: 4,
								borderLeftStyle: "solid",
								borderLeftColor: `${color}${formStatus === questionIndex ? "" : "40"}`,
								borderRightWidth: 4,
								borderRightStyle: "solid",
								borderRightColor: `${color}${formStatus === questionIndex ? "" : "40"}`,
							}}
						>
							<Row>
								<Form.Group className="mb-3 col-md-6" controlId={`questionGroups.${props.questionGroupIndex}.questions.${questionIndex}.title`}>
									<Form.Label>Titulo</Form.Label>
									<Form.Control
										type="text"
										placeholder="Informe aqui"
										{...props.register(`questionGroups.${props.questionGroupIndex}.questions.${questionIndex}.title`)}
										disabled={formStatus !== questionIndex}
									/>
								</Form.Group>
								<Form.Group className="mb-3 col-md-3" controlId={`questionGroups.${props.questionGroupIndex}.questions.${questionIndex}.type`}>
									<Form.Label>Tipo</Form.Label>
									<Select
										placeholder={"Selecione"}
										defaultValue={defaultType}
										options={props.formTiposOptions}
										onChange={(value) => {
											handleTipo(value, question.id!, questionIndex);
										}}
										styles={SelectStyle}
										isDisabled={formStatus !== questionIndex}
										{...SelectOptions}
									/>
								</Form.Group>
								<Form.Group className="mb-3 col-md-3" controlId={`questionGroups.${props.questionGroupIndex}.questions.${questionIndex}.required`}>
									<Form.Label>Obrigatório</Form.Label>
									<Form.Check
										type="switch"
										className="pt-1"
										{...props.register(`questionGroups.${props.questionGroupIndex}.questions.${questionIndex}.required`)}
										disabled={formStatus !== questionIndex}
									/>
								</Form.Group>
								<Form.Group className={`mb-3 col-md-${maxAnswers ? "6" : "12"}`} controlId={`questionGroups.${props.questionGroupIndex}.questions.${questionIndex}.text`}>
									<Form.Label>Texto</Form.Label>
									<Form.Control
										type="text"
										as="textarea"
										rows={3}
										placeholder="Informe aqui"
										{...props.register(`questionGroups.${props.questionGroupIndex}.questions.${questionIndex}.text`)}
										disabled={formStatus !== questionIndex}
									/>
								</Form.Group>
								{maxAnswers && (
									<Form.Group className="mb-3 col-md-6" controlId={`questionGroups.${props.questionGroupIndex}.questions.${questionIndex}.maxAnswers`}>
										<Form.Label>Quantidade de Respostas</Form.Label>
										<Form.Control
											type="number"
											placeholder="Informe aqui"
											min={1}
											{...props.register(`questionGroups.${props.questionGroupIndex}.questions.${questionIndex}.maxAnswers`, { valueAsNumber: true })}
											disabled={formStatus !== questionIndex}
										/>
									</Form.Group>
								)}
							</Row>

							{([PerguntaTipo.radio, PerguntaTipo.checkbox, PerguntaTipo.select, PerguntaTipo.parametro].includes(type) || parameterId) && (
								<>
									<Form.Group className="mb-3">
										<Form.Label>Grupos de Opções</Form.Label>
										<FormOptionGroups
											control={props.control}
											register={props.register}
											watch={props.watch}
											getValues={props.getValues}
											setValue={props.setValue}
											questionGroupIndex={props.questionGroupIndex}
											questionIndex={questionIndex}
											questionId={question.id!}
											formStatus={parameterId ? -1 : formStatus}
											formDisabled={!!parameterId}
										/>
									</Form.Group>
								</>
							)}

							<Form.Group className="mb-3" controlId="dependencias">
								<Form.Label className="d-flex flex-column">
									Grupos de Dependências <small className="text-muted">(Pelo menos uma opção de cada grupo deve estar marcada para a pergunta ser exibida)</small>
								</Form.Label>
								<FormDependencyGroups
									control={props.control}
									register={props.register}
									watch={props.watch}
									getValues={props.getValues}
									setValue={props.setValue}
									questionGroupIndex={props.questionGroupIndex}
									questionIndex={questionIndex}
									questionId={question.id!}
									formTiposOptions={props.formTiposOptions}
									formStatus={formStatus}
								/>
							</Form.Group>
						</Card.Body>
					</React.Fragment>
				);
			})}

			<Card.Footer className="bg-white">
				<Button variant="light" className="d-flex gap-2 align-items-center rounded-3 shadow-sm" onClick={handleAdicionar}>
					<Icon path={mdiPlus} size={1} /> Adicionar Pergunta
				</Button>
			</Card.Footer>
		</>
	);
}

type FormOptionGroupsProps = {
	control: Control<QuestionnaireGetResponse>;
	register: UseFormRegister<QuestionnaireGetResponse>;
	watch: UseFormWatch<QuestionnaireGetResponse>;
	getValues: UseFormGetValues<QuestionnaireGetResponse>;
	setValue: UseFormSetValue<QuestionnaireGetResponse>;
	questionGroupIndex: number;
	questionIndex: number;
	questionId: number;
	formStatus: number | null;
	formDisabled: boolean;
};

function FormOptionGroups(props: FormOptionGroupsProps) {
	const optionGroupService = new OptionGroupService();

	const { handleToast } = useToast();

	const [formSaving, setFormSaving] = useState(false);

	const { fields, append, remove } = useFieldArray({
		name: `questionGroups.${props.questionGroupIndex}.questions.${props.questionIndex}.optionGroups`,
		control: props.control,
		keyName: "key",
	});

	const mutationPost = useMutation(mutatePost, { onSuccess: mutatePostSuccess, onError: mutateError });
	const mutationDelete = useMutation(mutateDelete, { onSuccess: mutateDeleteSuccess, onError: mutateError });

	async function mutatePost(data: OptionGroupPostRequest) {
		setFormSaving(true);
		return await optionGroupService.post(props.questionId, data);
	}

	function mutatePostSuccess(resp: AxiosResponse<OptionGroupPostResponse, any>) {
		append(resp.data);
		handleToast(toastTitle, "Informações salvas com sucesso!", 5000);
		setFormSaving(false);
	}

	async function mutateDelete({ id, index }: { id: number; index: number }) {
		setFormSaving(true);
		let resp = await optionGroupService.delete(id);
		return { index: index, resp: resp };
	}

	function mutateDeleteSuccess({ index, resp }: { index: number; resp: AxiosResponse<QuestionGroupDeleteResponse, any> }) {
		remove(index);
		handleToast(toastTitle, "Informações salvas com sucesso!", 5000);
		setFormSaving(false);
	}

	function mutateError(resp: AxiosError) {
		handleToast(toastTitle, `Problema na operação: ${resp.message}`, 5000, "danger");
		setFormSaving(false);
	}

	function handleAdicionar() {
		mutationPost.mutate({
			id: undefined,
			title: "",
			required: false,
			order: 0,
			options: [],
		});
	}

	function handleRemover(id: number, index: number) {
		mutationDelete.mutate({ id, index });
	}

	return (
		<>
			{fields.map((optionGroup, optionGroupIndex) => {
				const title = props.watch(`questionGroups.${props.questionGroupIndex}.questions.${props.questionIndex}.optionGroups.${optionGroupIndex}.title`);
				return (
					<Card key={optionGroup.key} className="border mb-3">
						<Card.Header className="d-flex align-items-center bg-card border-bottom px-3">
							<div className="d-flex flex-column lh-1">
								<small className="text-muted fw-light">Grupo de Opções</small>
								{title}
							</div>
							<div className="d-flex gap-2 float-right ms-auto">
								<Button
									type="button"
									variant="light"
									className="d-flex rounded-3 shadow-sm"
									onClick={() => {
										handleRemover(optionGroup.id!, optionGroupIndex);
									}}
									disabled={formSaving || props.formDisabled}
								>
									<Icon path={mdiTrashCanOutline} size={1} className="text-danger" />
								</Button>
							</div>
						</Card.Header>
						<Card.Body>
							<Row>
								<Form.Group className="mb-3 col-md-9" controlId={`questionGroups.${props.questionGroupIndex}.questions.${props.questionIndex}.optionGroups.${optionGroupIndex}.title`}>
									<Form.Label>Titulo</Form.Label>
									<Form.Control
										type="text"
										placeholder="Informe aqui"
										{...props.register(`questionGroups.${props.questionGroupIndex}.questions.${props.questionIndex}.optionGroups.${optionGroupIndex}.title`)}
										disabled={props.formStatus !== props.questionIndex}
									/>
								</Form.Group>
								<Form.Group
									className="mb-3 col-md-3"
									controlId={`questionGroups.${props.questionGroupIndex}.questions.${props.questionIndex}.optionGroups.${optionGroupIndex}.required`}
								>
									<Form.Label>Obrigatório</Form.Label>
									<Form.Check
										type="switch"
										className="pt-1"
										{...props.register(`questionGroups.${props.questionGroupIndex}.questions.${props.questionIndex}.optionGroups.${optionGroupIndex}.required`)}
										disabled={props.formStatus !== props.questionIndex}
									/>
								</Form.Group>
								<Form.Group>
									<Form.Label>Opções:</Form.Label>
									<FormOptions
										control={props.control}
										register={props.register}
										watch={props.watch}
										getValues={props.getValues}
										setValue={props.setValue}
										questionGroupIndex={props.questionGroupIndex}
										questionIndex={props.questionIndex}
										optionGroupIndex={optionGroupIndex}
										optionGroupId={optionGroup.id!}
										formStatus={props.formStatus}
										formDisabled={props.formDisabled}
									/>
								</Form.Group>
							</Row>
						</Card.Body>
					</Card>
				);
			})}

			<Button variant="light" className="d-flex gap-2 align-items-center rounded-3 shadow-sm" onClick={handleAdicionar} disabled={formSaving || props.formDisabled}>
				<Icon path={mdiPlus} size={1} /> Grupo de Opções
			</Button>
		</>
	);
}

type FormOptionsProps = {
	control: Control<QuestionnaireGetResponse>;
	register: UseFormRegister<QuestionnaireGetResponse>;
	watch: UseFormWatch<QuestionnaireGetResponse>;
	getValues: UseFormGetValues<QuestionnaireGetResponse>;
	setValue: UseFormSetValue<QuestionnaireGetResponse>;
	questionGroupIndex: number;
	questionIndex: number;
	optionGroupIndex: number;
	optionGroupId: number;
	formStatus: number | null;
	formDisabled: boolean;
};

function FormOptions(props: FormOptionsProps) {
	const optionService = new OptionService();

	const { handleToast } = useToast();

	const [formSaving, setFormSaving] = useState(false);

	const { fields, append, remove } = useFieldArray({
		name: `questionGroups.${props.questionGroupIndex}.questions.${props.questionIndex}.optionGroups.${props.optionGroupIndex}.options`,
		control: props.control,
		keyName: "key",
	});

	const mutationPost = useMutation(mutatePost, { onSuccess: mutatePostSuccess, onError: mutateError });
	const mutationDelete = useMutation(mutateDelete, { onSuccess: mutateDeleteSuccess, onError: mutateError });

	async function mutatePost(data: OptionPostRequest) {
		setFormSaving(true);
		return await optionService.post(props.optionGroupId, data);
	}

	function mutatePostSuccess(resp: AxiosResponse<OptionPostResponse, any>) {
		append(resp.data);
		handleToast(toastTitle, "Informações salvas com sucesso!", 5000);
		setFormSaving(false);
	}

	async function mutateDelete({ id, index }: { id: number; index: number }) {
		setFormSaving(true);
		let resp = await optionService.delete(id);
		return { index: index, resp: resp };
	}

	function mutateDeleteSuccess({ index, resp }: { index: number; resp: AxiosResponse<QuestionGroupDeleteResponse, any> }) {
		remove(index);
		handleToast(toastTitle, "Informações salvas com sucesso!", 5000);
		setFormSaving(false);
	}

	function mutateError(resp: AxiosError) {
		handleToast(toastTitle, `Problema na operação: ${resp.message}`, 5000, "danger");
		setFormSaving(false);
	}

	function handleAdicionar() {
		mutationPost.mutate({
			id: undefined,
			title: "",
			order: 0,
			value: 0,
			info: "",
		});
	}

	function handleRemover(id: number, index: number) {
		mutationDelete.mutate({ id, index });
	}

	return (
		<>
			{fields.map((option, optionIndex) => {
				return (
					<Row key={option.key} className="mb-2">
						<Col>
							<InputGroup>
								<InputGroup.Text>
									<small className="text-muted">Titulo</small>
								</InputGroup.Text>
								<Form.Control
									type="text"
									placeholder="Informe aqui"
									{...props.register(
										`questionGroups.${props.questionGroupIndex}.questions.${props.questionIndex}.optionGroups.${props.optionGroupIndex}.options.${optionIndex}.title`
									)}
									disabled={props.formStatus !== props.questionIndex}
								/>
							</InputGroup>
						</Col>
						<Col>
							<InputGroup>
								<InputGroup.Text>
									<small className="text-muted">Informações</small>
								</InputGroup.Text>
								<Form.Control
									type="text"
									placeholder="Informe aqui"
									{...props.register(
										`questionGroups.${props.questionGroupIndex}.questions.${props.questionIndex}.optionGroups.${props.optionGroupIndex}.options.${optionIndex}.info`
									)}
									disabled={props.formStatus !== props.questionIndex}
								/>
							</InputGroup>
						</Col>
						<Col>
							<InputGroup>
								<InputGroup.Text>
									<small className="text-muted">Valor</small>
								</InputGroup.Text>
								<Form.Control
									type="number"
									placeholder="Informe aqui"
									{...props.register(
										`questionGroups.${props.questionGroupIndex}.questions.${props.questionIndex}.optionGroups.${props.optionGroupIndex}.options.${optionIndex}.value`,
										{ valueAsNumber: true }
									)}
									disabled={props.formStatus !== props.questionIndex}
								/>
							</InputGroup>
						</Col>
						<Col lg="auto">
							<Button
								variant="light"
								className="rounded-3 shadow-sm"
								onClick={() => {
									handleRemover(option.id!, optionIndex);
								}}
								disabled={formSaving || props.formDisabled}
							>
								<Icon path={mdiTrashCanOutline} size={1} className="text-danger" />
							</Button>
						</Col>
					</Row>
				);
			})}

			<Button variant="light" className="d-flex gap-2 align-items-center rounded-3 shadow-sm" onClick={handleAdicionar} disabled={formSaving || props.formDisabled}>
				<Icon path={mdiPlus} size={1} /> Opção
			</Button>
		</>
	);
}

type FormDependencyGroupsProps = {
	control: Control<QuestionnaireGetResponse>;
	register: UseFormRegister<QuestionnaireGetResponse>;
	watch: UseFormWatch<QuestionnaireGetResponse>;
	getValues: UseFormGetValues<QuestionnaireGetResponse>;
	setValue: UseFormSetValue<QuestionnaireGetResponse>;
	questionGroupIndex: number;
	questionIndex: number;
	questionId: number;
	formStatus: number | null;
	formTiposOptions: FormTypeOptionsType[];
};

function FormDependencyGroups(props: FormDependencyGroupsProps) {
	const dependencyGroupService = new DependencyGroupService();

	const { handleToast } = useToast();

	const [formSaving, setFormSaving] = useState(false);

	const { fields, append, remove } = useFieldArray({
		name: `questionGroups.${props.questionGroupIndex}.questions.${props.questionIndex}.dependencyGroups`,
		control: props.control,
		keyName: "key",
	});

	const mutationPost = useMutation(mutatePost, { onSuccess: mutatePostSuccess, onError: mutateError });
	const mutationDelete = useMutation(mutateDelete, { onSuccess: mutateDeleteSuccess, onError: mutateError });

	async function mutatePost(data: DependencyGroupPostRequest) {
		setFormSaving(true);
		return await dependencyGroupService.post(props.questionId, data);
	}

	function mutatePostSuccess(resp: AxiosResponse<DependencyGroupPostResponse, any>) {
		append(resp.data);
		handleToast(toastTitle, "Informações salvas com sucesso!", 5000);
		setFormSaving(false);
	}

	async function mutateDelete({ id, index }: { id: number; index: number }) {
		setFormSaving(true);
		let resp = await dependencyGroupService.delete(id);
		return { index: index, resp: resp };
	}

	function mutateDeleteSuccess({ index, resp }: { index: number; resp: AxiosResponse<QuestionGroupDeleteResponse, any> }) {
		remove(index);
		handleToast(toastTitle, "Informações salvas com sucesso!", 5000);
		setFormSaving(false);
	}

	function mutateError(resp: AxiosError) {
		handleToast(toastTitle, `Problema na operação: ${resp.message}`, 5000, "danger");
		setFormSaving(false);
	}

	function handleAdicionar() {
		mutationPost.mutate({
			id: undefined,
			dependencies: [],
		});
	}

	function handleRemover(id: number, index: number) {
		mutationDelete.mutate({ id, index });
	}

	const questionGroups = props.watch("questionGroups");
	const DependenciasOptions: any[] = [];
	if (questionGroups) {
		for (let i = 0; i < questionGroups.length; i++) {
			let questionGroup = questionGroups[i];
			for (let j = 0; j < questionGroup.questions.length; j++) {
				let question = questionGroup.questions[j];
				let options: any[] = [];
				if (question.id !== props.questionId && question.optionGroups) {
					for (let k = 0; k < question.optionGroups.length; k++) {
						let optionGroup = question.optionGroups[k];
						for (let l = 0; l < optionGroup.options.length; l++) {
							let option = optionGroup.options[l];
							options.push({ label: `${option.title}`, value: option.id });
						}
					}
				}
				if (options.length >= 1) {
					DependenciasOptions.push({ label: question.title, options: options });
				}
			}
		}
	}

	return (
		<>
			{fields.map((dependencyGroup, dependencyGroupIndex) => {
				return (
					<Form.Group key={dependencyGroup.key} className="mb-2">
						<Row className="mb-2">
							<Col>
								<Select
									placeholder={"Selecione"}
									defaultValue={DependenciasOptions.flatMap((item) => {
										return item.options.filter((option: any) => {
											return dependencyGroup.dependencies.find((dependencie) => {
												return dependencie.optionId === option.value;
											});
										});
									})}
									options={DependenciasOptions}
									onChange={(value) => {
										let setValue = [];
										for (let i in value) {
											setValue.push({
												optionId: value[i].value,
											});
										}
										props.setValue(`questionGroups.${props.questionGroupIndex}.questions.${props.questionIndex}.dependencyGroups.${dependencyGroupIndex}.dependencies`, setValue, {
											shouldDirty: true,
										});
									}}
									styles={SelectStyle}
									isMulti={true}
									isDisabled={props.formStatus !== props.questionIndex}
									closeMenuOnSelect={false}
									{...SelectOptions}
								/>
							</Col>
							<Col lg={"auto"}>
								<Button
									variant="light"
									className="rounded-3 shadow-sm"
									onClick={() => {
										handleRemover(dependencyGroup.id!, dependencyGroupIndex);
									}}
									disabled={formSaving}
								>
									<Icon path={mdiTrashCanOutline} size={1} className="text-danger" />
								</Button>
							</Col>
						</Row>
					</Form.Group>
				);
			})}

			<Button variant="light" className="d-flex gap-2 align-items-center rounded-3 shadow-sm" onClick={handleAdicionar} disabled={formSaving}>
				<Icon path={mdiPlus} size={1} /> Adicionar Grupo de Dependências
			</Button>
		</>
	);
}
