import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Modal, Spinner } from "react-bootstrap";
import Icon from "@mdi/react";
import { mdiChevronRight, mdiTrashCanOutline } from "@mdi/js";

import { Unpacked } from "../../config/defines";
import { useAuth } from "../../context/AuthContext";
import { useToast } from "../../context/ToastContext";
import { AccessControlListGetListResponseEntity } from "../../entities/AccessControlListEntity";
import AccessControlListService from "../../services/AccessControlListService";

import ListCard from "../ListCard";
import { useQueryClient } from "react-query";

type GruposCardPropsContent = {
	data: Unpacked<AccessControlListGetListResponseEntity["grupos"]>;
	skeleton?: never;
};

type GruposCardPropsSkeleton = {
	data?: never;
	skeleton: true;
};

type GruposCardProps = GruposCardPropsContent | GruposCardPropsSkeleton;

const toastTitle = "Grupo";

export default function GruposCard(props: GruposCardProps) {
	const accessControlListService = new AccessControlListService();

	const navigate = useNavigate();
	const queryClient = useQueryClient();
	const { handleToast } = useToast();

	const [removeModal, setRemoveModal] = useState(false);
	const [removeLoadingModal, setRemoveLoadingModal] = useState(false);
	const [removed, setRemoved] = useState(false);

	function handleClick() {
		if (!props.skeleton && props.data) {
			navigate("/grupoFormulario/" + props.data.grupoId);
		}
	}

	function handleClickRemove(e: any) {
		e.stopPropagation();
		setRemoveModal(true);
	}

	async function handleRemove() {
		if (!props.skeleton && props.data) {
			setRemoveLoadingModal(true);
			let resp = await accessControlListService.deleteGroup(props.data.grupoId);
			if (resp.status === 200) {
				queryClient.invalidateQueries(["parametros"]);
				setRemoved(true);
			} else {
				handleToast(toastTitle, resp.statusText, 5000, "danger");
			}
			setRemoveModal(false);
			setRemoveLoadingModal(false);
		}
	}

	function handleCloseModal() {
		if (!removeLoadingModal) {
			setRemoveModal(false);
		}
	}

	let skeletonButton = "";
	if (props.skeleton) {
		skeletonButton = "skeleton skeleton-button";
	}

	if (removed) {
		return <></>;
	}

	return (
		<>
			<ListCard onClick={handleClick}>
				<ListCard.Content>
					<ListCard.Item>
						<ListCard.Title skeleton={props.skeleton}>Nome</ListCard.Title>
						<ListCard.Text skeleton={props.skeleton}>{props.data?.nome ?? "..."}</ListCard.Text>
					</ListCard.Item>
				</ListCard.Content>
				<ListCard.Action>
					<Button variant="light" className={`me-2 ${skeletonButton}`} onClick={handleClickRemove}>
						<Icon path={mdiTrashCanOutline} size={1} className="text-danger" />
					</Button>
					<Icon path={mdiChevronRight} size={1} className={`text-muted m-2 ${skeletonButton}`} />
				</ListCard.Action>
			</ListCard>

			<Modal show={removeModal} onHide={handleCloseModal} centered>
				<Modal.Header closeButton>
					<Modal.Title>Remover Grupo</Modal.Title>
				</Modal.Header>
				<Modal.Body className="text-center">{!removeLoadingModal ? "Deseja realmente remover este grupo?" : <Spinner />}</Modal.Body>
				{!removeLoadingModal && (
					<Modal.Footer>
						<Button variant="secondary" onClick={handleCloseModal}>
							Cancelar
						</Button>
						<Button variant="danger" onClick={handleRemove}>
							Remover
						</Button>
					</Modal.Footer>
				)}
			</Modal>
		</>
	);
}
