import React from "react";
import { ReactNode } from "react";
import { Button, Card, Col, Image } from "react-bootstrap";

type ListDataProps = {
	children: any;
	onClick?: () => void;
};

type HeaderProps = {
	children: ReactNode | ReactNode[];
	onMouseEnter?: () => void;
	onMouseLeave?: () => void;
};

type ListDataShareProps = {
	children: ReactNode | ReactNode[];
};

type IconProps = {
	children: ReactNode | ReactNode[];
	skeleton?: true;
};

type ContentProps = {
	children: ReactNode | ReactNode[];
};

type ListDataLinkProps = {
	href: string;
	children: ReactNode | ReactNode[];
};

type ListDataDocumentProps = {
	children: ReactNode | ReactNode[];
	onClick?: () => void;
};

type ListDataCheckinProps = {
	children: ReactNode | ReactNode[];
	onClick?: () => void;
};

type ItemProps = {
	children: ReactNode | ReactNode[];
	onClick?: () => void;
};

type TitleProps = {
	children: ReactNode | ReactNode[];
	className?: string;
	skeleton?: true;
};

type AvatarProps = {
	src: string;
	small?: boolean;
	skeleton?: true;
	onClick?: () => void;
};

type ListDataMenuProps = {
	children: ReactNode | ReactNode[];
};

type ListDataImageProps = {
	src: string;
	skeleton?: true;
};

interface TextProps extends React.HTMLProps<HTMLDivElement> {
	children: ReactNode | ReactNode[];
	skeleton?: any;
}

type ActionProps = {
	className?: string;
	children: ReactNode | ReactNode[];
};

const ListData = ({ children, onClick }: ListDataProps) => {
	let subComponentList = Object.keys(ListData);

	let subComponents = subComponentList.map((key) => {
		return React.Children.map(children, (child) => {
			return child.type.displayName === key ? child : false;
		});
	});

	return (
		<Card className="border-0 rounded-0" role={onClick ? "button" : "div"} onClick={onClick}>
			<Card.Body className="d-flex flex-column gap-2 p-0">{subComponents.map((component) => component)}</Card.Body>
		</Card>
	);
};

const Icon = ({ children, skeleton }: IconProps) => {
	return (
		<div className={`d-lg-flex  align-items-center ${skeleton ? "skeleton skeleton-avatar" : ""} position-relative`}>
			{!Array.isArray(children)
				? children
				: children.filter((item) => {
						return item;
				  })}
		</div>
	);
};
Icon.displayName = "Icon";

const ListDataShare = ({ children }: ListDataShareProps) => {
	return (
		<div className="d-lg-flex align-items-strech flex-fill flex-wrap gap-2 border-bottom pb-2 mb-2">
			{!Array.isArray(children)
				? children
				: children.filter((item) => {
						return item;
				  })}
		</div>
	);
};
ListDataShare.displayName = "Share";

const Header = ({ children, onMouseEnter, onMouseLeave }: HeaderProps) => {
	return (
		<div className="d-lg-flex align-items-strech flex-wrap gap-2" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
			{!Array.isArray(children)
				? children
				: children.filter((item) => {
						return item;
				  })}
		</div>
	);
};
Header.displayName = "Header";

const Content = ({ children }: ContentProps) => {
	return (
		<div className="d-lg-flex align-items-start flex-fill flex-wrap">
			{!Array.isArray(children)
				? children
				: children.filter((item) => {
						return item;
				  })}
		</div>
	);
};
Content.displayName = "Content";

const Item = ({ children, onClick }: ItemProps) => {
	return (
		<Col role={onClick ? "button" : "div"} onClick={onClick}>
			{!Array.isArray(children)
				? children
				: children.filter((item) => {
						return item;
				  })}
		</Col>
	);
};
Item.displayName = "Item";

const Avatar = ({ src, small, skeleton, onClick }: AvatarProps) => {
	let width = 48;
	let height = 48;
	if (small) {
		width = width / 2;
		height = height / 2;
	}
	return (
		<div className={`${skeleton ? "skeleton skeleton-avatar" : ""} `} role={onClick ? "button" : "div"} onClick={onClick} style={{ width: width, height: height }}>
			<Image src={src} fluid className="rounded-circle object-fit-cover" width={48} height={48} style={{ width: 48, height: 48 }} />
		</div>
	);
};
Avatar.displayName = "Avatar";

const ListDataLink = ({ href, children }: ListDataLinkProps) => {
	return (
		<a href={href} className="d-lg-flex justify-content-between align-items-center flex-fill flex-wrap bg-light rounded p-3 text-decoration-none" target="_blank" rel="noreferrer">
			{!Array.isArray(children)
				? children
				: children.filter((item) => {
						return item;
				  })}
		</a>
	);
};
ListDataLink.displayName = "Link";

const ListDataDocument = ({ onClick, children }: ListDataDocumentProps) => {
	return (
		<Button variant="light" onClick={onClick} className="d-lg-flex justify-content-between align-items-center flex-fill flex-wrap p-3" style={{ textAlign: "left" }}>
			{!Array.isArray(children)
				? children
				: children.filter((item) => {
						return item;
				  })}
		</Button>
	);
};
ListDataDocument.displayName = "Link";

const ListDataCheckin = ({ onClick, children }: ListDataCheckinProps) => {
	return (
		<div onClick={onClick} className="d-lg-flex flex-column justify-content-between flex-fill flex-wrap bg-light rounded p-3 gap-2">
			{!Array.isArray(children)
				? children
				: children.filter((item) => {
						return item;
				  })}
		</div>
	);
};
ListDataCheckin.displayName = "Link";

const Title = ({ children, className, skeleton }: TitleProps) => {
	return (
		<div className={`line-1 fw-lighter ${skeleton ? "skeleton skeleton-text skeleton-text-title" : ""} ${className}`} title={!Array.isArray(children) ? children?.toString() : ""}>
			{!Array.isArray(children)
				? children
				: children.filter((item) => {
						return item;
				  })}
		</div>
	);
};
Title.displayName = "Title";

const Subtitle = ({ children, skeleton }: TitleProps) => {
	return (
		<div
			className={`d-flex align-items-center line-1 fw-lighter text-muted small ${skeleton ? "skeleton skeleton-text skeleton-text-title" : ""} `}
			title={!Array.isArray(children) ? children?.toString() : ""}
		>
			{!Array.isArray(children)
				? children
				: children.filter((item) => {
						return item;
				  })}
		</div>
	);
};
Subtitle.displayName = "Subtitle";

const Text = ({ children, skeleton, ...rest }: TextProps) => {
	return (
		<div className={`line-1 fw-light ${skeleton ? "skeleton skeleton-text skeleton-text-content" : ""} `} title={!Array.isArray(children) ? children?.toString() : ""} {...rest}>
			{!Array.isArray(children)
				? children
				: children.filter((item) => {
						return item;
				  })}
		</div>
	);
};
Text.displayName = "Text";

const ListDataMenu = ({ children }: ListDataMenuProps) => {
	return (
		<div>
			{!Array.isArray(children)
				? children
				: children.filter((item) => {
						return item;
				  })}
		</div>
	);
};
ListDataMenu.displayName = "Menu";

const ListDataImage = ({ src, skeleton }: ListDataImageProps) => {
	return <Card.Img src={src} className="rounded-0" />;
};
ListDataImage.displayName = "Image";

const Action = ({ children, className }: ActionProps) => {
	return (
		<div className={`d-flex align-items-center flex-nowrap gap-2 ${className}`}>
			{!Array.isArray(children)
				? children
				: children.filter((item) => {
						return item;
				  })}
		</div>
	);
};
Action.displayName = "Action";

ListData.Icon = Icon;
ListData.Share = ListDataShare;
ListData.Header = Header;
ListData.Content = Content;
ListData.Item = Item;
ListData.Avatar = Avatar;
ListData.Link = ListDataLink;
ListData.Document = ListDataDocument;
ListData.Checkin = ListDataCheckin;
ListData.Title = Title;
ListData.Subtitle = Subtitle;
ListData.Menu = ListDataMenu;
ListData.Text = Text;
ListData.Image = ListDataImage;
ListData.Action = Action;
export default ListData;
