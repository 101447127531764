import { UserLoginPostResponseEntity } from "../entities/UserEntity";
import { ModuleEntity, ModulePermissionEntity } from "./defines";

export function modulePermissionCheck(user: UserLoginPostResponseEntity, module: ModuleEntity, permission?: ModulePermissionEntity): boolean {
	return user.modules.some((userModule) => {
		if (permission) {
			return userModule.permissions.some((userPermission) => {
				return userPermission === permission;
			});
		}
		return userModule.id === module;
	});
}
