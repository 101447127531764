import ApiService from "./ApiService";
import {
	AvaliacaoGetAvaliacaoDetalhesResponseEntity,
	AvaliacaoGetAvaliacaoResponseEntity,
	AvaliacaoGetParametroResponseEntity,
	AvaliacaoGetParametrosResponseEntity,
	AvaliacaoPostAvaliacaoRespostaRequestEntity,
	AvaliacaoPostAvaliacaoRespostaResponseEntity,
	QuestionnaireDeleteResponse,
	QuestionnaireGetQuestionnairesResponse,
	QuestionnaireGetResponse,
	QuestionnairePostRequest,
	QuestionnairePostResponse,
	QuestionnairePutRequest,
	QuestionnairePutResponse,
} from "../entities/QuestionnaireEntity";

export default class QuestionnaireService extends ApiService {
	public async getQuestionnaires() {
		return await this.request.get<QuestionnaireGetQuestionnairesResponse[]>(`/api/v1/Questionnaires`);
	}
	public async getQuestionnairesResponses(headers: any = {}) {
		return await this.request.get<QuestionnaireGetResponse[]>(`/api/v1/Questionnaires`, { headers: headers });
	}
	public async get(id: number) {
		return await this.request.get<QuestionnaireGetResponse>(`/api/v1/Questionnaire/${id}`);
	}
	public async post(data: QuestionnairePostRequest) {
		return await this.request.post<QuestionnairePostResponse>(`/api/v1/Questionnaire`, data);
	}
	public async put(id: number, data: QuestionnairePutRequest) {
		return await this.request.put<QuestionnairePutResponse>(`/api/v1/Questionnaire/${id}`, data);
	}
	public async delete(id: number) {
		return await this.request.delete<QuestionnaireDeleteResponse>(`/api/v1/Questionnaire/${id}`);
	}

	public async getAvaliacao(id: number) {
		let user = this.user();
		let response;
		try {
			let req = await this.mock.get<AvaliacaoGetAvaliacaoResponseEntity>(`/api/v1/Avaliacao/${id}`, {
				headers: { Authorization: `Bearer ${user.hashLogin}` },
			});
			response = req.data;
		} catch (e) {
			return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" };
		}
		return response;
	}

	public async getAvaliacaoDetalhes(id: number) {
		let user = this.user();
		let response;
		try {
			let req = await this.request.get<AvaliacaoGetAvaliacaoDetalhesResponseEntity>(`/gerenciador/api/v1/Satisfacao/PesquisaDetalhes?empresaAppId=${id}`, {
				headers: { Authorization: `Bearer ${user.hashLogin}` },
			});
			response = req.data;
		} catch (e) {
			return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" };
		}
		return response;
	}

	public async postAvaliacaoResposta(data: AvaliacaoPostAvaliacaoRespostaRequestEntity) {
		let user = this.user();
		let response;
		try {
			let req = await this.request.post<AvaliacaoPostAvaliacaoRespostaResponseEntity>("/gerenciador/api/v1/Satisfacao/PesquisaDetalhes", data, {
				headers: { Authorization: `Bearer ${user.hashLogin}` },
			});
			response = req.data;
		} catch (e) {
			return { Result: 0, Message: "Problema na operação tente novamente mais tarde!" };
		}
		return response;
	}

	public async getParametros() {
		return await this.mock.get<AvaliacaoGetParametrosResponseEntity>(`/api/v1/Parametros`);
	}

	public async getParametro(id: number) {
		return await this.mock.get<AvaliacaoGetParametroResponseEntity>(`/api/v1/Parametro/${id}`);
	}
}
