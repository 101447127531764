import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, Card, Spinner } from "react-bootstrap";
import { useQuery } from "react-query";
import Icon from "@mdi/react";
import { mdiChevronLeft, mdiOfficeBuildingOutline } from "@mdi/js";

import { ModuleEntity, ModulePermissionEntity } from "../../config/defines";
import { modulePermissionCheck } from "../../config/utils";
import { useAuth } from "../../context/AuthContext";
import { QuestionnaireResponseEntity } from "../../entities/QuestionnaireResponseEntity";
import QuestionnaireResponseService from "../../services/QuestionnaireResponseService";

import ListData from "../../components/ListData";
import Layout from "../../components/Layout";

export default function AvaliacaoDetalhes() {
	const questionnaireResponseService = new QuestionnaireResponseService();

	const navigate = useNavigate();
	const { user } = useAuth();
	const { id } = useParams();

	const { data, isLoading, isFetching, isRefetching } = useQuery<QuestionnaireResponseEntity>(["avaliacaoResposta", id], () => fetchData(id), { enabled: id !== undefined });

	async function fetchData(id: any) {
		let resp = await questionnaireResponseService.get(id);
		return resp.data;
	}

	return (
		<Layout>
			<h5 className="mt-4 mb-4 d-flex align-items-center fw-light">
				<Link to={"/avaliacoes"} className="d-flex align-items-center text-decoration-none">
					<Icon path={mdiChevronLeft} size={1} className="me-1" /> <Icon path={mdiOfficeBuildingOutline} size={1} className="me-1" /> Detalhes da Avaliação
				</Link>
				{(isLoading || isFetching || isRefetching) && <Spinner size="sm" className="ms-1" variant="secondary" />}
				<div className="d-flex gap-2 float-right ms-auto" style={{ marginTop: -10, marginBottom: -10 }}>
					{modulePermissionCheck(user!, ModuleEntity.avaliacoes, ModulePermissionEntity.editar) && (
						<Button
							variant="light"
							className="rounded-3 shadow-sm"
							onClick={() => {
								navigate(`/avaliacaoFormulario/${data?.questionnaireId}/${id}`);
							}}
						>
							Editar Informações
						</Button>
					)}
				</div>
			</h5>

			<Card className="mb-3">
				<Card.Body>
					<ListData>
						<ListData.Content>
							<ListData.Item>
								<ListData.Title>Usuário</ListData.Title>
								<ListData.Text skeleton={isLoading}>{data?.person.name}&nbsp;</ListData.Text>
							</ListData.Item>
							<ListData.Item>
								<ListData.Title>Data</ListData.Title>
								<ListData.Text skeleton={isLoading}>{data?.responseDate}&nbsp;</ListData.Text>
							</ListData.Item>
						</ListData.Content>
						<ListData.Content>
							<ListData.Item>
								<ListData.Title>Risco Estrutural</ListData.Title>
								<ListData.Text skeleton={isLoading}>90 - Comprometimento Grave</ListData.Text>
								<div className="d-flex border border-secondary position-relative mt-2 mb-4">
									<div className="flex-fill border" style={{ background: "#34cc33", height: 40 }} />
									<div className="flex-fill border" style={{ background: "#34cc33", height: 40 }} />
									<div className="flex-fill border" style={{ background: "#339965", height: 40 }} />
									<div className="flex-fill border" style={{ background: "#339965", height: 40 }} />
									<div className="flex-fill border" style={{ background: "#ffff00", height: 40 }} />
									<div className="flex-fill border" style={{ background: "#ffff00", height: 40 }} />
									<div className="flex-fill border" style={{ background: "#ffbf00", height: 40 }} />
									<div className="flex-fill border" style={{ background: "#ffbf00", height: 40 }} />
									<div className="flex-fill border" style={{ background: "#c00201", height: 40 }} />
									<div className="flex-fill border" style={{ background: "#c00201", height: 40 }} />
									<div className="position-absolute" style={{ top: -10, left: "90%", height: 60, transform: "translateX(-25%)" }}>
										<div className="bg-black text-white" style={{ width: 10, height: 60 }}></div>
										<div className="fw-bold" style={{ marginLeft: -5 }}>
											90
										</div>
									</div>
								</div>
							</ListData.Item>
						</ListData.Content>
						<ListData.Content>
							<ListData.Item>
								<ListData.Title>Vulnerabilidade Funcional</ListData.Title>
								<ListData.Text skeleton={isLoading}>100 - Funcionalidade Severamente Comprometida</ListData.Text>
								<div className="d-flex border border-secondary position-relative mt-2 mb-4">
									<div className="flex-fill border" style={{ background: "#34cc33", height: 40 }} />
									<div className="flex-fill border" style={{ background: "#34cc33", height: 40 }} />
									<div className="flex-fill border" style={{ background: "#339965", height: 40 }} />
									<div className="flex-fill border" style={{ background: "#339965", height: 40 }} />
									<div className="flex-fill border" style={{ background: "#ffff00", height: 40 }} />
									<div className="flex-fill border" style={{ background: "#ffff00", height: 40 }} />
									<div className="flex-fill border" style={{ background: "#ffbf00", height: 40 }} />
									<div className="flex-fill border" style={{ background: "#ffbf00", height: 40 }} />
									<div className="flex-fill border" style={{ background: "#c00201", height: 40 }} />
									<div className="flex-fill border" style={{ background: "#c00201", height: 40 }} />
									<div className="position-absolute" style={{ top: -10, left: "100%", height: 60, transform: "translateX(-25%)" }}>
										<div className="bg-black text-white" style={{ width: 10, height: 60 }}></div>
										<div className="fw-bold" style={{ marginLeft: -5 }}>
											100
										</div>
									</div>
								</div>
							</ListData.Item>
						</ListData.Content>
						<ListData.Content>
							<ListData.Item>
								<ListData.Title>Índice de Comprometimento</ListData.Title>
								<ListData.Text skeleton={isLoading}>93 - Comprometimento Muito Alto</ListData.Text>
								<div className="d-flex border border-secondary position-relative mt-2 mb-4">
									<div className="flex-fill border" style={{ background: "#34cc33", height: 40 }} />
									<div className="flex-fill border" style={{ background: "#34cc33", height: 40 }} />
									<div className="flex-fill border" style={{ background: "#339965", height: 40 }} />
									<div className="flex-fill border" style={{ background: "#339965", height: 40 }} />
									<div className="flex-fill border" style={{ background: "#ffff00", height: 40 }} />
									<div className="flex-fill border" style={{ background: "#ffff00", height: 40 }} />
									<div className="flex-fill border" style={{ background: "#ffbf00", height: 40 }} />
									<div className="flex-fill border" style={{ background: "#ffbf00", height: 40 }} />
									<div className="flex-fill border" style={{ background: "#c00201", height: 40 }} />
									<div className="flex-fill border" style={{ background: "#c00201", height: 40 }} />
									<div className="position-absolute" style={{ top: -10, left: "93%", height: 60, transform: "translateX(-25%)" }}>
										<div className="bg-black  text-white" style={{ width: 10, height: 60 }}></div>
										<div className="fw-bold" style={{ marginLeft: -5 }}>
											93
										</div>
									</div>
								</div>
							</ListData.Item>
						</ListData.Content>
					</ListData>
				</Card.Body>
			</Card>

			{modulePermissionCheck(user!, ModuleEntity.avaliacoes, ModulePermissionEntity.editar) && (
				<div className="d-flex gap-2 mb-4">
					<Button
						variant="light"
						className="rounded-3 shadow-sm"
						onClick={() => {
							navigate(`/avaliacaoFormulario/${data?.questionnaireId}/${id}`);
						}}
					>
						Editar Informações
					</Button>
				</div>
			)}
		</Layout>
	);
}
