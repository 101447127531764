import ApiService from "./ApiService";
import { DomainEntityDeleteResponse, DomainEntityGetResponse, DomainEntityPostRequest, DomainEntityPostResponse, DomainEntityPutRequest, DomainEntityPutResponse } from "../entities/DomainEntity";

export default class DomainService extends ApiService {
	public async getDomains() {
		return await this.request.get<DomainEntityGetResponse[]>(`/api/v1/Domains`);
	}
	public async get(id: number) {
		return await this.request.get<DomainEntityGetResponse>(`/api/v1/Domain/${id}`);
	}
	public async post(data: DomainEntityPostRequest) {
		return await this.request.post<DomainEntityPostResponse>(`/api/v1/Domain`, data);
	}
	public async put(id: number, data: DomainEntityPutRequest) {
		return await this.request.put<DomainEntityPutResponse>(`/api/v1/Domain/${id}`, data);
	}
	public async delete(id: number) {
		return await this.request.delete<DomainEntityDeleteResponse>(`/api/v1/Domain/${id}`);
	}
}
