import ApiService from "./ApiService";
import {
	ParameterDomainOptionDeleteResponse,
	ParameterDomainOptionGetResponse,
	ParameterDomainOptionPostRequest,
	ParameterDomainOptionPostResponse,
	ParameterDomainOptionPutRequest,
	ParameterDomainOptionPutResponse,
} from "../entities/ParameterDomainOptionEntity";

export default class ParameterDomainOptionService extends ApiService {
	public async get(id: number) {
		return await this.request.get<ParameterDomainOptionGetResponse>(`/api/v1/ParameterDomainOption/${id}`);
	}
	public async post(id: number, data: ParameterDomainOptionPostRequest) {
		return await this.request.post<ParameterDomainOptionPostResponse>(`/api/v1/ParameterDomainOption`, data, { params: { parameterDomainId: id } });
	}
	public async put(id: number, data: ParameterDomainOptionPutRequest) {
		return await this.request.put<ParameterDomainOptionPutResponse>(`/api/v1/ParameterDomainOption/${id}`, data);
	}
	public async delete(id: number) {
		return await this.request.delete<ParameterDomainOptionDeleteResponse>(`/api/v1/ParameterDomainOption/${id}`);
	}
}
