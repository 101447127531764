import { Link, useNavigate } from "react-router-dom";
import { Alert, Button, Col, Row, Spinner } from "react-bootstrap";
import Icon from "@mdi/react";
import { mdiAccountMultipleOutline, mdiCardRemoveOutline, mdiChevronRight, mdiLockOutline } from "@mdi/js";
import { useQuery } from "react-query";

import { ModuleEntity, ModulePermissionEntity, SkeletonCount } from "../../config/defines";
import { modulePermissionCheck } from "../../config/utils";
import { useAuth } from "../../context/AuthContext";
import { PersonGetPersonsResponse } from "../../entities/PersonEntity";
import PersonService from "../../services/PersonService";

import UsuariosCard from "../../components/UsuariosCard";
import Layout from "../../components/Layout";

export default function Usuarios() {
	const personService = new PersonService();

	const navigate = useNavigate();
	const { user } = useAuth();

	const { data, isLoading, isRefetching, isError } = useQuery<PersonGetPersonsResponse>(["usuarios"], () => fetchData());

	async function fetchData() {
		let resp = await personService.getPersons();
		return resp.data;
	}

	return (
		<Layout>
			<h5 className="mt-4 mb-4 d-flex align-items-center fw-light">
				<Link to={"/acessos"} className="d-flex text-decoration-none">
					<Icon path={mdiLockOutline} size={1} className="me-1" /> Acessos
				</Link>
				<Icon path={mdiChevronRight} size={1} className="ms-1 me-1" />
				<Icon path={mdiAccountMultipleOutline} size={1} className="me-1" /> Usuários
				{isRefetching && <Spinner size="sm" className="ms-1" variant="secondary" />}
				<div className="d-flex gap-2 float-right ms-auto" style={{ marginTop: -10, marginBottom: -10 }}>
					{modulePermissionCheck(user!, ModuleEntity.acessos, ModulePermissionEntity.editar) && (
						<Button
							variant="primary"
							className="rounded-3 shadow-sm"
							onClick={() => {
								navigate("/usuarioFormulario");
							}}
						>
							Cadastrar
						</Button>
					)}
				</div>
			</h5>

			<Row className="mb-4">
				{isLoading &&
					Array.from({ length: Number(SkeletonCount) }, (_, index) => {
						return (
							<Col md={12} key={index}>
								<UsuariosCard skeleton />
							</Col>
						);
					})}
				{!isLoading && isError && (
					<Col md={12}>
						<Alert variant="secondary" className="text-center">
							Problema na operação. Tente novamente mais tarde
						</Alert>
					</Col>
				)}
				{!isLoading && data && data.length === 0 && (
					<Col md={12}>
						<Alert variant="light" className="text-center p-4 d-flex justify-content-center align-items-center">
							<Icon path={mdiCardRemoveOutline} size={1} className="me-2" />
							Nenhum registro encontrado
						</Alert>
					</Col>
				)}
				{!isLoading &&
					data &&
					data.length >= 1 &&
					data.map((item, index) => {
						return (
							<Col md={12} key={item.id}>
								<UsuariosCard data={item} />
							</Col>
						);
					})}
			</Row>
		</Layout>
	);
}
