import { PerguntaTipo } from "../entities/QuestionnaireEntity";

type M<T> = { status: number; data: T };

export default class MockService {
	get<T = any, R = M<T>>(url: string, ..._: any): Promise<R> {
		return new Promise((resolve) => {
			setTimeout(() => {
				resolve(mockData[url] ? (mockData[url] as R) : ({} as R));
			}, 1000);
		});
	}

	post<T = any, R = M<T>>(url: string, ..._: any): Promise<R> {
		return new Promise((resolve) => {
			resolve(mockData[url] ? (mockData[url] as R) : ({} as R));
		});
	}
}

const mockData: { [key: string]: any } = {
	"/api/v1/Avaliacao/1": {
		data: {
			Result: 1,
			Data: {
				id: 1,
				titulo: "ReHabiLAR - Mapeamento de Edificações Pós-Inundação",
				texto: "Este formulário é baseado em um modelo de classificação desenvolvido para auxiliar na avaliação de edificações afetadas por inundações. O modelo considera indicadores de risco estrutural, suscetibilidade estrutural à inundação, vulnerabilidade funcional da edificação e índice de comprometimento. O objetivo deste modelo é fornecer uma ferramenta que auxilie os profissionais envolvidos na gestão de desastres a tomarem decisões mais eficientes e eficazes sobre as intervenções necessárias nas edificações afetadas por inundações. Ao preencher este formulário, você estará contribuindo para a gestão eficaz de desastres, ajudando a identificar as prioridades de intervenção e promovendo medidas preventivas e mitigadoras que aumentam a resiliência das edificações e das comunidades afetadas por inundações.",
				grupos: [
					// Informações da Edificação
					{
						id: 1,
						titulo: "Informações da Edificação",
						texto: "Forneça dados relativos a edificação",
						cor: "#90D14F",
						perguntas: [
							{
								id: 1,
								titulo: "Geolocalização",
								texto: "Insira a localização aproximada da edificação. Exemplo: R. Diretor Augusto Pestana, 1342 - Humaitá, Porto Alegre",
								tipo: PerguntaTipo.geo,
							},
							{
								id: 2,
								titulo: "Insira uma Imagem da Edificação",
								texto: "Essa imagem será útil para avaliar visualmente o estado da edificação e pode fornecer informações adicionais sobre os danos causados pelas inundações. Se possível, inclua fotos do de frente à rua de acesso a edificação.",
								tipo: PerguntaTipo.image,
							},
							{
								id: 3,
								titulo: "Possui subsolo?",
								texto: "Por definição da ferramenta, subsolo é considerado uma parte da edificação (garagem, área de serviço, depósitos e outros).",
								tipo: PerguntaTipo.objective,
							},
							{
								id: 4,
								titulo: "A edificação possui sistema construtivo em pilotis ou palafita?",
								texto: "Indique se a  edificações foi construída em pilotis (palafitas).",
								tipo: PerguntaTipo.objective,
							},
							{
								id: 5,
								titulo: "Número de Pavimentos",
								texto: "Selecione o Número de Pavimentos da Edificação",
								tipo: PerguntaTipo.radio,
								opcoes: [
									[
										{
											id: 1,
											titulo: "1",
											valor: 1,
										},
										{
											id: 2,
											titulo: "2",
											valor: 2,
										},
										{
											id: 3,
											titulo: "3",
											valor: 3,
										},
										{
											id: 4,
											titulo: "4",
											valor: 4,
										},
									],
								],
							},
							{
								id: 6,
								titulo: "Estimativa de área Construída (m²)",
								texto: "Informe a área construída da edificação, considerando cada pavimento separadamente ou fornecendo um valor médio para todos os pavimentos (m²)",
								tipo: PerguntaTipo.number,
							},
							{
								id: 7,
								titulo: "Detalhe da Fundação",
								texto: "Se possível indique a tipologia da fundação da edificação.",
								tipo: PerguntaTipo.checkbox,
								required: true,
								opcoes: [
									[
										{
											id: 5,
											titulo: "Não foi possivel identificar",
											valor: 5,
										},
										{
											id: 6,
											titulo: "Sapatas",
											valor: 6,
										},
										{
											id: 7,
											titulo: "Sapata Corrida",
											valor: 7,
										},
										{
											id: 8,
											titulo: "Estacas",
											valor: 8,
										},
										{
											id: 9,
											titulo: "Viga de Baldrame",
											valor: 9,
										},
										{
											id: 10,
											titulo: "Blocos",
											valor: 10,
										},
										{
											id: 11,
											titulo: "Alvenaria/Pedras",
											valor: 11,
										},
										{
											id: 12,
											titulo: "Outros",
											valor: 12,
										},
									],
								],
							},
							{
								id: 8,
								titulo: "Detalhes do Sistema Estrutural",
								texto: "Selecione a característica construtivas que mais representa o sistema estrutural da edificação.",
								tipo: PerguntaTipo.radio,
								opcoes: [
									[
										{
											id: 13,
											titulo: "Concreto Armado",
											valor: 1,
										},
										{
											id: 14,
											titulo: "Madeira",
											valor: 2,
										},
										{
											id: 15,
											titulo: "Parede Estrutural (autoportante)",
											valor: 3,
										},
										{
											id: 16,
											titulo: "Aço Metálica",
											valor: 4,
										},
										{
											id: 17,
											titulo: "Não identificado",
											valor: 5,
										},
										{
											id: 18,
											titulo: "Steel/wood frame ou similar",
											valor: 6,
										},
									],
								],
							},
							{
								id: 9,
								titulo: "Característica da Laje - Tipo Estrutural",
								texto: "Indique o tipo de material que mais representa o tipo de piso da edificação.",
								tipo: PerguntaTipo.radio,
								opcoes: [
									[
										{
											id: 19,
											titulo: "Concreto Armado",
											valor: 1,
										},
										{
											id: 20,
											titulo: "Estrutura de Madeira",
											valor: 2,
										},
										{
											id: 21,
											titulo: "Estrutura Metálica",
											valor: 3,
										},
										{
											id: 22,
											titulo: "Não há na Edificação",
											valor: 4,
										},
									],
								],
							},
							{
								id: 10,
								titulo: "Especificações dos Pisos - Tipo de Material",
								texto: "Indique o tipo de material que mais representa o tipo de piso da edificação.",
								tipo: PerguntaTipo.checkbox,
								opcoes: [
									[
										{
											id: 23,
											titulo: "Concreto/Argamassado",
											valor: 1,
										},
										{
											id: 24,
											titulo: "Madeira ou Assemelhados",
											valor: 2,
										},
										{
											id: 25,
											titulo: "Cerâmico ou Assemelhados",
											valor: 3,
										},
										{
											id: 26,
											titulo: "Não identificado",
											valor: 4,
										},
									],
								],
							},
							{
								id: 11,
								titulo: "Sistema de Paredes da Edificação",
								texto: "Selecione a característica construtivas que mais representa o sistema paredes da edificação.",
								tipo: PerguntaTipo.radio,
								opcoes: [
									[
										{
											id: 27,
											titulo: "Alvenaria (tijolo, bloco cerâmico ou concreto)",
											valor: 1,
										},
										{
											id: 28,
											titulo: "Divisórias/Paredes de Madeira",
											valor: 2,
										},
										{
											id: 29,
											titulo: "Gesso Acartonado, placas Cimentícias ou Similares",
											valor: 3,
										},
										{
											id: 30,
											titulo: "Metálica",
											valor: 4,
										},
										{
											id: 31,
											titulo: "Não identificado",
											valor: 5,
										},
										{
											id: 32,
											titulo: "Mista (Alvenaria/Madeira)",
											valor: 6,
										},
									],
								],
							},
							{
								id: 12,
								titulo: "Estado atual da Edificação",
								texto: "Instruções: Selecione uma das opções abaixo para indicar o estado atual da edificação. Essa informação é importante para avaliar o risco estrutural da edificação e demais parâmetros. A sequência do levantamento de dados dependerá do estado atual.",
								tipo: PerguntaTipo.radio,
								opcoes: [
									[
										{
											id: 32,
											titulo: "SEM DANOS: Escolha esta opção se a edificação não sofreu nenhum dano ou não houve ocorrência de inundação.",
											valor: 1,
										},
										{
											id: 33,
											titulo: "RECUPERÁVEL: Escolha esta opção se a edificação sofreu danos, mas ainda pode ser restaurada e reabilitada. Exemplos: danos estruturais leves ou inexistentes, podendo ser acessada a edificação.",
											valor: 2,
										},
										{
											id: 34,
											titulo: "PARCIALMENTE COLAPSADA: Selecione esta opção se a edificação sofreu danos significativos, afetando partes substanciais da estrutura. Exemplos: desabamento parcial de paredes, colapso de lajes, vigas ou pilares, danos severos em sistemas. Nesse estado, não é possível acessar a edificação com segurança.",
											valor: 3,
										},
										{
											id: 35,
											titulo: "TOTALMENTE COLAPSADA: Escolha esta opção se a edificação sofreu danos graves que não é mais viável recuperá-la. Exemplos: desabamento completo, estrutura comprometida, impossibilidade de uso seguro.",
											valor: 4,
										},
										{
											id: 36,
											titulo: "SEM ACESSO: Escolha esta opção se não foi possível acessar a edificação para avaliação. Exemplos: entrada bloqueada por escombros, área de risco inacessível, autorização de acesso negada.",
											valor: 5,
										},
									],
								],
							},
						],
					},
					// INUNDAÇÃO
					{
						id: 2,
						titulo: "Mapeamento de Edificações Pós-Inundação",
						texto: "Nesta seção, são coletadas informações essenciais para aplicar a ferramenta ReHabiLAR para mapeamento classificação das edificações. Os indicadores incluem risco estrutural, vulnerabilidade funcional da edificação e o índice de comprometimento.",
						cor: "#14CDD4",
						perguntas: [
							{
								id: 13,
								titulo: "Nível de Inundação na Edificação*",
								texto: "Para avaliar o nível de inundação da edificação, utilize a seguinte escala:",
								tipo: PerguntaTipo.parametro,
								parametroId: 19,
								opcoes: [
									[
										{
											id: 37,
											titulo: "Nível 0",
											informacoes: "Não atingiu a edificação.",
											dominioOptionId: 17,
											valor: 0,
										},
										{
											id: 38,
											titulo: "Nível 1",
											informacoes: "Atingiu somente o pavimento térreo da edificação com altura inferior a 0,5 m (se houver subsolo e foi inundado, considerar nível 4).",
											dominioOptionId: 18,
											valor: 1,
										},
										{
											id: 39,
											titulo: "Nível 2",
											informacoes: "Atingiu somente o pavimento térreo da edificação com altura acima de 0,5 m e abaixo de 1,5 m.",
											dominioOptionId: 19,
											valor: 2,
										},
										{
											id: 40,
											titulo: "Nível 3",
											informacoes:
												" Atingiu somente o pavimento térreo da edificação com altura acima de 1,5 m e abaixo do segundo pavimento. Em edificações de 1 pavimento a inundação ficou abaixo do forro da edificação.",
											dominioOptionId: 20,
											valor: 3,
										},
										{
											id: 41,
											titulo: "Nível 4",
											informacoes:
												"Atingiu o segundo pavimento da edificação. Em edificações de 1 pavimento a inundação ficou acima do forro porém a edificação não foi coberta totalmente pela água.",
											dominioOptionId: 21,
											valor: 4,
										},
										{
											id: 42,
											titulo: "Nível 5",
											informacoes: "Edificação foi totalmente coberta pela inundação (todos os pavimentos).",
											dominioOptionId: 22,
											valor: 5,
										},
									],
								],
							},
						],
					},
					// FUNDAÇÃO
					{
						id: 3,
						titulo: "Indicativo de Dano Estrutural: Fundação",
						texto: "Indicativos de danos na fundação podem ser quanto a recalque, erosão, infiltração, fissura(s), entre outros.",
						cor: "#F24727",
						perguntas: [
							{
								id: 14,
								titulo: "Indicativo de dano na Fundação",
								texto: "Os indicativos de danos referem-se a pior condição encontrada para todas as Fundações. A seguir, são apresentados exemplos de danos em Fundações para cada nível de intensidade:",
								tipo: PerguntaTipo.parametro,
								parametroId: 1,
								opcoes: [
									[
										{
											id: 100,
											parametroOptionId: 1,
											titulo: "Intensidade 0",
											informacoes: "Não há indicativo de dano na fundação pré-existente ou devido à inundação.",
											valor: 0,
										},
										{
											id: 102,
											parametroOptionId: 2,
											titulo: "Intensidade 1",
											informacoes:
												"Há indicativo de dano leve na fundação devido à inundação ou pré-existente, como erosão do solo e exposição superficial da fundação (pilaretes, blocos de fundação, vigas de baldrame), porém não é visível a base de apoio da fundação (estacas, sapatas, fundação superficial).",
											valor: 1,
										},
										{
											id: 103,
											parametroOptionId: 3,
											titulo: "Intensidade 2",
											informacoes:
												"Há indicativo de dano moderado na fundação devido à inundação ou pré-existente, como erosão e exposição da base de apoio da fundação (estacas, sapatas, fundação superficial, entre outras), que podem reduzir parcialmente a segurança da fundação.",
											valor: 2,
										},
										{
											id: 104,
											parametroOptionId: 4,
											titulo: "Intensidade 3",
											informacoes:
												"Há indicativo de dano grave na fundação devido à inundação ou pré-existente, como erosão profunda (abaixo da base de apoio da fundação), movimentação do solo ou indicativo, recalque diferencial causado pelo solo erodido, fissura(s) na edificação que indicam movimentação da fundação, comprometimento de muro(s) de contenção ou outros indicativos que comprometem significativamente a segurança da fundação.",
											valor: 3,
										},
										{
											id: 105,
											parametroOptionId: 5,
											titulo: "Intensidade 4",
											informacoes:
												" Há indicativo de dano severo na fundação devido à inundação ou pré-existente, como indicativo de ruptura, deformação excessiva, recalque, movimentação, perda da base de apoio da fundação ou outros indicativos que causam a perda da segurança da fundação.",
											valor: 4,
										},
										{
											id: 106,
											parametroOptionId: 6,
											titulo: "Intensidade 5",
											informacoes:
												"Há indicativo de dano extremamente severo na fundação devido à inundação ou pré-existente,  como colapso parcial ou total da fundação, que comprometem a integridade da estrutura. Há indicativos de movimentação de encostas e deslizamento de terras.",
											valor: 5,
										},
										{
											id: 107,
											parametroOptionId: 7,
											titulo: "Não avaliada",
											informacoes: "Não foi possível avaliar a fundação.",
											valor: null,
										},
									],
									[
										{ id: 108, parametroOptionId: 9, titulo: "Extensão 0", valor: 0 },
										{ id: 109, parametroOptionId: 10, titulo: "Extensão 1", valor: 1 },
										{ id: 110, parametroOptionId: 11, titulo: "Extensão 2", valor: 2 },
										{ id: 111, parametroOptionId: 12, titulo: "Extensão 3", valor: 3 },
										{ id: 112, parametroOptionId: 13, titulo: "Extensão 4", valor: 4 },
										{ id: 113, parametroOptionId: 14, titulo: "Extensão 5", valor: 5 },
										{ id: 114, parametroOptionId: 15, titulo: "Não avaliada", valor: null },
										{ id: 115, parametroOptionId: 16, titulo: "Não se aplica", valor: null },
									],
								],
								dependencia: [[33, 34]],
							},
						],
					},
					// PILAR DE CONCRETO ARMADO
					{
						id: 4,
						titulo: "Indicativo de Dano Estrutural: Pilar de Concreto Armado",
						texto: "Indicativos de danos nos pilares podem ser quanto a ruptura parcial ou colapso, incidência de  corrosão pré-existente na armadura, sobrecarga, fissura, deformação, desplacamento, entre outros.",
						cor: "#F24727",
						perguntas: [
							{
								id: 15,
								titulo: "Indicativo de dano nos Pilares de Concreto Armado",
								texto: "Os danos referem-se a pior condição encontrada para todas os Pilares. A seguir, são apresentados exemplos de danos em Pilares para cada nível de intensidade:",
								tipo: PerguntaTipo.parametro,
								parametroId: 2,
								opcoes: [
									[
										{
											id: 200,
											dominioOptionId: 1,
											titulo: "Intensidade 0",
											informacoes: "Não há indicativo de dano na fundação pré-existente ou devido à inundação.",
											valor: 0,
										},
										{
											id: 202,
											dominioOptionId: 2,
											titulo: "Intensidade 1",
											informacoes:
												"Há indicativo de dano leve na fundação devido à inundação ou pré-existente, como erosão do solo e exposição superficial da fundação (pilaretes, blocos de fundação, vigas de baldrame), porém não é visível a base de apoio da fundação (estacas, sapatas, fundação superficial).",
											valor: 1,
										},
										{
											id: 203,
											dominioOptionId: 3,
											titulo: "Intensidade 2",
											informacoes:
												"Há indicativo de dano moderado na fundação devido à inundação ou pré-existente, como erosão e exposição da base de apoio da fundação (estacas, sapatas, fundação superficial, entre outras), que podem reduzir parcialmente a segurança da fundação.",
											valor: 2,
										},
										{
											id: 204,
											dominioOptionId: 4,
											titulo: "Intensidade 3",
											informacoes:
												"Há indicativo de dano grave na fundação devido à inundação ou pré-existente, como erosão profunda (abaixo da base de apoio da fundação), movimentação do solo ou indicativo, recalque diferencial causado pelo solo erodido, fissura(s) na edificação que indicam movimentação da fundação, comprometimento de muro(s) de contenção ou outros indicativos que comprometem significativamente a segurança da fundação.",
											valor: 3,
										},
										{
											id: 205,
											dominioOptionId: 5,
											titulo: "Intensidade 4",
											informacoes:
												" Há indicativo de dano severo na fundação devido à inundação ou pré-existente, como indicativo de ruptura, deformação excessiva, recalque, movimentação, perda da base de apoio da fundação ou outros indicativos que causam a perda da segurança da fundação.",
											valor: 4,
										},
										{
											id: 206,
											dominioOptionId: 6,
											titulo: "Intensidade 5",
											informacoes:
												"Há indicativo de dano extremamente severo na fundação devido à inundação ou pré-existente,  como colapso parcial ou total da fundação, que comprometem a integridade da estrutura. Há indicativos de movimentação de encostas e deslizamento de terras.",
											valor: 5,
										},
										{
											id: 207,
											dominioOptionId: 7,
											titulo: "Não avaliada",
											informacoes: "Não foi possível avaliar a fundação.",
											valor: null,
										},
									],
									[
										{ id: 208, dominioOptionId: 9, titulo: "Extensão 0", valor: 0 },
										{ id: 209, dominioOptionId: 10, titulo: "Extensão 1", valor: 1 },
										{ id: 210, dominioOptionId: 11, titulo: "Extensão 2", valor: 2 },
										{ id: 211, dominioOptionId: 12, titulo: "Extensão 3", valor: 3 },
										{ id: 212, dominioOptionId: 13, titulo: "Extensão 4", valor: 4 },
										{ id: 213, dominioOptionId: 14, titulo: "Extensão 5", valor: 5 },
										{ id: 214, dominioOptionId: 15, titulo: "Não avaliada", valor: null },
										{ id: 215, dominioOptionId: 16, titulo: "Não se aplica", valor: null },
									],
								],
								dependencia: [[33, 34], [13]],
							},
						],
					},
					// VIGAS DE CONCRETO ARMADO
					{
						id: 5,
						titulo: "Indicativo de Dano Estrutural: Vigas de Concreto Armado",
						texto: "Indicativos de danos nas vigas podem ser quanto a ação da água, fissura, deformação, desplacamento, incidência de corrosão pré-existente, ruptura localizada, ruptura total, entre outros.",
						cor: "#F24727",
						perguntas: [
							{
								id: 16,
								titulo: "Indicativo de dano nas Vigas de Concreto Armado",
								texto: "Os danos referem-se a pior condição encontrada para todas as Vigas. A seguir, são apresentados exemplos de danos em Vigas para cada nível de intensidade:",
								tipo: PerguntaTipo.parametro,
								parametroId: 3,
								opcoes: [
									[
										{
											id: 300,
											dominioOptionId: 1,
											titulo: "Intensidade 0",
											informacoes: "Não há indicativo de dano na fundação pré-existente ou devido à inundação.",
											valor: 0,
										},
										{
											id: 302,
											dominioOptionId: 2,
											titulo: "Intensidade 1",
											informacoes:
												"Há indicativo de dano leve na fundação devido à inundação ou pré-existente, como erosão do solo e exposição superficial da fundação (pilaretes, blocos de fundação, vigas de baldrame), porém não é visível a base de apoio da fundação (estacas, sapatas, fundação superficial).",
											valor: 1,
										},
										{
											id: 303,
											dominioOptionId: 3,
											titulo: "Intensidade 2",
											informacoes:
												"Há indicativo de dano moderado na fundação devido à inundação ou pré-existente, como erosão e exposição da base de apoio da fundação (estacas, sapatas, fundação superficial, entre outras), que podem reduzir parcialmente a segurança da fundação.",
											valor: 2,
										},
										{
											id: 304,
											dominioOptionId: 4,
											titulo: "Intensidade 3",
											informacoes:
												"Há indicativo de dano grave na fundação devido à inundação ou pré-existente, como erosão profunda (abaixo da base de apoio da fundação), movimentação do solo ou indicativo, recalque diferencial causado pelo solo erodido, fissura(s) na edificação que indicam movimentação da fundação, comprometimento de muro(s) de contenção ou outros indicativos que comprometem significativamente a segurança da fundação.",
											valor: 3,
										},
										{
											id: 305,
											dominioOptionId: 5,
											titulo: "Intensidade 4",
											informacoes:
												" Há indicativo de dano severo na fundação devido à inundação ou pré-existente, como indicativo de ruptura, deformação excessiva, recalque, movimentação, perda da base de apoio da fundação ou outros indicativos que causam a perda da segurança da fundação.",
											valor: 4,
										},
										{
											id: 306,
											dominioOptionId: 6,
											titulo: "Intensidade 5",
											informacoes:
												"Há indicativo de dano extremamente severo na fundação devido à inundação ou pré-existente,  como colapso parcial ou total da fundação, que comprometem a integridade da estrutura. Há indicativos de movimentação de encostas e deslizamento de terras.",
											valor: 5,
										},
										{
											id: 307,
											dominioOptionId: 7,
											titulo: "Não avaliada",
											informacoes: "Não foi possível avaliar a fundação.",
											valor: null,
										},
									],
									[
										{ id: 308, dominioOptionId: 9, titulo: "Extensão 0", valor: 0 },
										{ id: 309, dominioOptionId: 10, titulo: "Extensão 1", valor: 1 },
										{ id: 310, dominioOptionId: 11, titulo: "Extensão 2", valor: 2 },
										{ id: 311, dominioOptionId: 12, titulo: "Extensão 3", valor: 3 },
										{ id: 312, dominioOptionId: 13, titulo: "Extensão 4", valor: 4 },
										{ id: 313, dominioOptionId: 14, titulo: "Extensão 5", valor: 5 },
										{ id: 314, dominioOptionId: 15, titulo: "Não avaliada", valor: null },
										{ id: 315, dominioOptionId: 16, titulo: "Não se aplica", valor: null },
									],
								],
								dependencia: [[33, 34], [13]],
							},
						],
					},
					// PAREDES ESTRUTURAIS
					{
						id: 6,
						titulo: "Indicativo de dano nas Paredes Estruturais - blocos cerâmicos, blocos de concreto, bloco de cíclico calcário, tijolos e assemelhados.",
						texto: "Indicativos de danos nas paredes estruturais podem ser quanto a ruptura parcial ou colapso, sobrecarga, fissura, deformação, desplacamento, entre outros.",
						cor: "#F24727",
						perguntas: [
							{
								id: 17,
								titulo: "Indicativo de dano nas paredes estruturais (blocos, tijolos e assemelhados)",
								texto: "Os indicativos de danos referem-se a pior condição encontrada para Paredes Estruturais. A seguir, são apresentados exemplos de  indicativos de danos cada nível de intensidade:",
								tipo: PerguntaTipo.parametro,
								parametroId: 4,
								opcoes: [
									[
										{
											id: 400,
											dominioOptionId: 1,
											titulo: "Intensidade 0",
											informacoes: "Não há indicativo de dano na fundação pré-existente ou devido à inundação.",
											valor: 0,
										},
										{
											id: 402,
											dominioOptionId: 2,
											titulo: "Intensidade 1",
											informacoes:
												"Há indicativo de dano leve na fundação devido à inundação ou pré-existente, como erosão do solo e exposição superficial da fundação (pilaretes, blocos de fundação, vigas de baldrame), porém não é visível a base de apoio da fundação (estacas, sapatas, fundação superficial).",
											valor: 1,
										},
										{
											id: 403,
											dominioOptionId: 3,
											titulo: "Intensidade 2",
											informacoes:
												"Há indicativo de dano moderado na fundação devido à inundação ou pré-existente, como erosão e exposição da base de apoio da fundação (estacas, sapatas, fundação superficial, entre outras), que podem reduzir parcialmente a segurança da fundação.",
											valor: 2,
										},
										{
											id: 404,
											dominioOptionId: 4,
											titulo: "Intensidade 3",
											informacoes:
												"Há indicativo de dano grave na fundação devido à inundação ou pré-existente, como erosão profunda (abaixo da base de apoio da fundação), movimentação do solo ou indicativo, recalque diferencial causado pelo solo erodido, fissura(s) na edificação que indicam movimentação da fundação, comprometimento de muro(s) de contenção ou outros indicativos que comprometem significativamente a segurança da fundação.",
											valor: 3,
										},
										{
											id: 405,
											dominioOptionId: 5,
											titulo: "Intensidade 4",
											informacoes:
												" Há indicativo de dano severo na fundação devido à inundação ou pré-existente, como indicativo de ruptura, deformação excessiva, recalque, movimentação, perda da base de apoio da fundação ou outros indicativos que causam a perda da segurança da fundação.",
											valor: 4,
										},
										{
											id: 406,
											dominioOptionId: 6,
											titulo: "Intensidade 5",
											informacoes:
												"Há indicativo de dano extremamente severo na fundação devido à inundação ou pré-existente,  como colapso parcial ou total da fundação, que comprometem a integridade da estrutura. Há indicativos de movimentação de encostas e deslizamento de terras.",
											valor: 5,
										},
										{
											id: 407,
											dominioOptionId: 7,
											titulo: "Não avaliada",
											informacoes: "Não foi possível avaliar a fundação.",
											valor: null,
										},
									],
									[
										{ id: 408, dominioOptionId: 9, titulo: "Extensão 0", valor: 0 },
										{ id: 409, dominioOptionId: 10, titulo: "Extensão 1", valor: 1 },
										{ id: 410, dominioOptionId: 11, titulo: "Extensão 2", valor: 2 },
										{ id: 411, dominioOptionId: 12, titulo: "Extensão 3", valor: 3 },
										{ id: 412, dominioOptionId: 13, titulo: "Extensão 4", valor: 4 },
										{ id: 413, dominioOptionId: 14, titulo: "Extensão 5", valor: 5 },
										{ id: 414, dominioOptionId: 15, titulo: "Não avaliada", valor: null },
										{ id: 415, dominioOptionId: 16, titulo: "Não se aplica", valor: null },
									],
								],
								dependencia: [[33, 34], [15]],
							},
						],
					},
					// PAREDES ESTRUTURAIS DE MADEIRA
					{
						id: 7,
						titulo: "Indicativo de dano nas Paredes Estruturais de Madeira",
						texto: "Indicativos de danos nas paredes estruturais de madeira podem ser quanto a ruptura parcial ou colapso, sobrecarga, deformação, desalinhamento, desplacamento, umidade, entre outros.",
						cor: "#F24727",
						perguntas: [
							{
								id: 18,
								titulo: "Indicativo de dano nas Paredes Estruturais de Madeira",
								texto: "Os indicativos de danos referem-se a pior condição encontrada para Paredes Estruturais. A seguir, são apresentados exemplos de  indicativos de danos cada nível de intensidade:",
								tipo: PerguntaTipo.parametro,
								parametroId: 5,
								opcoes: [
									[
										{
											id: 500,
											dominioOptionId: 1,
											titulo: "Intensidade 0",
											informacoes: "Não há indicativo de dano na fundação pré-existente ou devido à inundação.",
											valor: 0,
										},
										{
											id: 502,
											dominioOptionId: 2,
											titulo: "Intensidade 1",
											informacoes:
												"Há indicativo de dano leve na fundação devido à inundação ou pré-existente, como erosão do solo e exposição superficial da fundação (pilaretes, blocos de fundação, vigas de baldrame), porém não é visível a base de apoio da fundação (estacas, sapatas, fundação superficial).",
											valor: 1,
										},
										{
											id: 503,
											dominioOptionId: 3,
											titulo: "Intensidade 2",
											informacoes:
												"Há indicativo de dano moderado na fundação devido à inundação ou pré-existente, como erosão e exposição da base de apoio da fundação (estacas, sapatas, fundação superficial, entre outras), que podem reduzir parcialmente a segurança da fundação.",
											valor: 2,
										},
										{
											id: 504,
											dominioOptionId: 4,
											titulo: "Intensidade 3",
											informacoes:
												"Há indicativo de dano grave na fundação devido à inundação ou pré-existente, como erosão profunda (abaixo da base de apoio da fundação), movimentação do solo ou indicativo, recalque diferencial causado pelo solo erodido, fissura(s) na edificação que indicam movimentação da fundação, comprometimento de muro(s) de contenção ou outros indicativos que comprometem significativamente a segurança da fundação.",
											valor: 3,
										},
										{
											id: 505,
											dominioOptionId: 5,
											titulo: "Intensidade 4",
											informacoes:
												" Há indicativo de dano severo na fundação devido à inundação ou pré-existente, como indicativo de ruptura, deformação excessiva, recalque, movimentação, perda da base de apoio da fundação ou outros indicativos que causam a perda da segurança da fundação.",
											valor: 4,
										},
										{
											id: 506,
											dominioOptionId: 6,
											titulo: "Intensidade 5",
											informacoes:
												"Há indicativo de dano extremamente severo na fundação devido à inundação ou pré-existente,  como colapso parcial ou total da fundação, que comprometem a integridade da estrutura. Há indicativos de movimentação de encostas e deslizamento de terras.",
											valor: 5,
										},
										{
											id: 507,
											dominioOptionId: 7,
											titulo: "Não avaliada",
											informacoes: "Não foi possível avaliar a fundação.",
											valor: null,
										},
									],
									[
										{ id: 508, dominioOptionId: 9, titulo: "Extensão 0", valor: 0 },
										{ id: 509, dominioOptionId: 10, titulo: "Extensão 1", valor: 1 },
										{ id: 510, dominioOptionId: 11, titulo: "Extensão 2", valor: 2 },
										{ id: 511, dominioOptionId: 12, titulo: "Extensão 3", valor: 3 },
										{ id: 512, dominioOptionId: 13, titulo: "Extensão 4", valor: 4 },
										{ id: 513, dominioOptionId: 14, titulo: "Extensão 5", valor: 5 },
										{ id: 514, dominioOptionId: 15, titulo: "Não avaliada", valor: null },
										{ id: 515, dominioOptionId: 16, titulo: "Não se aplica", valor: null },
									],
								],
								dependencia: [[33, 34], [14]],
							},
						],
					},
					// PAREDES ESTRUTURAIS
					{
						id: 8,
						titulo: "Indicativo de dano nas Paredes Estruturais",
						texto: "Indicativos de danos nas paredes estruturais autoportantes de sistemas genéricos (metálicas, parede de concreto maciça ou sanduíche, parede de concreto pré-moldado, painéis de concreto com incorporação de fibras, drywall estrutural e outros sistemas não convencionais) podem ser quanto a ruptura parcial ou colapso, sobrecarga, fissura, deformação, desalinhamento, desplacamento, umidade, entre outros",
						cor: "#F24727",
						perguntas: [
							{
								id: 19,
								titulo: "Indicativo de dano em Paredes Estruturais",
								texto: "Os indicativos de danos referem-se a pior condição encontrada para Paredes Estruturais. A seguir, são apresentados exemplos de  indicativos de danos cada nível de intensidade:",
								tipo: PerguntaTipo.parametro,
								parametroId: 6,
								opcoes: [
									[
										{
											id: 600,
											dominioOptionId: 1,
											titulo: "Intensidade 0",
											informacoes: "Não há indicativo de dano na fundação pré-existente ou devido à inundação.",
											valor: 0,
										},
										{
											id: 602,
											dominioOptionId: 2,
											titulo: "Intensidade 1",
											informacoes:
												"Há indicativo de dano leve na fundação devido à inundação ou pré-existente, como erosão do solo e exposição superficial da fundação (pilaretes, blocos de fundação, vigas de baldrame), porém não é visível a base de apoio da fundação (estacas, sapatas, fundação superficial).",
											valor: 1,
										},
										{
											id: 603,
											dominioOptionId: 3,
											titulo: "Intensidade 2",
											informacoes:
												"Há indicativo de dano moderado na fundação devido à inundação ou pré-existente, como erosão e exposição da base de apoio da fundação (estacas, sapatas, fundação superficial, entre outras), que podem reduzir parcialmente a segurança da fundação.",
											valor: 2,
										},
										{
											id: 604,
											dominioOptionId: 4,
											titulo: "Intensidade 3",
											informacoes:
												"Há indicativo de dano grave na fundação devido à inundação ou pré-existente, como erosão profunda (abaixo da base de apoio da fundação), movimentação do solo ou indicativo, recalque diferencial causado pelo solo erodido, fissura(s) na edificação que indicam movimentação da fundação, comprometimento de muro(s) de contenção ou outros indicativos que comprometem significativamente a segurança da fundação.",
											valor: 3,
										},
										{
											id: 605,
											dominioOptionId: 5,
											titulo: "Intensidade 4",
											informacoes:
												" Há indicativo de dano severo na fundação devido à inundação ou pré-existente, como indicativo de ruptura, deformação excessiva, recalque, movimentação, perda da base de apoio da fundação ou outros indicativos que causam a perda da segurança da fundação.",
											valor: 4,
										},
										{
											id: 606,
											dominioOptionId: 6,
											titulo: "Intensidade 5",
											informacoes:
												"Há indicativo de dano extremamente severo na fundação devido à inundação ou pré-existente,  como colapso parcial ou total da fundação, que comprometem a integridade da estrutura. Há indicativos de movimentação de encostas e deslizamento de terras.",
											valor: 5,
										},
										{
											id: 607,
											dominioOptionId: 7,
											titulo: "Não avaliada",
											informacoes: "Não foi possível avaliar a fundação.",
											valor: null,
										},
									],
									[
										{ id: 608, dominioOptionId: 9, titulo: "Extensão 0", valor: 0 },
										{ id: 609, dominioOptionId: 10, titulo: "Extensão 1", valor: 1 },
										{ id: 610, dominioOptionId: 11, titulo: "Extensão 2", valor: 2 },
										{ id: 611, dominioOptionId: 12, titulo: "Extensão 3", valor: 3 },
										{ id: 612, dominioOptionId: 13, titulo: "Extensão 4", valor: 4 },
										{ id: 613, dominioOptionId: 14, titulo: "Extensão 5", valor: 5 },
										{ id: 614, dominioOptionId: 15, titulo: "Não avaliada", valor: null },
										{ id: 615, dominioOptionId: 16, titulo: "Não se aplica", valor: null },
									],
								],
								dependencia: [
									[33, 34],
									[16, 17, 18],
								],
							},
						],
					},
					// LAJE DE CONCRETO ARMADO
					{
						id: 9,
						titulo: "Indicativo de dano Estrutural na Laje de Concreto Armado",
						texto: "Indicativo de dano na laje de concreto armado – laje maciça, laje treliçada, laje pré-moldada, piso de concreto armado, entre outros. Indicativos de danos na laje e no piso de concreto podem ser quanto a ação da água, incidência de corrosão pré-existente, fissura, deformação, desplacamento, ruptura localizada, ruptura total, entre outros",
						cor: "#F24727",
						perguntas: [
							{
								id: 20,
								titulo: "Indicativo de dano na Laje de Concreto Armado",
								texto: "Inclui-se nessa avaliação: Lajes Maciças, Lajes Treliçadas, Lajes pré-moldadas, Pisos de Concreto, entre outros. Os danos referem-se a pior condição encontrada para todas as Lajes. A seguir, são apresentados exemplos de danos em lajes para cada nível de intensidade:",
								tipo: PerguntaTipo.parametro,
								parametroId: 7,
								opcoes: [
									[
										{
											id: 700,
											dominioOptionId: 1,
											titulo: "Intensidade 0",
											informacoes: "Não há indicativo de dano na fundação pré-existente ou devido à inundação.",
											valor: 0,
										},
										{
											id: 702,
											dominioOptionId: 2,
											titulo: "Intensidade 1",
											informacoes:
												"Há indicativo de dano leve na fundação devido à inundação ou pré-existente, como erosão do solo e exposição superficial da fundação (pilaretes, blocos de fundação, vigas de baldrame), porém não é visível a base de apoio da fundação (estacas, sapatas, fundação superficial).",
											valor: 1,
										},
										{
											id: 703,
											dominioOptionId: 3,
											titulo: "Intensidade 2",
											informacoes:
												"Há indicativo de dano moderado na fundação devido à inundação ou pré-existente, como erosão e exposição da base de apoio da fundação (estacas, sapatas, fundação superficial, entre outras), que podem reduzir parcialmente a segurança da fundação.",
											valor: 2,
										},
										{
											id: 704,
											dominioOptionId: 4,
											titulo: "Intensidade 3",
											informacoes:
												"Há indicativo de dano grave na fundação devido à inundação ou pré-existente, como erosão profunda (abaixo da base de apoio da fundação), movimentação do solo ou indicativo, recalque diferencial causado pelo solo erodido, fissura(s) na edificação que indicam movimentação da fundação, comprometimento de muro(s) de contenção ou outros indicativos que comprometem significativamente a segurança da fundação.",
											valor: 3,
										},
										{
											id: 705,
											dominioOptionId: 5,
											titulo: "Intensidade 4",
											informacoes:
												" Há indicativo de dano severo na fundação devido à inundação ou pré-existente, como indicativo de ruptura, deformação excessiva, recalque, movimentação, perda da base de apoio da fundação ou outros indicativos que causam a perda da segurança da fundação.",
											valor: 4,
										},
										{
											id: 706,
											dominioOptionId: 6,
											titulo: "Intensidade 5",
											informacoes:
												"Há indicativo de dano extremamente severo na fundação devido à inundação ou pré-existente,  como colapso parcial ou total da fundação, que comprometem a integridade da estrutura. Há indicativos de movimentação de encostas e deslizamento de terras.",
											valor: 5,
										},
										{
											id: 707,
											dominioOptionId: 7,
											titulo: "Não avaliada",
											informacoes: "Não foi possível avaliar a fundação.",
											valor: null,
										},
									],
									[
										{ id: 708, dominioOptionId: 9, titulo: "Extensão 0", valor: 0 },
										{ id: 709, dominioOptionId: 10, titulo: "Extensão 1", valor: 1 },
										{ id: 710, dominioOptionId: 11, titulo: "Extensão 2", valor: 2 },
										{ id: 711, dominioOptionId: 12, titulo: "Extensão 3", valor: 3 },
										{ id: 712, dominioOptionId: 13, titulo: "Extensão 4", valor: 4 },
										{ id: 713, dominioOptionId: 14, titulo: "Extensão 5", valor: 5 },
										{ id: 714, dominioOptionId: 15, titulo: "Não avaliada", valor: null },
										{ id: 715, dominioOptionId: 16, titulo: "Não se aplica", valor: null },
									],
								],
								dependencia: [[33, 34], [19]],
							},
						],
					},
					// LAJE DE MADEIRA
					{
						id: 10,
						titulo: "Indicativo de dano Estrutural na Laje de Madeira: laje estrutural de madeira com piso de madeira, entre outros assemelhados",
						texto: "Indicativos de danos na laje e no piso de madeira podem ser quanto a ação da água, deformação, ruptura localizada, ruptura total, entre outros.",
						cor: "#F24727",
						perguntas: [
							{
								id: 21,
								titulo: "Indicativo de dano na Laje de Madeira",
								texto: "Os danos referem-se a pior condição encontrada para todas as Lajes. A seguir, são apresentados exemplos de danos em lajes para cada nível de intensidade:",
								tipo: PerguntaTipo.parametro,
								parametroId: 8,
								opcoes: [
									[
										{
											id: 800,
											dominioOptionId: 1,
											titulo: "Intensidade 0",
											informacoes: "Não há indicativo de dano na fundação pré-existente ou devido à inundação.",
											valor: 0,
										},
										{
											id: 802,
											dominioOptionId: 2,
											titulo: "Intensidade 1",
											informacoes:
												"Há indicativo de dano leve na fundação devido à inundação ou pré-existente, como erosão do solo e exposição superficial da fundação (pilaretes, blocos de fundação, vigas de baldrame), porém não é visível a base de apoio da fundação (estacas, sapatas, fundação superficial).",
											valor: 1,
										},
										{
											id: 803,
											dominioOptionId: 3,
											titulo: "Intensidade 2",
											informacoes:
												"Há indicativo de dano moderado na fundação devido à inundação ou pré-existente, como erosão e exposição da base de apoio da fundação (estacas, sapatas, fundação superficial, entre outras), que podem reduzir parcialmente a segurança da fundação.",
											valor: 2,
										},
										{
											id: 804,
											dominioOptionId: 4,
											titulo: "Intensidade 3",
											informacoes:
												"Há indicativo de dano grave na fundação devido à inundação ou pré-existente, como erosão profunda (abaixo da base de apoio da fundação), movimentação do solo ou indicativo, recalque diferencial causado pelo solo erodido, fissura(s) na edificação que indicam movimentação da fundação, comprometimento de muro(s) de contenção ou outros indicativos que comprometem significativamente a segurança da fundação.",
											valor: 3,
										},
										{
											id: 805,
											dominioOptionId: 5,
											titulo: "Intensidade 4",
											informacoes:
												" Há indicativo de dano severo na fundação devido à inundação ou pré-existente, como indicativo de ruptura, deformação excessiva, recalque, movimentação, perda da base de apoio da fundação ou outros indicativos que causam a perda da segurança da fundação.",
											valor: 4,
										},
										{
											id: 806,
											dominioOptionId: 6,
											titulo: "Intensidade 5",
											informacoes:
												"Há indicativo de dano extremamente severo na fundação devido à inundação ou pré-existente,  como colapso parcial ou total da fundação, que comprometem a integridade da estrutura. Há indicativos de movimentação de encostas e deslizamento de terras.",
											valor: 5,
										},
										{
											id: 807,
											dominioOptionId: 7,
											titulo: "Não avaliada",
											informacoes: "Não foi possível avaliar a fundação.",
											valor: null,
										},
									],
									[
										{ id: 808, dominioOptionId: 9, titulo: "Extensão 0", valor: 0 },
										{ id: 809, dominioOptionId: 10, titulo: "Extensão 1", valor: 1 },
										{ id: 810, dominioOptionId: 11, titulo: "Extensão 2", valor: 2 },
										{ id: 811, dominioOptionId: 12, titulo: "Extensão 3", valor: 3 },
										{ id: 812, dominioOptionId: 13, titulo: "Extensão 4", valor: 4 },
										{ id: 813, dominioOptionId: 14, titulo: "Extensão 5", valor: 5 },
										{ id: 814, dominioOptionId: 15, titulo: "Não avaliada", valor: null },
										{ id: 815, dominioOptionId: 16, titulo: "Não se aplica", valor: null },
									],
								],
								dependencia: [[33, 34], [20]],
							},
						],
					},
					// LAJE METÁLICA
					{
						id: 11,
						titulo: "Indicativo de dano Estrutural na Laje Metálica",
						texto: "Indicativo de dano na laje metálica. Indicativos de danos na laje e no piso de concreto podem ser quanto a ação da água, incidência de corrosão pré-existente, fissura, deformação, desplacamento, ruptura localizada, ruptura total, entre outros",
						cor: "#F24727",
						perguntas: [
							{
								id: 22,
								titulo: "Indicativo de dano na Laje Metálica",
								texto: "Os danos referem-se a pior condição encontrada para todas as Lajes. A seguir, são apresentados exemplos de danos em lajes para cada nível de intensidade:",
								tipo: PerguntaTipo.parametro,
								parametroId: 9,
								opcoes: [
									[
										{
											id: 900,
											dominioOptionId: 1,
											titulo: "Intensidade 0",
											informacoes: "Não há indicativo de dano na fundação pré-existente ou devido à inundação.",
											valor: 0,
										},
										{
											id: 902,
											dominioOptionId: 2,
											titulo: "Intensidade 1",
											informacoes:
												"Há indicativo de dano leve na fundação devido à inundação ou pré-existente, como erosão do solo e exposição superficial da fundação (pilaretes, blocos de fundação, vigas de baldrame), porém não é visível a base de apoio da fundação (estacas, sapatas, fundação superficial).",
											valor: 1,
										},
										{
											id: 903,
											dominioOptionId: 3,
											titulo: "Intensidade 2",
											informacoes:
												"Há indicativo de dano moderado na fundação devido à inundação ou pré-existente, como erosão e exposição da base de apoio da fundação (estacas, sapatas, fundação superficial, entre outras), que podem reduzir parcialmente a segurança da fundação.",
											valor: 2,
										},
										{
											id: 904,
											dominioOptionId: 4,
											titulo: "Intensidade 3",
											informacoes:
												"Há indicativo de dano grave na fundação devido à inundação ou pré-existente, como erosão profunda (abaixo da base de apoio da fundação), movimentação do solo ou indicativo, recalque diferencial causado pelo solo erodido, fissura(s) na edificação que indicam movimentação da fundação, comprometimento de muro(s) de contenção ou outros indicativos que comprometem significativamente a segurança da fundação.",
											valor: 3,
										},
										{
											id: 905,
											dominioOptionId: 5,
											titulo: "Intensidade 4",
											informacoes:
												" Há indicativo de dano severo na fundação devido à inundação ou pré-existente, como indicativo de ruptura, deformação excessiva, recalque, movimentação, perda da base de apoio da fundação ou outros indicativos que causam a perda da segurança da fundação.",
											valor: 4,
										},
										{
											id: 906,
											dominioOptionId: 6,
											titulo: "Intensidade 5",
											informacoes:
												"Há indicativo de dano extremamente severo na fundação devido à inundação ou pré-existente,  como colapso parcial ou total da fundação, que comprometem a integridade da estrutura. Há indicativos de movimentação de encostas e deslizamento de terras.",
											valor: 5,
										},
										{
											id: 907,
											dominioOptionId: 7,
											titulo: "Não avaliada",
											informacoes: "Não foi possível avaliar a fundação.",
											valor: null,
										},
									],
									[
										{ id: 908, dominioOptionId: 9, titulo: "Extensão 0", valor: 0 },
										{ id: 909, dominioOptionId: 10, titulo: "Extensão 1", valor: 1 },
										{ id: 910, dominioOptionId: 11, titulo: "Extensão 2", valor: 2 },
										{ id: 911, dominioOptionId: 12, titulo: "Extensão 3", valor: 3 },
										{ id: 912, dominioOptionId: 13, titulo: "Extensão 4", valor: 4 },
										{ id: 913, dominioOptionId: 14, titulo: "Extensão 5", valor: 5 },
										{ id: 914, dominioOptionId: 15, titulo: "Não avaliada", valor: null },
										{ id: 915, dominioOptionId: 16, titulo: "Não se aplica", valor: null },
									],
								],
								dependencia: [[33, 34], [21]],
							},
						],
					},
					// COBERTURA/TELHADO
					{
						id: 12,
						titulo: "Indicativo de dano Estrutural Cobertura/telhado da Edificação de Madeira",
						texto: "Indicativos de danos na cobertura / telhado da edificação de madeira podem ser quanto a ruptura parcial ou colapso, sobrecarga, deformação, desalinhamento, umidade, entre outros.",
						cor: "#F24727",
						perguntas: [
							{
								id: 23,
								titulo: "Indicativo de dano na cobertura/telhado da Edificação de Madeira",
								texto: "Os indicativos de danos referem-se a pior condição encontrada para a Cobertura/Telhado. A seguir, são apresentados exemplos de  indicativos de danos cada nível de intensidade:",
								tipo: PerguntaTipo.parametro,
								parametroId: 10,
								opcoes: [
									[
										{
											id: 1000,
											dominioOptionId: 1,
											titulo: "Intensidade 0",
											informacoes: "Não há indicativo de dano na fundação pré-existente ou devido à inundação.",
											valor: 0,
										},
										{
											id: 1002,
											dominioOptionId: 2,
											titulo: "Intensidade 1",
											informacoes:
												"Há indicativo de dano leve na fundação devido à inundação ou pré-existente, como erosão do solo e exposição superficial da fundação (pilaretes, blocos de fundação, vigas de baldrame), porém não é visível a base de apoio da fundação (estacas, sapatas, fundação superficial).",
											valor: 1,
										},
										{
											id: 1003,
											dominioOptionId: 3,
											titulo: "Intensidade 2",
											informacoes:
												"Há indicativo de dano moderado na fundação devido à inundação ou pré-existente, como erosão e exposição da base de apoio da fundação (estacas, sapatas, fundação superficial, entre outras), que podem reduzir parcialmente a segurança da fundação.",
											valor: 2,
										},
										{
											id: 1004,
											dominioOptionId: 4,
											titulo: "Intensidade 3",
											informacoes:
												"Há indicativo de dano grave na fundação devido à inundação ou pré-existente, como erosão profunda (abaixo da base de apoio da fundação), movimentação do solo ou indicativo, recalque diferencial causado pelo solo erodido, fissura(s) na edificação que indicam movimentação da fundação, comprometimento de muro(s) de contenção ou outros indicativos que comprometem significativamente a segurança da fundação.",
											valor: 3,
										},
										{
											id: 1005,
											dominioOptionId: 5,
											titulo: "Intensidade 4",
											informacoes:
												" Há indicativo de dano severo na fundação devido à inundação ou pré-existente, como indicativo de ruptura, deformação excessiva, recalque, movimentação, perda da base de apoio da fundação ou outros indicativos que causam a perda da segurança da fundação.",
											valor: 4,
										},
										{
											id: 1006,
											dominioOptionId: 6,
											titulo: "Intensidade 5",
											informacoes:
												"Há indicativo de dano extremamente severo na fundação devido à inundação ou pré-existente,  como colapso parcial ou total da fundação, que comprometem a integridade da estrutura. Há indicativos de movimentação de encostas e deslizamento de terras.",
											valor: 5,
										},
										{
											id: 1007,
											dominioOptionId: 7,
											titulo: "Não avaliada",
											informacoes: "Não foi possível avaliar a fundação.",
											valor: null,
										},
									],
									[
										{ id: 1008, dominioOptionId: 9, titulo: "Extensão 0", valor: 0 },
										{ id: 1009, dominioOptionId: 10, titulo: "Extensão 1", valor: 1 },
										{ id: 1010, dominioOptionId: 11, titulo: "Extensão 2", valor: 2 },
										{ id: 1011, dominioOptionId: 12, titulo: "Extensão 3", valor: 3 },
										{ id: 1012, dominioOptionId: 13, titulo: "Extensão 4", valor: 4 },
										{ id: 1013, dominioOptionId: 14, titulo: "Extensão 5", valor: 5 },
										{ id: 1014, dominioOptionId: 15, titulo: "Não avaliada", valor: null },
										{ id: 1015, dominioOptionId: 16, titulo: "Não se aplica", valor: null },
									],
								],
								dependencia: [[33, 34], [14]],
							},
						],
					},
					// FUNCIONAIS EM SISTEMAS DE REVESTIMENTO DE PISO, ASSOALHO E ASSEMELHADOS
					{
						id: 13,
						titulo: "Indicativos de Danos Funcionais em Sistemas de Revestimento de piso, Assoalho e Assemelhados",
						texto: "Para fins de aplicação da ferramenta, são considerados sistema de revestimento de piso qualquer tipo (concreto, madeira, cerâmico, por exemplo). O indicativo de dano funcional deve refletir o estado geral do parâmetro avaliado em toda a edificação, e não apenas os níveis críticos.",
						cor: "#2D9BF0",
						perguntas: [
							{
								id: 24,
								titulo: "Indicativo de dano Funcional para Revestimento de Piso",
								texto: "Os indicativos de danos funcionais devem refletir o estado geral do parâmetro avaliado em toda a edificação, e não apenas os níveis críticos. A seguir, são apresentados exemplos de  indicativos de danos cada nível de intensidade:",
								tipo: PerguntaTipo.parametro,
								parametroId: 11,
								opcoes: [
									[
										{
											id: 1100,
											dominioOptionId: 1,
											titulo: "Intensidade 0",
											informacoes: "Não há indicativo de dano na fundação pré-existente ou devido à inundação.",
											valor: 0,
										},
										{
											id: 1102,
											dominioOptionId: 2,
											titulo: "Intensidade 1",
											informacoes:
												"Há indicativo de dano leve na fundação devido à inundação ou pré-existente, como erosão do solo e exposição superficial da fundação (pilaretes, blocos de fundação, vigas de baldrame), porém não é visível a base de apoio da fundação (estacas, sapatas, fundação superficial).",
											valor: 1,
										},
										{
											id: 1103,
											dominioOptionId: 3,
											titulo: "Intensidade 2",
											informacoes:
												"Há indicativo de dano moderado na fundação devido à inundação ou pré-existente, como erosão e exposição da base de apoio da fundação (estacas, sapatas, fundação superficial, entre outras), que podem reduzir parcialmente a segurança da fundação.",
											valor: 2,
										},
										{
											id: 1104,
											dominioOptionId: 4,
											titulo: "Intensidade 3",
											informacoes:
												"Há indicativo de dano grave na fundação devido à inundação ou pré-existente, como erosão profunda (abaixo da base de apoio da fundação), movimentação do solo ou indicativo, recalque diferencial causado pelo solo erodido, fissura(s) na edificação que indicam movimentação da fundação, comprometimento de muro(s) de contenção ou outros indicativos que comprometem significativamente a segurança da fundação.",
											valor: 3,
										},
										{
											id: 1105,
											dominioOptionId: 5,
											titulo: "Intensidade 4",
											informacoes:
												" Há indicativo de dano severo na fundação devido à inundação ou pré-existente, como indicativo de ruptura, deformação excessiva, recalque, movimentação, perda da base de apoio da fundação ou outros indicativos que causam a perda da segurança da fundação.",
											valor: 4,
										},
										{
											id: 1106,
											dominioOptionId: 6,
											titulo: "Intensidade 5",
											informacoes:
												"Há indicativo de dano extremamente severo na fundação devido à inundação ou pré-existente,  como colapso parcial ou total da fundação, que comprometem a integridade da estrutura. Há indicativos de movimentação de encostas e deslizamento de terras.",
											valor: 5,
										},
										{
											id: 1107,
											dominioOptionId: 7,
											titulo: "Não avaliada",
											informacoes: "Não foi possível avaliar a fundação.",
											valor: null,
										},
									],
									[
										{ id: 1108, dominioOptionId: 9, titulo: "Extensão 0", valor: 0 },
										{ id: 1109, dominioOptionId: 10, titulo: "Extensão 1", valor: 1 },
										{ id: 1110, dominioOptionId: 11, titulo: "Extensão 2", valor: 2 },
										{ id: 1111, dominioOptionId: 12, titulo: "Extensão 3", valor: 3 },
										{ id: 1112, dominioOptionId: 13, titulo: "Extensão 4", valor: 4 },
										{ id: 1113, dominioOptionId: 14, titulo: "Extensão 5", valor: 5 },
										{ id: 1114, dominioOptionId: 15, titulo: "Não avaliada", valor: null },
										{ id: 1115, dominioOptionId: 16, titulo: "Não se aplica", valor: null },
									],
								],
								dependencia: [[33, 34]],
							},
						],
					},
					// COMPLEMENTAR
					{
						id: 100,
						titulo: "Dados Complementares",
						texto: "Forneça dados complementares",
						cor: "#000000",
						perguntas: [
							{
								id: 100,
								titulo: "Observações e Comentários Complementares",
								texto: "Neste campo, podem ser indicadas observações, insights ou qualquer informação adicional relevante sobre a edificação. Lembre-se de manter as informações objetivas e relacionadas à avaliação pós-inundação.",
								tipo: PerguntaTipo.textarea,
							},
							{
								id: 101,
								titulo: "Indicador de Manutenção na Edificação",
								texto: "Este indicador avalia a presença e a eficácia das práticas de manutenção preventiva e corretiva na edificação. A escala vai de 0 a 5, onde: 0 - indica uma incidência de boas práticas de manutenção preventiva e corretiva, com reparos e correções. 5 -  indica que não há indícios de manutenção preventiva ou corretiva. A seguir, são apresentados exemplos de  indicativos de danos cada nível de intensidade:",
								tipo: PerguntaTipo.radio,
								opcoes: [
									[
										{
											id: 100000,
											titulo: "Intensidade 0 : Há uma incidência de boas práticas de manutenção preventiva e corretiva na edificação. A manutenção é realizada de maneira sistemática e regular, abrangendo todos os sistemas da edificação com reparos e correções constantes. Exemplos incluem manutenção programada e realizada em todos os sistemas (pintura, pisos, cobertura, sistemas elétricos e hidráulicos), garantindo a conservação e funcionalidade da edificação.",
											valor: 1,
										},
										{
											id: 100001,
											titulo: "Intensidade 1 : Há indícios significativos de manutenção preventiva ou corretiva na edificação. A manutenção é realizada de forma abrangente e regular, cobrindo a maioria dos sistemas da edificação. Exemplos incluem inspeções regulares e reparos nos sistemas de cobertura, pisos, pintura, sistemas elétricos e hidráulicos.",
											valor: 2,
										},
										{
											id: 100002,
											titulo: "Intensidade 2 : Há indícios moderados de manutenção preventiva ou corretiva na edificação. A manutenção é realizada de maneira mais regular, abrangendo vários sistemas, mas ainda não é totalmente abrangente ou sistemática. Exemplos incluem reparos regulares de pintura, pisos, e verificação periódica de sistemas elétricos e hidráulicos.",
											valor: 3,
										},
										{
											id: 100003,
											titulo: "Intensidade 3 : Há indícios de manutenção preventiva ou corretiva na edificação, mas com cobertura limitada. Foram realizadas algumas ações de manutenção em certos sistemas, mas não de maneira abrangente ou regular. Exemplos incluem manutenção parcial de sistemas elétricos ou hidráulicos, reparos ocasionais em pisos ou pintura.",
											valor: 4,
										},
										{
											id: 100004,
											titulo: "Intensidade 4 : Há poucos indícios de manutenção preventiva ou corretiva na edificação. Foram identificadas algumas ações esporádicas de manutenção, mas de forma irregular e insuficiente para garantir a boa conservação da edificação. Exemplos incluem reparos isolados e superficiais.",
											valor: 5,
										},
										{
											id: 100005,
											titulo: "Intensidade 5 : Não há indícios de manutenção preventiva ou corretiva na edificação. Não foram identificadas ações regulares de manutenção ou reparos. Exemplos incluem pintura descascada, pisos danificados, telhado com infiltrações, sistemas elétricos e hidráulicos sem manutenção.",
											valor: 6,
										},
										{
											id: 100006,
											titulo: "Não Avaliada : Não foi possível avaliar a efetividade das manutenções",
											valor: 7,
										},
									],
								],
							},
						],
					},
				],
			},
		},
	},
	"/api/v1/Dominios": {
		data: [
			{
				id: 1,
				titulo: "Intensidade",
				opcoes: [
					{ id: 1, titulo: "Intensidade 0", valor: 0 },
					{ id: 2, titulo: "Intensidade 1", valor: 1 },
					{ id: 3, titulo: "Intensidade 2", valor: 2 },
					{ id: 4, titulo: "Intensidade 3", valor: 3 },
					{ id: 5, titulo: "Intensidade 4", valor: 4 },
					{ id: 6, titulo: "Intensidade 5", valor: 5 },
					{ id: 7, titulo: "Não avaliada", valor: null },
					{ id: 8, titulo: "Não se aplica", valor: null },
				],
			},
			{
				id: 2,
				titulo: "Extensão",
				opcoes: [
					{ id: 9, titulo: "Extensão 0", valor: 0 },
					{ id: 10, titulo: "Extensão 1", valor: 1 },
					{ id: 11, titulo: "Extensão 2", valor: 2 },
					{ id: 12, titulo: "Extensão 3", valor: 3 },
					{ id: 13, titulo: "Extensão 4", valor: 4 },
					{ id: 14, titulo: "Extensão 5", valor: 5 },
					{ id: 15, titulo: "Não avaliada", valor: null },
					{ id: 16, titulo: "Não se aplica", valor: null },
				],
			},
			{
				id: 3,
				titulo: "Inundação",
				opcoes: [
					{ id: 17, titulo: "Nível 0", valor: 0 },
					{ id: 18, titulo: "Nível 1", valor: 1 },
					{ id: 19, titulo: "Nível 2", valor: 2 },
					{ id: 20, titulo: "Nível 3", valor: 3 },
					{ id: 21, titulo: "Nível 4", valor: 4 },
					{ id: 22, titulo: "Nível 5", valor: 5 },
				],
			},
		],
	},
	"/api/v1/Dominio/1": {
		data: {
			id: 1,
			titulo: "Intensidade",
			opcoes: [
				{ id: 1, titulo: "Intensidade 0", valor: 0 },
				{ id: 2, titulo: "Intensidade 1", valor: 1 },
				{ id: 3, titulo: "Intensidade 2", valor: 2 },
				{ id: 4, titulo: "Intensidade 3", valor: 3 },
				{ id: 5, titulo: "Intensidade 4", valor: 4 },
				{ id: 6, titulo: "Intensidade 5", valor: 5 },
				{ id: 7, titulo: "Não avaliada", valor: null },
				{ id: 8, titulo: "Não se aplica", valor: null },
			],
		},
	},
	"/api/v1/Dominio": {
		data: {
			id: 1,
			titulo: "Intensidade",
			opcoes: [
				{ id: 1, titulo: "Intensidade 0", valor: 0 },
				{ id: 2, titulo: "Intensidade 1", valor: 1 },
				{ id: 3, titulo: "Intensidade 2", valor: 2 },
				{ id: 4, titulo: "Intensidade 3", valor: 3 },
				{ id: 5, titulo: "Intensidade 4", valor: 4 },
				{ id: 6, titulo: "Intensidade 5", valor: 5 },
				{ id: 7, titulo: "Não avaliada", valor: null },
				{ id: 8, titulo: "Não se aplica", valor: null },
			],
		},
	},
	"/api/v1/Parametros": {
		status: 200,
		data: [
			{
				id: 1, // parametroID
				titulo: "Fundação",
				importanciaTecnica: 3.5,
				importanciaFuncional: null,
				valorMaximo: 0.585662019,
				opcoesGroups: [
					[
						{
							id: 1, // parametroOptionID
							dominioOptionId: 1,
							titulo: "Intensidade 0",
							informacoes: "Não há indicativo de dano na fundação pré-existente ou devido à inundação.",
							valor: 0,
						},
						{
							id: 2,
							dominioOptionId: 2,
							titulo: "Intensidade 1",
							informacoes:
								"Há indicativo de dano leve na fundação devido à inundação ou pré-existente, como erosão do solo e exposição superficial da fundação (pilaretes, blocos de fundação, vigas de baldrame), porém não é visível a base de apoio da fundação (estacas, sapatas, fundação superficial).",
							valor: 1,
						},
						{
							id: 3,
							dominioOptionId: 3,
							titulo: "Intensidade 2",
							informacoes:
								"Há indicativo de dano moderado na fundação devido à inundação ou pré-existente, como erosão e exposição da base de apoio da fundação (estacas, sapatas, fundação superficial, entre outras), que podem reduzir parcialmente a segurança da fundação.",
							valor: 2,
						},
						{
							id: 4,
							dominioOptionId: 4,
							titulo: "Intensidade 3",
							informacoes:
								"Há indicativo de dano grave na fundação devido à inundação ou pré-existente, como erosão profunda (abaixo da base de apoio da fundação), movimentação do solo ou indicativo, recalque diferencial causado pelo solo erodido, fissura(s) na edificação que indicam movimentação da fundação, comprometimento de muro(s) de contenção ou outros indicativos que comprometem significativamente a segurança da fundação.",
							valor: 3,
						},
						{
							id: 5,
							dominioOptionId: 5,
							titulo: "Intensidade 4",
							informacoes:
								"Há indicativo de dano severo na fundação devido à inundação ou pré-existente, como indicativo de ruptura, deformação excessiva, recalque, movimentação, perda da base de apoio da fundação ou outros indicativos que causam a perda da segurança da fundação.",
							valor: 4,
						},
						{
							id: 6,
							dominioOptionId: 6,
							titulo: "Intensidade 5",
							informacoes:
								"Há indicativo de dano extremamente severo na fundação devido à inundação ou pré-existente,  como colapso parcial ou total da fundação, que comprometem a integridade da estrutura. Há indicativos de movimentação de encostas e deslizamento de terras.",
							valor: 5,
						},
						{
							id: 7,
							dominioOptionId: 7,
							titulo: "Não avaliada",
							informacoes: "Não foi possível avaliar a fundação.",
							valor: null,
						},
					],
					[
						{ id: 8, dominioOptionId: 9, titulo: "Extensão 0", valor: 0 },
						{ id: 9, dominioOptionId: 10, titulo: "Extensão 1", valor: 1 },
						{ id: 10, dominioOptionId: 11, titulo: "Extensão 2", valor: 2 },
						{ id: 11, dominioOptionId: 12, titulo: "Extensão 3", valor: 3 },
						{ id: 12, dominioOptionId: 13, titulo: "Extensão 4", valor: 4 },
						{ id: 13, dominioOptionId: 14, titulo: "Extensão 5", valor: 5 },
						{ id: 14, dominioOptionId: 15, titulo: "Não avaliada", valor: null },
					],
				],
			},
			{
				id: 2,
				titulo: "Pilar",
				importanciaTecnica: 5,
				importanciaFuncional: null,
				valorMaximo: 1,
				opcoes: [
					[
						{
							id: 1,
							dominioOptionId: 1,
							titulo: "Intensidade 0",
							informacoes: "Não há indicativo de dano na fundação pré-existente ou devido à inundação.",
							valor: 0,
						},
						{
							id: 2,
							dominioOptionId: 2,
							titulo: "Intensidade 1",
							informacoes:
								"Há indicativo de dano leve na fundação devido à inundação ou pré-existente, como erosão do solo e exposição superficial da fundação (pilaretes, blocos de fundação, vigas de baldrame), porém não é visível a base de apoio da fundação (estacas, sapatas, fundação superficial).",
							valor: 1,
						},
						{
							id: 3,
							dominioOptionId: 3,
							titulo: "Intensidade 2",
							informacoes:
								"Há indicativo de dano moderado na fundação devido à inundação ou pré-existente, como erosão e exposição da base de apoio da fundação (estacas, sapatas, fundação superficial, entre outras), que podem reduzir parcialmente a segurança da fundação.",
							valor: 2,
						},
						{
							id: 4,
							dominioOptionId: 4,
							titulo: "Intensidade 3",
							informacoes:
								"Há indicativo de dano grave na fundação devido à inundação ou pré-existente, como erosão profunda (abaixo da base de apoio da fundação), movimentação do solo ou indicativo, recalque diferencial causado pelo solo erodido, fissura(s) na edificação que indicam movimentação da fundação, comprometimento de muro(s) de contenção ou outros indicativos que comprometem significativamente a segurança da fundação.",
							valor: 3,
						},
						{
							id: 5,
							dominioOptionId: 5,
							titulo: "Intensidade 4",
							informacoes:
								" Há indicativo de dano severo na fundação devido à inundação ou pré-existente, como indicativo de ruptura, deformação excessiva, recalque, movimentação, perda da base de apoio da fundação ou outros indicativos que causam a perda da segurança da fundação.",
							valor: 4,
						},
						{
							id: 6,
							dominioOptionId: 6,
							titulo: "Intensidade 5",
							informacoes:
								"Há indicativo de dano extremamente severo na fundação devido à inundação ou pré-existente,  como colapso parcial ou total da fundação, que comprometem a integridade da estrutura. Há indicativos de movimentação de encostas e deslizamento de terras.",
							valor: 5,
						},
						{
							id: 7,
							dominioOptionId: 7,
							titulo: "Não avaliada",
							informacoes: "Não foi possível avaliar a fundação.",
							valor: null,
						},
					],
					[
						{ id: 8, dominioOptionId: 9, titulo: "Extensão 0", valor: 0 },
						{ id: 9, dominioOptionId: 10, titulo: "Extensão 1", valor: 1 },
						{ id: 10, dominioOptionId: 11, titulo: "Extensão 2", valor: 2 },
						{ id: 11, dominioOptionId: 12, titulo: "Extensão 3", valor: 3 },
						{ id: 12, dominioOptionId: 13, titulo: "Extensão 4", valor: 4 },
						{ id: 13, dominioOptionId: 14, titulo: "Extensão 5", valor: 5 },
						{ id: 14, dominioOptionId: 15, titulo: "Não avaliada", valor: null },
					],
				],
			},
			{
				id: 3,
				titulo: "Vigas",
				importanciaTecnica: 2.5,
				importanciaFuncional: null,
				valorMaximo: 0.353553391,
				opcoes: [
					[
						{
							id: 1,
							dominioOptionId: 1,
							titulo: "Intensidade 0",
							informacoes: "Não há indicativo de dano na fundação pré-existente ou devido à inundação.",
							valor: 0,
						},
						{
							id: 2,
							dominioOptionId: 2,
							titulo: "Intensidade 1",
							informacoes:
								"Há indicativo de dano leve na fundação devido à inundação ou pré-existente, como erosão do solo e exposição superficial da fundação (pilaretes, blocos de fundação, vigas de baldrame), porém não é visível a base de apoio da fundação (estacas, sapatas, fundação superficial).",
							valor: 1,
						},
						{
							id: 3,
							dominioOptionId: 3,
							titulo: "Intensidade 2",
							informacoes:
								"Há indicativo de dano moderado na fundação devido à inundação ou pré-existente, como erosão e exposição da base de apoio da fundação (estacas, sapatas, fundação superficial, entre outras), que podem reduzir parcialmente a segurança da fundação.",
							valor: 2,
						},
						{
							id: 4,
							dominioOptionId: 4,
							titulo: "Intensidade 3",
							informacoes:
								"Há indicativo de dano grave na fundação devido à inundação ou pré-existente, como erosão profunda (abaixo da base de apoio da fundação), movimentação do solo ou indicativo, recalque diferencial causado pelo solo erodido, fissura(s) na edificação que indicam movimentação da fundação, comprometimento de muro(s) de contenção ou outros indicativos que comprometem significativamente a segurança da fundação.",
							valor: 3,
						},
						{
							id: 5,
							dominioOptionId: 5,
							titulo: "Intensidade 4",
							informacoes:
								" Há indicativo de dano severo na fundação devido à inundação ou pré-existente, como indicativo de ruptura, deformação excessiva, recalque, movimentação, perda da base de apoio da fundação ou outros indicativos que causam a perda da segurança da fundação.",
							valor: 4,
						},
						{
							id: 6,
							dominioOptionId: 6,
							titulo: "Intensidade 5",
							informacoes:
								"Há indicativo de dano extremamente severo na fundação devido à inundação ou pré-existente,  como colapso parcial ou total da fundação, que comprometem a integridade da estrutura. Há indicativos de movimentação de encostas e deslizamento de terras.",
							valor: 5,
						},
						{
							id: 7,
							dominioOptionId: 7,
							titulo: "Não avaliada",
							informacoes: "Não foi possível avaliar a fundação.",
							valor: null,
						},
					],
					[
						{ id: 8, dominioOptionId: 9, titulo: "Extensão 0", valor: 0 },
						{ id: 9, dominioOptionId: 10, titulo: "Extensão 1", valor: 1 },
						{ id: 10, dominioOptionId: 11, titulo: "Extensão 2", valor: 2 },
						{ id: 11, dominioOptionId: 12, titulo: "Extensão 3", valor: 3 },
						{ id: 12, dominioOptionId: 13, titulo: "Extensão 4", valor: 4 },
						{ id: 13, dominioOptionId: 14, titulo: "Extensão 5", valor: 5 },
						{ id: 14, dominioOptionId: 15, titulo: "Não avaliada", valor: null },
					],
				],
			},
			{
				id: 4,
				titulo: "Paredes-Est-Blocos",
				importanciaTecnica: 5,
				importanciaFuncional: null,
				valorMaximo: 1,
				opcoes: [
					[
						{
							id: 1,
							dominioOptionId: 1,
							titulo: "Intensidade 0",
							informacoes: "Não há indicativo de dano na fundação pré-existente ou devido à inundação.",
							valor: 0,
						},
						{
							id: 2,
							dominioOptionId: 2,
							titulo: "Intensidade 1",
							informacoes:
								"Há indicativo de dano leve na fundação devido à inundação ou pré-existente, como erosão do solo e exposição superficial da fundação (pilaretes, blocos de fundação, vigas de baldrame), porém não é visível a base de apoio da fundação (estacas, sapatas, fundação superficial).",
							valor: 1,
						},
						{
							id: 3,
							dominioOptionId: 3,
							titulo: "Intensidade 2",
							informacoes:
								"Há indicativo de dano moderado na fundação devido à inundação ou pré-existente, como erosão e exposição da base de apoio da fundação (estacas, sapatas, fundação superficial, entre outras), que podem reduzir parcialmente a segurança da fundação.",
							valor: 2,
						},
						{
							id: 4,
							dominioOptionId: 4,
							titulo: "Intensidade 3",
							informacoes:
								"Há indicativo de dano grave na fundação devido à inundação ou pré-existente, como erosão profunda (abaixo da base de apoio da fundação), movimentação do solo ou indicativo, recalque diferencial causado pelo solo erodido, fissura(s) na edificação que indicam movimentação da fundação, comprometimento de muro(s) de contenção ou outros indicativos que comprometem significativamente a segurança da fundação.",
							valor: 3,
						},
						{
							id: 5,
							dominioOptionId: 5,
							titulo: "Intensidade 4",
							informacoes:
								" Há indicativo de dano severo na fundação devido à inundação ou pré-existente, como indicativo de ruptura, deformação excessiva, recalque, movimentação, perda da base de apoio da fundação ou outros indicativos que causam a perda da segurança da fundação.",
							valor: 4,
						},
						{
							id: 6,
							dominioOptionId: 6,
							titulo: "Intensidade 5",
							informacoes:
								"Há indicativo de dano extremamente severo na fundação devido à inundação ou pré-existente,  como colapso parcial ou total da fundação, que comprometem a integridade da estrutura. Há indicativos de movimentação de encostas e deslizamento de terras.",
							valor: 5,
						},
						{
							id: 7,
							dominioOptionId: 7,
							titulo: "Não avaliada",
							informacoes: "Não foi possível avaliar a fundação.",
							valor: null,
						},
					],
					[
						{ id: 8, dominioOptionId: 9, titulo: "Extensão 0", valor: 0 },
						{ id: 9, dominioOptionId: 10, titulo: "Extensão 1", valor: 1 },
						{ id: 10, dominioOptionId: 11, titulo: "Extensão 2", valor: 2 },
						{ id: 11, dominioOptionId: 12, titulo: "Extensão 3", valor: 3 },
						{ id: 12, dominioOptionId: 13, titulo: "Extensão 4", valor: 4 },
						{ id: 13, dominioOptionId: 14, titulo: "Extensão 5", valor: 5 },
						{ id: 14, dominioOptionId: 15, titulo: "Não avaliada", valor: null },
					],
				],
			},
			{
				id: 5,
				titulo: "Paredes-Est-Madeira",
				importanciaTecnica: 5,
				importanciaFuncional: null,
				valorMaximo: 1,
				opcoes: [
					[
						{
							id: 1,
							dominioOptionId: 1,
							titulo: "Intensidade 0",
							informacoes: "Não há indicativo de dano na fundação pré-existente ou devido à inundação.",
							valor: 0,
						},
						{
							id: 2,
							dominioOptionId: 2,
							titulo: "Intensidade 1",
							informacoes:
								"Há indicativo de dano leve na fundação devido à inundação ou pré-existente, como erosão do solo e exposição superficial da fundação (pilaretes, blocos de fundação, vigas de baldrame), porém não é visível a base de apoio da fundação (estacas, sapatas, fundação superficial).",
							valor: 1,
						},
						{
							id: 3,
							dominioOptionId: 3,
							titulo: "Intensidade 2",
							informacoes:
								"Há indicativo de dano moderado na fundação devido à inundação ou pré-existente, como erosão e exposição da base de apoio da fundação (estacas, sapatas, fundação superficial, entre outras), que podem reduzir parcialmente a segurança da fundação.",
							valor: 2,
						},
						{
							id: 4,
							dominioOptionId: 4,
							titulo: "Intensidade 3",
							informacoes:
								"Há indicativo de dano grave na fundação devido à inundação ou pré-existente, como erosão profunda (abaixo da base de apoio da fundação), movimentação do solo ou indicativo, recalque diferencial causado pelo solo erodido, fissura(s) na edificação que indicam movimentação da fundação, comprometimento de muro(s) de contenção ou outros indicativos que comprometem significativamente a segurança da fundação.",
							valor: 3,
						},
						{
							id: 5,
							dominioOptionId: 5,
							titulo: "Intensidade 4",
							informacoes:
								" Há indicativo de dano severo na fundação devido à inundação ou pré-existente, como indicativo de ruptura, deformação excessiva, recalque, movimentação, perda da base de apoio da fundação ou outros indicativos que causam a perda da segurança da fundação.",
							valor: 4,
						},
						{
							id: 6,
							dominioOptionId: 6,
							titulo: "Intensidade 5",
							informacoes:
								"Há indicativo de dano extremamente severo na fundação devido à inundação ou pré-existente,  como colapso parcial ou total da fundação, que comprometem a integridade da estrutura. Há indicativos de movimentação de encostas e deslizamento de terras.",
							valor: 5,
						},
						{
							id: 7,
							dominioOptionId: 7,
							titulo: "Não avaliada",
							informacoes: "Não foi possível avaliar a fundação.",
							valor: null,
						},
					],
					[
						{ id: 8, dominioOptionId: 9, titulo: "Extensão 0", valor: 0 },
						{ id: 9, dominioOptionId: 10, titulo: "Extensão 1", valor: 1 },
						{ id: 10, dominioOptionId: 11, titulo: "Extensão 2", valor: 2 },
						{ id: 11, dominioOptionId: 12, titulo: "Extensão 3", valor: 3 },
						{ id: 12, dominioOptionId: 13, titulo: "Extensão 4", valor: 4 },
						{ id: 13, dominioOptionId: 14, titulo: "Extensão 5", valor: 5 },
						{ id: 14, dominioOptionId: 15, titulo: "Não avaliada", valor: null },
					],
				],
			},
			{
				id: 6,
				titulo: "Paredes-Est-Geral",
				importanciaTecnica: 5,
				importanciaFuncional: null,
				valorMaximo: 1,
				opcoes: [
					[
						{
							id: 1,
							dominioOptionId: 1,
							titulo: "Intensidade 0",
							informacoes: "Não há indicativo de dano na fundação pré-existente ou devido à inundação.",
							valor: 0,
						},
						{
							id: 2,
							dominioOptionId: 2,
							titulo: "Intensidade 1",
							informacoes:
								"Há indicativo de dano leve na fundação devido à inundação ou pré-existente, como erosão do solo e exposição superficial da fundação (pilaretes, blocos de fundação, vigas de baldrame), porém não é visível a base de apoio da fundação (estacas, sapatas, fundação superficial).",
							valor: 1,
						},
						{
							id: 3,
							dominioOptionId: 3,
							titulo: "Intensidade 2",
							informacoes:
								"Há indicativo de dano moderado na fundação devido à inundação ou pré-existente, como erosão e exposição da base de apoio da fundação (estacas, sapatas, fundação superficial, entre outras), que podem reduzir parcialmente a segurança da fundação.",
							valor: 2,
						},
						{
							id: 4,
							dominioOptionId: 4,
							titulo: "Intensidade 3",
							informacoes:
								"Há indicativo de dano grave na fundação devido à inundação ou pré-existente, como erosão profunda (abaixo da base de apoio da fundação), movimentação do solo ou indicativo, recalque diferencial causado pelo solo erodido, fissura(s) na edificação que indicam movimentação da fundação, comprometimento de muro(s) de contenção ou outros indicativos que comprometem significativamente a segurança da fundação.",
							valor: 3,
						},
						{
							id: 5,
							dominioOptionId: 5,
							titulo: "Intensidade 4",
							informacoes:
								" Há indicativo de dano severo na fundação devido à inundação ou pré-existente, como indicativo de ruptura, deformação excessiva, recalque, movimentação, perda da base de apoio da fundação ou outros indicativos que causam a perda da segurança da fundação.",
							valor: 4,
						},
						{
							id: 6,
							dominioOptionId: 6,
							titulo: "Intensidade 5",
							informacoes:
								"Há indicativo de dano extremamente severo na fundação devido à inundação ou pré-existente,  como colapso parcial ou total da fundação, que comprometem a integridade da estrutura. Há indicativos de movimentação de encostas e deslizamento de terras.",
							valor: 5,
						},
						{
							id: 7,
							dominioOptionId: 7,
							titulo: "Não avaliada",
							informacoes: "Não foi possível avaliar a fundação.",
							valor: null,
						},
					],
					[
						{ id: 8, dominioOptionId: 9, titulo: "Extensão 0", valor: 0 },
						{ id: 9, dominioOptionId: 10, titulo: "Extensão 1", valor: 1 },
						{ id: 10, dominioOptionId: 11, titulo: "Extensão 2", valor: 2 },
						{ id: 11, dominioOptionId: 12, titulo: "Extensão 3", valor: 3 },
						{ id: 12, dominioOptionId: 13, titulo: "Extensão 4", valor: 4 },
						{ id: 13, dominioOptionId: 14, titulo: "Extensão 5", valor: 5 },
						{ id: 14, dominioOptionId: 15, titulo: "Não avaliada", valor: null },
					],
				],
			},
			{
				id: 7,
				titulo: "Laje-Concreto",
				importanciaTecnica: 2,
				importanciaFuncional: null,
				valorMaximo: 0.252982213,
				opcoes: [
					[
						{
							id: 1,
							dominioOptionId: 1,
							titulo: "Intensidade 0",
							informacoes: "Não há indicativo de dano na fundação pré-existente ou devido à inundação.",
							valor: 0,
						},
						{
							id: 2,
							dominioOptionId: 2,
							titulo: "Intensidade 1",
							informacoes:
								"Há indicativo de dano leve na fundação devido à inundação ou pré-existente, como erosão do solo e exposição superficial da fundação (pilaretes, blocos de fundação, vigas de baldrame), porém não é visível a base de apoio da fundação (estacas, sapatas, fundação superficial).",
							valor: 1,
						},
						{
							id: 3,
							dominioOptionId: 3,
							titulo: "Intensidade 2",
							informacoes:
								"Há indicativo de dano moderado na fundação devido à inundação ou pré-existente, como erosão e exposição da base de apoio da fundação (estacas, sapatas, fundação superficial, entre outras), que podem reduzir parcialmente a segurança da fundação.",
							valor: 2,
						},
						{
							id: 4,
							dominioOptionId: 4,
							titulo: "Intensidade 3",
							informacoes:
								"Há indicativo de dano grave na fundação devido à inundação ou pré-existente, como erosão profunda (abaixo da base de apoio da fundação), movimentação do solo ou indicativo, recalque diferencial causado pelo solo erodido, fissura(s) na edificação que indicam movimentação da fundação, comprometimento de muro(s) de contenção ou outros indicativos que comprometem significativamente a segurança da fundação.",
							valor: 3,
						},
						{
							id: 5,
							dominioOptionId: 5,
							titulo: "Intensidade 4",
							informacoes:
								" Há indicativo de dano severo na fundação devido à inundação ou pré-existente, como indicativo de ruptura, deformação excessiva, recalque, movimentação, perda da base de apoio da fundação ou outros indicativos que causam a perda da segurança da fundação.",
							valor: 4,
						},
						{
							id: 6,
							dominioOptionId: 6,
							titulo: "Intensidade 5",
							informacoes:
								"Há indicativo de dano extremamente severo na fundação devido à inundação ou pré-existente,  como colapso parcial ou total da fundação, que comprometem a integridade da estrutura. Há indicativos de movimentação de encostas e deslizamento de terras.",
							valor: 5,
						},
						{
							id: 7,
							dominioOptionId: 7,
							titulo: "Não avaliada",
							informacoes: "Não foi possível avaliar a fundação.",
							valor: null,
						},
					],
					[
						{ id: 8, dominioOptionId: 9, titulo: "Extensão 0", valor: 0 },
						{ id: 9, dominioOptionId: 10, titulo: "Extensão 1", valor: 1 },
						{ id: 10, dominioOptionId: 11, titulo: "Extensão 2", valor: 2 },
						{ id: 11, dominioOptionId: 12, titulo: "Extensão 3", valor: 3 },
						{ id: 12, dominioOptionId: 13, titulo: "Extensão 4", valor: 4 },
						{ id: 13, dominioOptionId: 14, titulo: "Extensão 5", valor: 5 },
						{ id: 14, dominioOptionId: 15, titulo: "Não avaliada", valor: null },
					],
				],
			},
			{
				id: 8,
				titulo: "Laje-Madeira",
				importanciaTecnica: 2,
				importanciaFuncional: null,
				valorMaximo: 0.252982213,
				opcoes: [
					[
						{
							id: 1,
							dominioOptionId: 1,
							titulo: "Intensidade 0",
							informacoes: "Não há indicativo de dano na fundação pré-existente ou devido à inundação.",
							valor: 0,
						},
						{
							id: 2,
							dominioOptionId: 2,
							titulo: "Intensidade 1",
							informacoes:
								"Há indicativo de dano leve na fundação devido à inundação ou pré-existente, como erosão do solo e exposição superficial da fundação (pilaretes, blocos de fundação, vigas de baldrame), porém não é visível a base de apoio da fundação (estacas, sapatas, fundação superficial).",
							valor: 1,
						},
						{
							id: 3,
							dominioOptionId: 3,
							titulo: "Intensidade 2",
							informacoes:
								"Há indicativo de dano moderado na fundação devido à inundação ou pré-existente, como erosão e exposição da base de apoio da fundação (estacas, sapatas, fundação superficial, entre outras), que podem reduzir parcialmente a segurança da fundação.",
							valor: 2,
						},
						{
							id: 4,
							dominioOptionId: 4,
							titulo: "Intensidade 3",
							informacoes:
								"Há indicativo de dano grave na fundação devido à inundação ou pré-existente, como erosão profunda (abaixo da base de apoio da fundação), movimentação do solo ou indicativo, recalque diferencial causado pelo solo erodido, fissura(s) na edificação que indicam movimentação da fundação, comprometimento de muro(s) de contenção ou outros indicativos que comprometem significativamente a segurança da fundação.",
							valor: 3,
						},
						{
							id: 5,
							dominioOptionId: 5,
							titulo: "Intensidade 4",
							informacoes:
								" Há indicativo de dano severo na fundação devido à inundação ou pré-existente, como indicativo de ruptura, deformação excessiva, recalque, movimentação, perda da base de apoio da fundação ou outros indicativos que causam a perda da segurança da fundação.",
							valor: 4,
						},
						{
							id: 6,
							dominioOptionId: 6,
							titulo: "Intensidade 5",
							informacoes:
								"Há indicativo de dano extremamente severo na fundação devido à inundação ou pré-existente,  como colapso parcial ou total da fundação, que comprometem a integridade da estrutura. Há indicativos de movimentação de encostas e deslizamento de terras.",
							valor: 5,
						},
						{
							id: 7,
							dominioOptionId: 7,
							titulo: "Não avaliada",
							informacoes: "Não foi possível avaliar a fundação.",
							valor: null,
						},
					],
					[
						{ id: 8, dominioOptionId: 9, titulo: "Extensão 0", valor: 0 },
						{ id: 9, dominioOptionId: 10, titulo: "Extensão 1", valor: 1 },
						{ id: 10, dominioOptionId: 11, titulo: "Extensão 2", valor: 2 },
						{ id: 11, dominioOptionId: 12, titulo: "Extensão 3", valor: 3 },
						{ id: 12, dominioOptionId: 13, titulo: "Extensão 4", valor: 4 },
						{ id: 13, dominioOptionId: 14, titulo: "Extensão 5", valor: 5 },
						{ id: 14, dominioOptionId: 15, titulo: "Não avaliada", valor: null },
					],
				],
			},
			{
				id: 9,
				titulo: "Laje-Geral",
				importanciaTecnica: 2,
				importanciaFuncional: null,
				valorMaximo: 0.252982213,
				opcoes: [
					[
						{
							id: 1,
							dominioOptionId: 1,
							titulo: "Intensidade 0",
							informacoes: "Não há indicativo de dano na fundação pré-existente ou devido à inundação.",
							valor: 0,
						},
						{
							id: 2,
							dominioOptionId: 2,
							titulo: "Intensidade 1",
							informacoes:
								"Há indicativo de dano leve na fundação devido à inundação ou pré-existente, como erosão do solo e exposição superficial da fundação (pilaretes, blocos de fundação, vigas de baldrame), porém não é visível a base de apoio da fundação (estacas, sapatas, fundação superficial).",
							valor: 1,
						},
						{
							id: 3,
							dominioOptionId: 3,
							titulo: "Intensidade 2",
							informacoes:
								"Há indicativo de dano moderado na fundação devido à inundação ou pré-existente, como erosão e exposição da base de apoio da fundação (estacas, sapatas, fundação superficial, entre outras), que podem reduzir parcialmente a segurança da fundação.",
							valor: 2,
						},
						{
							id: 4,
							dominioOptionId: 4,
							titulo: "Intensidade 3",
							informacoes:
								"Há indicativo de dano grave na fundação devido à inundação ou pré-existente, como erosão profunda (abaixo da base de apoio da fundação), movimentação do solo ou indicativo, recalque diferencial causado pelo solo erodido, fissura(s) na edificação que indicam movimentação da fundação, comprometimento de muro(s) de contenção ou outros indicativos que comprometem significativamente a segurança da fundação.",
							valor: 3,
						},
						{
							id: 5,
							dominioOptionId: 5,
							titulo: "Intensidade 4",
							informacoes:
								" Há indicativo de dano severo na fundação devido à inundação ou pré-existente, como indicativo de ruptura, deformação excessiva, recalque, movimentação, perda da base de apoio da fundação ou outros indicativos que causam a perda da segurança da fundação.",
							valor: 4,
						},
						{
							id: 6,
							dominioOptionId: 6,
							titulo: "Intensidade 5",
							informacoes:
								"Há indicativo de dano extremamente severo na fundação devido à inundação ou pré-existente,  como colapso parcial ou total da fundação, que comprometem a integridade da estrutura. Há indicativos de movimentação de encostas e deslizamento de terras.",
							valor: 5,
						},
						{
							id: 7,
							dominioOptionId: 7,
							titulo: "Não avaliada",
							informacoes: "Não foi possível avaliar a fundação.",
							valor: null,
						},
					],
					[
						{ id: 8, dominioOptionId: 9, titulo: "Extensão 0", valor: 0 },
						{ id: 9, dominioOptionId: 10, titulo: "Extensão 1", valor: 1 },
						{ id: 10, dominioOptionId: 11, titulo: "Extensão 2", valor: 2 },
						{ id: 11, dominioOptionId: 12, titulo: "Extensão 3", valor: 3 },
						{ id: 12, dominioOptionId: 13, titulo: "Extensão 4", valor: 4 },
						{ id: 13, dominioOptionId: 14, titulo: "Extensão 5", valor: 5 },
						{ id: 14, dominioOptionId: 15, titulo: "Não avaliada", valor: null },
					],
				],
			},
			{
				id: 10,
				titulo: "Cobertura-estrutral",
				importanciaTecnica: 3.5,
				importanciaFuncional: null,
				valorMaximo: 0.585662019,
				opcoes: [
					[
						{
							id: 1,
							dominioOptionId: 1,
							titulo: "Intensidade 0",
							informacoes: "Não há indicativo de dano na fundação pré-existente ou devido à inundação.",
							valor: 0,
						},
						{
							id: 2,
							dominioOptionId: 2,
							titulo: "Intensidade 1",
							informacoes:
								"Há indicativo de dano leve na fundação devido à inundação ou pré-existente, como erosão do solo e exposição superficial da fundação (pilaretes, blocos de fundação, vigas de baldrame), porém não é visível a base de apoio da fundação (estacas, sapatas, fundação superficial).",
							valor: 1,
						},
						{
							id: 3,
							dominioOptionId: 3,
							titulo: "Intensidade 2",
							informacoes:
								"Há indicativo de dano moderado na fundação devido à inundação ou pré-existente, como erosão e exposição da base de apoio da fundação (estacas, sapatas, fundação superficial, entre outras), que podem reduzir parcialmente a segurança da fundação.",
							valor: 2,
						},
						{
							id: 4,
							dominioOptionId: 4,
							titulo: "Intensidade 3",
							informacoes:
								"Há indicativo de dano grave na fundação devido à inundação ou pré-existente, como erosão profunda (abaixo da base de apoio da fundação), movimentação do solo ou indicativo, recalque diferencial causado pelo solo erodido, fissura(s) na edificação que indicam movimentação da fundação, comprometimento de muro(s) de contenção ou outros indicativos que comprometem significativamente a segurança da fundação.",
							valor: 3,
						},
						{
							id: 5,
							dominioOptionId: 5,
							titulo: "Intensidade 4",
							informacoes:
								" Há indicativo de dano severo na fundação devido à inundação ou pré-existente, como indicativo de ruptura, deformação excessiva, recalque, movimentação, perda da base de apoio da fundação ou outros indicativos que causam a perda da segurança da fundação.",
							valor: 4,
						},
						{
							id: 6,
							dominioOptionId: 6,
							titulo: "Intensidade 5",
							informacoes:
								"Há indicativo de dano extremamente severo na fundação devido à inundação ou pré-existente,  como colapso parcial ou total da fundação, que comprometem a integridade da estrutura. Há indicativos de movimentação de encostas e deslizamento de terras.",
							valor: 5,
						},
						{
							id: 7,
							dominioOptionId: 7,
							titulo: "Não avaliada",
							informacoes: "Não foi possível avaliar a fundação.",
							valor: null,
						},
					],
					[
						{ id: 8, dominioOptionId: 9, titulo: "Extensão 0", valor: 0 },
						{ id: 9, dominioOptionId: 10, titulo: "Extensão 1", valor: 1 },
						{ id: 10, dominioOptionId: 11, titulo: "Extensão 2", valor: 2 },
						{ id: 11, dominioOptionId: 12, titulo: "Extensão 3", valor: 3 },
						{ id: 12, dominioOptionId: 13, titulo: "Extensão 4", valor: 4 },
						{ id: 13, dominioOptionId: 14, titulo: "Extensão 5", valor: 5 },
						{ id: 14, dominioOptionId: 15, titulo: "Não avaliada", valor: null },
					],
				],
			},
			{
				id: 11,
				titulo: "Func-Piso",
				importanciaTecnica: null,
				importanciaFuncional: 3,
				valorMaximo: 0.464758002,
				opcoes: [
					[
						{
							id: 1,
							dominioOptionId: 1,
							titulo: "Intensidade 0",
							informacoes: "Não há indicativo de dano na fundação pré-existente ou devido à inundação.",
							valor: 0,
						},
						{
							id: 2,
							dominioOptionId: 2,
							titulo: "Intensidade 1",
							informacoes:
								"Há indicativo de dano leve na fundação devido à inundação ou pré-existente, como erosão do solo e exposição superficial da fundação (pilaretes, blocos de fundação, vigas de baldrame), porém não é visível a base de apoio da fundação (estacas, sapatas, fundação superficial).",
							valor: 1,
						},
						{
							id: 3,
							dominioOptionId: 3,
							titulo: "Intensidade 2",
							informacoes:
								"Há indicativo de dano moderado na fundação devido à inundação ou pré-existente, como erosão e exposição da base de apoio da fundação (estacas, sapatas, fundação superficial, entre outras), que podem reduzir parcialmente a segurança da fundação.",
							valor: 2,
						},
						{
							id: 4,
							dominioOptionId: 4,
							titulo: "Intensidade 3",
							informacoes:
								"Há indicativo de dano grave na fundação devido à inundação ou pré-existente, como erosão profunda (abaixo da base de apoio da fundação), movimentação do solo ou indicativo, recalque diferencial causado pelo solo erodido, fissura(s) na edificação que indicam movimentação da fundação, comprometimento de muro(s) de contenção ou outros indicativos que comprometem significativamente a segurança da fundação.",
							valor: 3,
						},
						{
							id: 5,
							dominioOptionId: 5,
							titulo: "Intensidade 4",
							informacoes:
								" Há indicativo de dano severo na fundação devido à inundação ou pré-existente, como indicativo de ruptura, deformação excessiva, recalque, movimentação, perda da base de apoio da fundação ou outros indicativos que causam a perda da segurança da fundação.",
							valor: 4,
						},
						{
							id: 6,
							dominioOptionId: 6,
							titulo: "Intensidade 5",
							informacoes:
								"Há indicativo de dano extremamente severo na fundação devido à inundação ou pré-existente,  como colapso parcial ou total da fundação, que comprometem a integridade da estrutura. Há indicativos de movimentação de encostas e deslizamento de terras.",
							valor: 5,
						},
						{
							id: 7,
							dominioOptionId: 7,
							titulo: "Não avaliada",
							informacoes: "Não foi possível avaliar a fundação.",
							valor: null,
						},
					],
					[
						{ id: 8, dominioOptionId: 9, titulo: "Extensão 0", valor: 0 },
						{ id: 9, dominioOptionId: 10, titulo: "Extensão 1", valor: 1 },
						{ id: 10, dominioOptionId: 11, titulo: "Extensão 2", valor: 2 },
						{ id: 11, dominioOptionId: 12, titulo: "Extensão 3", valor: 3 },
						{ id: 12, dominioOptionId: 13, titulo: "Extensão 4", valor: 4 },
						{ id: 13, dominioOptionId: 14, titulo: "Extensão 5", valor: 5 },
						{ id: 14, dominioOptionId: 15, titulo: "Não avaliada", valor: null },
					],
				],
			},
			{
				id: 12,
				titulo: "Func-Parede-Madeira",
				importanciaTecnica: null,
				importanciaFuncional: 2,
				valorMaximo: 0.252982213,
				opcoes: [
					[
						{
							id: 1,
							dominioOptionId: 1,
							titulo: "Intensidade 0",
							informacoes: "Não há indicativo de dano na fundação pré-existente ou devido à inundação.",
							valor: 0,
						},
						{
							id: 2,
							dominioOptionId: 2,
							titulo: "Intensidade 1",
							informacoes:
								"Há indicativo de dano leve na fundação devido à inundação ou pré-existente, como erosão do solo e exposição superficial da fundação (pilaretes, blocos de fundação, vigas de baldrame), porém não é visível a base de apoio da fundação (estacas, sapatas, fundação superficial).",
							valor: 1,
						},
						{
							id: 3,
							dominioOptionId: 3,
							titulo: "Intensidade 2",
							informacoes:
								"Há indicativo de dano moderado na fundação devido à inundação ou pré-existente, como erosão e exposição da base de apoio da fundação (estacas, sapatas, fundação superficial, entre outras), que podem reduzir parcialmente a segurança da fundação.",
							valor: 2,
						},
						{
							id: 4,
							dominioOptionId: 4,
							titulo: "Intensidade 3",
							informacoes:
								"Há indicativo de dano grave na fundação devido à inundação ou pré-existente, como erosão profunda (abaixo da base de apoio da fundação), movimentação do solo ou indicativo, recalque diferencial causado pelo solo erodido, fissura(s) na edificação que indicam movimentação da fundação, comprometimento de muro(s) de contenção ou outros indicativos que comprometem significativamente a segurança da fundação.",
							valor: 3,
						},
						{
							id: 5,
							dominioOptionId: 5,
							titulo: "Intensidade 4",
							informacoes:
								" Há indicativo de dano severo na fundação devido à inundação ou pré-existente, como indicativo de ruptura, deformação excessiva, recalque, movimentação, perda da base de apoio da fundação ou outros indicativos que causam a perda da segurança da fundação.",
							valor: 4,
						},
						{
							id: 6,
							dominioOptionId: 6,
							titulo: "Intensidade 5",
							informacoes:
								"Há indicativo de dano extremamente severo na fundação devido à inundação ou pré-existente,  como colapso parcial ou total da fundação, que comprometem a integridade da estrutura. Há indicativos de movimentação de encostas e deslizamento de terras.",
							valor: 5,
						},
						{
							id: 7,
							dominioOptionId: 7,
							titulo: "Não avaliada",
							informacoes: "Não foi possível avaliar a fundação.",
							valor: null,
						},
					],
					[
						{ id: 8, dominioOptionId: 9, titulo: "Extensão 0", valor: 0 },
						{ id: 9, dominioOptionId: 10, titulo: "Extensão 1", valor: 1 },
						{ id: 10, dominioOptionId: 11, titulo: "Extensão 2", valor: 2 },
						{ id: 11, dominioOptionId: 12, titulo: "Extensão 3", valor: 3 },
						{ id: 12, dominioOptionId: 13, titulo: "Extensão 4", valor: 4 },
						{ id: 13, dominioOptionId: 14, titulo: "Extensão 5", valor: 5 },
						{ id: 14, dominioOptionId: 15, titulo: "Não avaliada", valor: null },
					],
				],
			},
			{
				id: 13,
				titulo: "Func-Forro",
				importanciaTecnica: null,
				importanciaFuncional: 2,
				valorMaximo: 0.252982213,
				opcoes: [
					[
						{
							id: 1,
							dominioOptionId: 1,
							titulo: "Intensidade 0",
							informacoes: "Não há indicativo de dano na fundação pré-existente ou devido à inundação.",
							valor: 0,
						},
						{
							id: 2,
							dominioOptionId: 2,
							titulo: "Intensidade 1",
							informacoes:
								"Há indicativo de dano leve na fundação devido à inundação ou pré-existente, como erosão do solo e exposição superficial da fundação (pilaretes, blocos de fundação, vigas de baldrame), porém não é visível a base de apoio da fundação (estacas, sapatas, fundação superficial).",
							valor: 1,
						},
						{
							id: 3,
							dominioOptionId: 3,
							titulo: "Intensidade 2",
							informacoes:
								"Há indicativo de dano moderado na fundação devido à inundação ou pré-existente, como erosão e exposição da base de apoio da fundação (estacas, sapatas, fundação superficial, entre outras), que podem reduzir parcialmente a segurança da fundação.",
							valor: 2,
						},
						{
							id: 4,
							dominioOptionId: 4,
							titulo: "Intensidade 3",
							informacoes:
								"Há indicativo de dano grave na fundação devido à inundação ou pré-existente, como erosão profunda (abaixo da base de apoio da fundação), movimentação do solo ou indicativo, recalque diferencial causado pelo solo erodido, fissura(s) na edificação que indicam movimentação da fundação, comprometimento de muro(s) de contenção ou outros indicativos que comprometem significativamente a segurança da fundação.",
							valor: 3,
						},
						{
							id: 5,
							dominioOptionId: 5,
							titulo: "Intensidade 4",
							informacoes:
								" Há indicativo de dano severo na fundação devido à inundação ou pré-existente, como indicativo de ruptura, deformação excessiva, recalque, movimentação, perda da base de apoio da fundação ou outros indicativos que causam a perda da segurança da fundação.",
							valor: 4,
						},
						{
							id: 6,
							dominioOptionId: 6,
							titulo: "Intensidade 5",
							informacoes:
								"Há indicativo de dano extremamente severo na fundação devido à inundação ou pré-existente,  como colapso parcial ou total da fundação, que comprometem a integridade da estrutura. Há indicativos de movimentação de encostas e deslizamento de terras.",
							valor: 5,
						},
						{
							id: 7,
							dominioOptionId: 7,
							titulo: "Não avaliada",
							informacoes: "Não foi possível avaliar a fundação.",
							valor: null,
						},
					],
					[
						{ id: 8, dominioOptionId: 9, titulo: "Extensão 0", valor: 0 },
						{ id: 9, dominioOptionId: 10, titulo: "Extensão 1", valor: 1 },
						{ id: 10, dominioOptionId: 11, titulo: "Extensão 2", valor: 2 },
						{ id: 11, dominioOptionId: 12, titulo: "Extensão 3", valor: 3 },
						{ id: 12, dominioOptionId: 13, titulo: "Extensão 4", valor: 4 },
						{ id: 13, dominioOptionId: 14, titulo: "Extensão 5", valor: 5 },
						{ id: 14, dominioOptionId: 15, titulo: "Não avaliada", valor: null },
					],
				],
			},
			{
				id: 14,
				titulo: "Func-Abertura",
				importanciaTecnica: null,
				importanciaFuncional: 4,
				valorMaximo: 0.715541753,
				opcoes: [
					[
						{
							id: 1,
							dominioOptionId: 1,
							titulo: "Intensidade 0",
							informacoes: "Não há indicativo de dano na fundação pré-existente ou devido à inundação.",
							valor: 0,
						},
						{
							id: 2,
							dominioOptionId: 2,
							titulo: "Intensidade 1",
							informacoes:
								"Há indicativo de dano leve na fundação devido à inundação ou pré-existente, como erosão do solo e exposição superficial da fundação (pilaretes, blocos de fundação, vigas de baldrame), porém não é visível a base de apoio da fundação (estacas, sapatas, fundação superficial).",
							valor: 1,
						},
						{
							id: 3,
							dominioOptionId: 3,
							titulo: "Intensidade 2",
							informacoes:
								"Há indicativo de dano moderado na fundação devido à inundação ou pré-existente, como erosão e exposição da base de apoio da fundação (estacas, sapatas, fundação superficial, entre outras), que podem reduzir parcialmente a segurança da fundação.",
							valor: 2,
						},
						{
							id: 4,
							dominioOptionId: 4,
							titulo: "Intensidade 3",
							informacoes:
								"Há indicativo de dano grave na fundação devido à inundação ou pré-existente, como erosão profunda (abaixo da base de apoio da fundação), movimentação do solo ou indicativo, recalque diferencial causado pelo solo erodido, fissura(s) na edificação que indicam movimentação da fundação, comprometimento de muro(s) de contenção ou outros indicativos que comprometem significativamente a segurança da fundação.",
							valor: 3,
						},
						{
							id: 5,
							dominioOptionId: 5,
							titulo: "Intensidade 4",
							informacoes:
								" Há indicativo de dano severo na fundação devido à inundação ou pré-existente, como indicativo de ruptura, deformação excessiva, recalque, movimentação, perda da base de apoio da fundação ou outros indicativos que causam a perda da segurança da fundação.",
							valor: 4,
						},
						{
							id: 6,
							dominioOptionId: 6,
							titulo: "Intensidade 5",
							informacoes:
								"Há indicativo de dano extremamente severo na fundação devido à inundação ou pré-existente,  como colapso parcial ou total da fundação, que comprometem a integridade da estrutura. Há indicativos de movimentação de encostas e deslizamento de terras.",
							valor: 5,
						},
						{
							id: 7,
							dominioOptionId: 7,
							titulo: "Não avaliada",
							informacoes: "Não foi possível avaliar a fundação.",
							valor: null,
						},
					],
					[
						{ id: 8, dominioOptionId: 9, titulo: "Extensão 0", valor: 0 },
						{ id: 9, dominioOptionId: 10, titulo: "Extensão 1", valor: 1 },
						{ id: 10, dominioOptionId: 11, titulo: "Extensão 2", valor: 2 },
						{ id: 11, dominioOptionId: 12, titulo: "Extensão 3", valor: 3 },
						{ id: 12, dominioOptionId: 13, titulo: "Extensão 4", valor: 4 },
						{ id: 13, dominioOptionId: 14, titulo: "Extensão 5", valor: 5 },
						{ id: 14, dominioOptionId: 15, titulo: "Não avaliada", valor: null },
					],
				],
			},
			{
				id: 15,
				titulo: "Func-Inst-Hidro",
				importanciaTecnica: null,
				importanciaFuncional: 3.5,
				valorMaximo: 0.585662019,
				opcoes: [
					[
						{
							id: 1,
							dominioOptionId: 1,
							titulo: "Intensidade 0",
							informacoes: "Não há indicativo de dano na fundação pré-existente ou devido à inundação.",
							valor: 0,
						},
						{
							id: 2,
							dominioOptionId: 2,
							titulo: "Intensidade 1",
							informacoes:
								"Há indicativo de dano leve na fundação devido à inundação ou pré-existente, como erosão do solo e exposição superficial da fundação (pilaretes, blocos de fundação, vigas de baldrame), porém não é visível a base de apoio da fundação (estacas, sapatas, fundação superficial).",
							valor: 1,
						},
						{
							id: 3,
							dominioOptionId: 3,
							titulo: "Intensidade 2",
							informacoes:
								"Há indicativo de dano moderado na fundação devido à inundação ou pré-existente, como erosão e exposição da base de apoio da fundação (estacas, sapatas, fundação superficial, entre outras), que podem reduzir parcialmente a segurança da fundação.",
							valor: 2,
						},
						{
							id: 4,
							dominioOptionId: 4,
							titulo: "Intensidade 3",
							informacoes:
								"Há indicativo de dano grave na fundação devido à inundação ou pré-existente, como erosão profunda (abaixo da base de apoio da fundação), movimentação do solo ou indicativo, recalque diferencial causado pelo solo erodido, fissura(s) na edificação que indicam movimentação da fundação, comprometimento de muro(s) de contenção ou outros indicativos que comprometem significativamente a segurança da fundação.",
							valor: 3,
						},
						{
							id: 5,
							dominioOptionId: 5,
							titulo: "Intensidade 4",
							informacoes:
								" Há indicativo de dano severo na fundação devido à inundação ou pré-existente, como indicativo de ruptura, deformação excessiva, recalque, movimentação, perda da base de apoio da fundação ou outros indicativos que causam a perda da segurança da fundação.",
							valor: 4,
						},
						{
							id: 6,
							dominioOptionId: 6,
							titulo: "Intensidade 5",
							informacoes:
								"Há indicativo de dano extremamente severo na fundação devido à inundação ou pré-existente,  como colapso parcial ou total da fundação, que comprometem a integridade da estrutura. Há indicativos de movimentação de encostas e deslizamento de terras.",
							valor: 5,
						},
						{
							id: 7,
							dominioOptionId: 7,
							titulo: "Não avaliada",
							informacoes: "Não foi possível avaliar a fundação.",
							valor: null,
						},
					],
					[
						{ id: 8, dominioOptionId: 9, titulo: "Extensão 0", valor: 0 },
						{ id: 9, dominioOptionId: 10, titulo: "Extensão 1", valor: 1 },
						{ id: 10, dominioOptionId: 11, titulo: "Extensão 2", valor: 2 },
						{ id: 11, dominioOptionId: 12, titulo: "Extensão 3", valor: 3 },
						{ id: 12, dominioOptionId: 13, titulo: "Extensão 4", valor: 4 },
						{ id: 13, dominioOptionId: 14, titulo: "Extensão 5", valor: 5 },
						{ id: 14, dominioOptionId: 15, titulo: "Não avaliada", valor: null },
					],
				],
			},
			{
				id: 16,
				titulo: "Func-Inst-Eletr",
				importanciaTecnica: null,
				importanciaFuncional: 3.5,
				valorMaximo: 0.585662019,
				opcoes: [
					[
						{
							id: 1,
							dominioOptionId: 1,
							titulo: "Intensidade 0",
							informacoes: "Não há indicativo de dano na fundação pré-existente ou devido à inundação.",
							valor: 0,
						},
						{
							id: 2,
							dominioOptionId: 2,
							titulo: "Intensidade 1",
							informacoes:
								"Há indicativo de dano leve na fundação devido à inundação ou pré-existente, como erosão do solo e exposição superficial da fundação (pilaretes, blocos de fundação, vigas de baldrame), porém não é visível a base de apoio da fundação (estacas, sapatas, fundação superficial).",
							valor: 1,
						},
						{
							id: 3,
							dominioOptionId: 3,
							titulo: "Intensidade 2",
							informacoes:
								"Há indicativo de dano moderado na fundação devido à inundação ou pré-existente, como erosão e exposição da base de apoio da fundação (estacas, sapatas, fundação superficial, entre outras), que podem reduzir parcialmente a segurança da fundação.",
							valor: 2,
						},
						{
							id: 4,
							dominioOptionId: 4,
							titulo: "Intensidade 3",
							informacoes:
								"Há indicativo de dano grave na fundação devido à inundação ou pré-existente, como erosão profunda (abaixo da base de apoio da fundação), movimentação do solo ou indicativo, recalque diferencial causado pelo solo erodido, fissura(s) na edificação que indicam movimentação da fundação, comprometimento de muro(s) de contenção ou outros indicativos que comprometem significativamente a segurança da fundação.",
							valor: 3,
						},
						{
							id: 5,
							dominioOptionId: 5,
							titulo: "Intensidade 4",
							informacoes:
								" Há indicativo de dano severo na fundação devido à inundação ou pré-existente, como indicativo de ruptura, deformação excessiva, recalque, movimentação, perda da base de apoio da fundação ou outros indicativos que causam a perda da segurança da fundação.",
							valor: 4,
						},
						{
							id: 6,
							dominioOptionId: 6,
							titulo: "Intensidade 5",
							informacoes:
								"Há indicativo de dano extremamente severo na fundação devido à inundação ou pré-existente,  como colapso parcial ou total da fundação, que comprometem a integridade da estrutura. Há indicativos de movimentação de encostas e deslizamento de terras.",
							valor: 5,
						},
						{
							id: 7,
							dominioOptionId: 7,
							titulo: "Não avaliada",
							informacoes: "Não foi possível avaliar a fundação.",
							valor: null,
						},
					],
					[
						{ id: 8, dominioOptionId: 9, titulo: "Extensão 0", valor: 0 },
						{ id: 9, dominioOptionId: 10, titulo: "Extensão 1", valor: 1 },
						{ id: 10, dominioOptionId: 11, titulo: "Extensão 2", valor: 2 },
						{ id: 11, dominioOptionId: 12, titulo: "Extensão 3", valor: 3 },
						{ id: 12, dominioOptionId: 13, titulo: "Extensão 4", valor: 4 },
						{ id: 13, dominioOptionId: 14, titulo: "Extensão 5", valor: 5 },
						{ id: 14, dominioOptionId: 15, titulo: "Não avaliada", valor: null },
					],
				],
			},
			{
				id: 17,
				titulo: "Func-Moveis",
				importanciaTecnica: null,
				importanciaFuncional: 4.5,
				valorMaximo: 0.853814968,
				opcoes: [
					[
						{
							id: 1,
							dominioOptionId: 1,
							titulo: "Intensidade 0",
							informacoes: "Não há indicativo de dano na fundação pré-existente ou devido à inundação.",
							valor: 0,
						},
						{
							id: 2,
							dominioOptionId: 2,
							titulo: "Intensidade 1",
							informacoes:
								"Há indicativo de dano leve na fundação devido à inundação ou pré-existente, como erosão do solo e exposição superficial da fundação (pilaretes, blocos de fundação, vigas de baldrame), porém não é visível a base de apoio da fundação (estacas, sapatas, fundação superficial).",
							valor: 1,
						},
						{
							id: 3,
							dominioOptionId: 3,
							titulo: "Intensidade 2",
							informacoes:
								"Há indicativo de dano moderado na fundação devido à inundação ou pré-existente, como erosão e exposição da base de apoio da fundação (estacas, sapatas, fundação superficial, entre outras), que podem reduzir parcialmente a segurança da fundação.",
							valor: 2,
						},
						{
							id: 4,
							dominioOptionId: 4,
							titulo: "Intensidade 3",
							informacoes:
								"Há indicativo de dano grave na fundação devido à inundação ou pré-existente, como erosão profunda (abaixo da base de apoio da fundação), movimentação do solo ou indicativo, recalque diferencial causado pelo solo erodido, fissura(s) na edificação que indicam movimentação da fundação, comprometimento de muro(s) de contenção ou outros indicativos que comprometem significativamente a segurança da fundação.",
							valor: 3,
						},
						{
							id: 5,
							dominioOptionId: 5,
							titulo: "Intensidade 4",
							informacoes:
								" Há indicativo de dano severo na fundação devido à inundação ou pré-existente, como indicativo de ruptura, deformação excessiva, recalque, movimentação, perda da base de apoio da fundação ou outros indicativos que causam a perda da segurança da fundação.",
							valor: 4,
						},
						{
							id: 6,
							dominioOptionId: 6,
							titulo: "Intensidade 5",
							informacoes:
								"Há indicativo de dano extremamente severo na fundação devido à inundação ou pré-existente,  como colapso parcial ou total da fundação, que comprometem a integridade da estrutura. Há indicativos de movimentação de encostas e deslizamento de terras.",
							valor: 5,
						},
						{
							id: 7,
							dominioOptionId: 7,
							titulo: "Não avaliada",
							informacoes: "Não foi possível avaliar a fundação.",
							valor: null,
						},
					],
					[
						{ id: 8, dominioOptionId: 9, titulo: "Extensão 0", valor: 0 },
						{ id: 9, dominioOptionId: 10, titulo: "Extensão 1", valor: 1 },
						{ id: 10, dominioOptionId: 11, titulo: "Extensão 2", valor: 2 },
						{ id: 11, dominioOptionId: 12, titulo: "Extensão 3", valor: 3 },
						{ id: 12, dominioOptionId: 13, titulo: "Extensão 4", valor: 4 },
						{ id: 13, dominioOptionId: 14, titulo: "Extensão 5", valor: 5 },
						{ id: 14, dominioOptionId: 15, titulo: "Não avaliada", valor: null },
					],
				],
			},
			{
				id: 18,
				titulo: "Func-Cobertura",
				importanciaTecnica: null,
				importanciaFuncional: 3,
				valorMaximo: 0.464758002,
				opcoes: [
					[
						{
							id: 1,
							dominioOptionId: 1,
							titulo: "Intensidade 0",
							informacoes: "Não há indicativo de dano na fundação pré-existente ou devido à inundação.",
							valor: 0,
						},
						{
							id: 2,
							dominioOptionId: 2,
							titulo: "Intensidade 1",
							informacoes:
								"Há indicativo de dano leve na fundação devido à inundação ou pré-existente, como erosão do solo e exposição superficial da fundação (pilaretes, blocos de fundação, vigas de baldrame), porém não é visível a base de apoio da fundação (estacas, sapatas, fundação superficial).",
							valor: 1,
						},
						{
							id: 3,
							dominioOptionId: 3,
							titulo: "Intensidade 2",
							informacoes:
								"Há indicativo de dano moderado na fundação devido à inundação ou pré-existente, como erosão e exposição da base de apoio da fundação (estacas, sapatas, fundação superficial, entre outras), que podem reduzir parcialmente a segurança da fundação.",
							valor: 2,
						},
						{
							id: 4,
							dominioOptionId: 4,
							titulo: "Intensidade 3",
							informacoes:
								"Há indicativo de dano grave na fundação devido à inundação ou pré-existente, como erosão profunda (abaixo da base de apoio da fundação), movimentação do solo ou indicativo, recalque diferencial causado pelo solo erodido, fissura(s) na edificação que indicam movimentação da fundação, comprometimento de muro(s) de contenção ou outros indicativos que comprometem significativamente a segurança da fundação.",
							valor: 3,
						},
						{
							id: 5,
							dominioOptionId: 5,
							titulo: "Intensidade 4",
							informacoes:
								" Há indicativo de dano severo na fundação devido à inundação ou pré-existente, como indicativo de ruptura, deformação excessiva, recalque, movimentação, perda da base de apoio da fundação ou outros indicativos que causam a perda da segurança da fundação.",
							valor: 4,
						},
						{
							id: 6,
							dominioOptionId: 6,
							titulo: "Intensidade 5",
							informacoes:
								"Há indicativo de dano extremamente severo na fundação devido à inundação ou pré-existente,  como colapso parcial ou total da fundação, que comprometem a integridade da estrutura. Há indicativos de movimentação de encostas e deslizamento de terras.",
							valor: 5,
						},
						{
							id: 7,
							dominioOptionId: 7,
							titulo: "Não avaliada",
							informacoes: "Não foi possível avaliar a fundação.",
							valor: null,
						},
					],
					[
						{ id: 8, dominioOptionId: 9, titulo: "Extensão 0", valor: 0 },
						{ id: 9, dominioOptionId: 10, titulo: "Extensão 1", valor: 1 },
						{ id: 10, dominioOptionId: 11, titulo: "Extensão 2", valor: 2 },
						{ id: 11, dominioOptionId: 12, titulo: "Extensão 3", valor: 3 },
						{ id: 12, dominioOptionId: 13, titulo: "Extensão 4", valor: 4 },
						{ id: 13, dominioOptionId: 14, titulo: "Extensão 5", valor: 5 },
						{ id: 14, dominioOptionId: 15, titulo: "Não avaliada", valor: null },
					],
				],
			},
			{
				id: 19,
				titulo: "Inundação",
				importanciaTecnica: null,
				importanciaFuncional: null,
				valorMaximo: null,
				opcoes: [
					[
						{
							id: 37,
							titulo: "Nível 0",
							informacoes: "Não atingiu a edificação.",
							dominioOptionId: 17,
							valor: 0,
						},
						{
							id: 38,
							titulo: "Nível 1",
							informacoes: "Atingiu somente o pavimento térreo da edificação com altura inferior a 0,5 m (se houver subsolo e foi inundado, considerar nível 4).",
							dominioOptionId: 18,
							valor: 1,
						},
						{
							id: 39,
							titulo: "Nível 2",
							informacoes: "Atingiu somente o pavimento térreo da edificação com altura acima de 0,5 m e abaixo de 1,5 m.",
							dominioOptionId: 19,
							valor: 2,
						},
						{
							id: 40,
							titulo: "Nível 3",
							informacoes:
								" Atingiu somente o pavimento térreo da edificação com altura acima de 1,5 m e abaixo do segundo pavimento. Em edificações de 1 pavimento a inundação ficou abaixo do forro da edificação.",
							dominioOptionId: 20,
							valor: 3,
						},
						{
							id: 41,
							titulo: "Nível 4",
							informacoes:
								"Atingiu o segundo pavimento da edificação. Em edificações de 1 pavimento a inundação ficou acima do forro porém a edificação não foi coberta totalmente pela água.",
							dominioOptionId: 21,
							valor: 4,
						},
						{
							id: 42,
							titulo: "Nível 5",
							informacoes: "Edificação foi totalmente coberta pela inundação (todos os pavimentos).",
							dominioOptionId: 22,
							valor: 5,
						},
					],
				],
			},
		],
	},
	"/api/v1/Parametro/1": {
		data: {
			id: 1,
			titulo: "Fundação",
			importanciaTecnica: 3.5,
			importanciaFuncional: null,
			valorMaximo: 0.585662019,
			opcoes: [
				[
					{
						id: 1,
						dominioOptionId: 1,
						titulo: "Intensidade 0",
						informacoes: "Não há indicativo de dano na fundação pré-existente ou devido à inundação.",
						valor: 0,
					},
					{
						id: 2,
						dominioOptionId: 2,
						titulo: "Intensidade 1",
						informacoes:
							"Há indicativo de dano leve na fundação devido à inundação ou pré-existente, como erosão do solo e exposição superficial da fundação (pilaretes, blocos de fundação, vigas de baldrame), porém não é visível a base de apoio da fundação (estacas, sapatas, fundação superficial).",
						valor: 1,
					},
					{
						id: 3,
						dominioOptionId: 3,
						titulo: "Intensidade 2",
						informacoes:
							"Há indicativo de dano moderado na fundação devido à inundação ou pré-existente, como erosão e exposição da base de apoio da fundação (estacas, sapatas, fundação superficial, entre outras), que podem reduzir parcialmente a segurança da fundação.",
						valor: 2,
					},
					{
						id: 4,
						dominioOptionId: 4,
						titulo: "Intensidade 3",
						informacoes:
							"Há indicativo de dano grave na fundação devido à inundação ou pré-existente, como erosão profunda (abaixo da base de apoio da fundação), movimentação do solo ou indicativo, recalque diferencial causado pelo solo erodido, fissura(s) na edificação que indicam movimentação da fundação, comprometimento de muro(s) de contenção ou outros indicativos que comprometem significativamente a segurança da fundação.",
						valor: 3,
					},
					{
						id: 5,
						dominioOptionId: 5,
						titulo: "Intensidade 4",
						informacoes:
							" Há indicativo de dano severo na fundação devido à inundação ou pré-existente, como indicativo de ruptura, deformação excessiva, recalque, movimentação, perda da base de apoio da fundação ou outros indicativos que causam a perda da segurança da fundação.",
						valor: 4,
					},
					{
						id: 6,
						dominioOptionId: 6,
						titulo: "Intensidade 5",
						informacoes:
							"Há indicativo de dano extremamente severo na fundação devido à inundação ou pré-existente,  como colapso parcial ou total da fundação, que comprometem a integridade da estrutura. Há indicativos de movimentação de encostas e deslizamento de terras.",
						valor: 5,
					},
					{
						id: 7,
						dominioOptionId: 7,
						titulo: "Não avaliada",
						informacoes: "Não foi possível avaliar a fundação.",
						valor: null,
					},
				],
				[
					{ id: 8, dominioOptionId: 9, titulo: "Extensão 0", valor: 0 },
					{ id: 9, dominioOptionId: 10, titulo: "Extensão 1", valor: 1 },
					{ id: 10, dominioOptionId: 11, titulo: "Extensão 2", valor: 2 },
					{ id: 11, dominioOptionId: 12, titulo: "Extensão 3", valor: 3 },
					{ id: 12, dominioOptionId: 13, titulo: "Extensão 4", valor: 4 },
					{ id: 13, dominioOptionId: 14, titulo: "Extensão 5", valor: 5 },
					{ id: 14, dominioOptionId: 15, titulo: "Não avaliada", valor: null },
				],
			],
		},
	},
	"/api/v1/Parametro": {
		data: {
			id: 1,
			titulo: "Fundação",
			importanciaTecnica: 3.5,
			importanciaFuncional: null,
			valorMaximo: 0.585662019,
			opcoes: [
				[
					{
						id: 1,
						dominioOptionId: 1,
						titulo: "Intensidade 0",
						informacoes: "Não há indicativo de dano na fundação pré-existente ou devido à inundação.",
						valor: 0,
					},
					{
						id: 2,
						dominioOptionId: 2,
						titulo: "Intensidade 1",
						informacoes:
							"Há indicativo de dano leve na fundação devido à inundação ou pré-existente, como erosão do solo e exposição superficial da fundação (pilaretes, blocos de fundação, vigas de baldrame), porém não é visível a base de apoio da fundação (estacas, sapatas, fundação superficial).",
						valor: 1,
					},
					{
						id: 3,
						dominioOptionId: 3,
						titulo: "Intensidade 2",
						informacoes:
							"Há indicativo de dano moderado na fundação devido à inundação ou pré-existente, como erosão e exposição da base de apoio da fundação (estacas, sapatas, fundação superficial, entre outras), que podem reduzir parcialmente a segurança da fundação.",
						valor: 2,
					},
					{
						id: 4,
						dominioOptionId: 4,
						titulo: "Intensidade 3",
						informacoes:
							"Há indicativo de dano grave na fundação devido à inundação ou pré-existente, como erosão profunda (abaixo da base de apoio da fundação), movimentação do solo ou indicativo, recalque diferencial causado pelo solo erodido, fissura(s) na edificação que indicam movimentação da fundação, comprometimento de muro(s) de contenção ou outros indicativos que comprometem significativamente a segurança da fundação.",
						valor: 3,
					},
					{
						id: 5,
						dominioOptionId: 5,
						titulo: "Intensidade 4",
						informacoes:
							" Há indicativo de dano severo na fundação devido à inundação ou pré-existente, como indicativo de ruptura, deformação excessiva, recalque, movimentação, perda da base de apoio da fundação ou outros indicativos que causam a perda da segurança da fundação.",
						valor: 4,
					},
					{
						id: 6,
						dominioOptionId: 6,
						titulo: "Intensidade 5",
						informacoes:
							"Há indicativo de dano extremamente severo na fundação devido à inundação ou pré-existente,  como colapso parcial ou total da fundação, que comprometem a integridade da estrutura. Há indicativos de movimentação de encostas e deslizamento de terras.",
						valor: 5,
					},
					{
						id: 7,
						dominioOptionId: 7,
						titulo: "Não avaliada",
						informacoes: "Não foi possível avaliar a fundação.",
						valor: null,
					},
				],
				[
					{ id: 8, dominioOptionId: 9, titulo: "Extensão 0", valor: 0 },
					{ id: 9, dominioOptionId: 10, titulo: "Extensão 1", valor: 1 },
					{ id: 10, dominioOptionId: 11, titulo: "Extensão 2", valor: 2 },
					{ id: 11, dominioOptionId: 12, titulo: "Extensão 3", valor: 3 },
					{ id: 12, dominioOptionId: 13, titulo: "Extensão 4", valor: 4 },
					{ id: 13, dominioOptionId: 14, titulo: "Extensão 5", valor: 5 },
					{ id: 14, dominioOptionId: 15, titulo: "Não avaliada", valor: null },
				],
			],
		},
	},
};
