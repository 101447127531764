import ApiService from "./ApiService";
import { OptionDeleteResponse, OptionGetResponse, OptionPostRequest, OptionPostResponse, OptionPutRequest, OptionPutResponse } from "../entities/OptionEntity";

export default class OptionService extends ApiService {
	public async get(id: number) {
		return await this.request.get<OptionGetResponse>(`/api/v1/Option/${id}`);
	}
	public async post(id: number, data: OptionPostRequest) {
		return await this.request.post<OptionPostResponse>(`/api/v1/Option`, data, { params: { optionGroupId: id } });
	}
	public async put(id: number, data: OptionPutRequest) {
		return await this.request.put<OptionPutResponse>(`/api/v1/Option/${id}`, data);
	}
	public async delete(id: number) {
		return await this.request.delete<OptionDeleteResponse>(`/api/v1/Option/${id}`);
	}
}
