import axios from "axios";

import { UserLoginPostResponseEntity } from "../entities/UserEntity";

import MockService from "./MockService";

export default class ApiService {
	protected request = axios.create({ baseURL: process.env.REACT_APP_API_BASE_URL });
	protected mock = new MockService();

	constructor() {
		let user = this.user();
		this.request.defaults.headers.common["Authorization"] = `Bearer ${user?.hashLogin}`;
		this.request.defaults.headers.common["X-CompanyId"] = `Bearer ${user?.usuario?.empresaId}`;
	}

	protected user() {
		return JSON.parse(sessionStorage.getItem("user") ?? "{}") as UserLoginPostResponseEntity;
	}
}
