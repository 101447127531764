import ApiService from "./ApiService";
import {
	ParameterDeleteResponse,
	ParameterGetParametersResponse,
	ParameterGetResponse,
	ParameterPostRequest,
	ParameterPostResponse,
	ParameterPutRequest,
	ParameterPutResponse,
} from "../entities/ParameterEntity";

export default class ParameterService extends ApiService {
	public async getParameters() {
		return await this.request.get<ParameterGetParametersResponse>(`/api/v1/Parameters`);
	}
	public async get(id: number) {
		return await this.request.get<ParameterGetResponse>(`/api/v1/Parameter/${id}`);
	}
	public async post(data: ParameterPostRequest) {
		return await this.request.post<ParameterPostResponse>(`/api/v1/Parameter`, data);
	}
	public async put(id: number, data: ParameterPutRequest) {
		return await this.request.put<ParameterPutResponse>(`/api/v1/Parameter/${id}`, data);
	}
	public async delete(id: number) {
		return await this.request.delete<ParameterDeleteResponse>(`/api/v1/Parameter/${id}`);
	}
}
