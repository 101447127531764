import { Navigate, useLocation } from "react-router-dom";

import { useAuth } from "../../context/AuthContext";
import { ModuleEntity, ModulePermissionEntity } from "../../config/defines";

type RouteAuthProps = {
	children: JSX.Element;
	module?: ModuleEntity;
	permission?: ModulePermissionEntity;
};

export default function RouteAuth({ children, module, permission }: RouteAuthProps) {
	const { user } = useAuth();
	const location = useLocation();

	let moduleCheck = true;
	if (user && module) {
		moduleCheck = user.modules.some((userModule) => {
			if (permission) {
				return userModule.permissions.some((userPermission) => {
					return userPermission === permission;
				});
			}
			return userModule.id === module;
		});
	}

	if (!user || !moduleCheck) {
		return <Navigate to="/" state={{ from: location }} replace />;
	}

	return children;
}
