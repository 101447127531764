import ApiService from "./ApiService";
import { OptionGroupDeleteResponse, OptionGroupGetResponse, OptionGroupPostRequest, OptionGroupPostResponse, OptionGroupPutRequest, OptionGroupPutResponse } from "../entities/OptionGroupEntity";

export default class OptionGroupService extends ApiService {
	public async get(id: number) {
		return await this.request.get<OptionGroupGetResponse>(`/api/v1/OptionGroup/${id}`);
	}
	public async post(id: number, data: OptionGroupPostRequest) {
		return await this.request.post<OptionGroupPostResponse>(`/api/v1/OptionGroup`, data, { params: { questionId: id } });
	}
	public async put(id: number, data: OptionGroupPutRequest) {
		return await this.request.put<OptionGroupPutResponse>(`/api/v1/OptionGroup/${id}`, data);
	}
	public async delete(id: number) {
		return await this.request.delete<OptionGroupDeleteResponse>(`/api/v1/OptionGroup/${id}`);
	}
}
