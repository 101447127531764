import { useState } from "react";
import { Form, Spinner } from "react-bootstrap";
import { mdiClose, mdiUpload } from "@mdi/js";
import Icon from "@mdi/react";

import { RespostaType } from "../../entities/QuestionnaireEntity";
import { QuestionEntity } from "../../entities/QuestionEntity";

type FormFileProps = {
	pergunta: QuestionEntity;
	resposta: RespostaType | null;
	onChange: (value: RespostaType) => void;
};

const fileEmpty = { name: "", file: "", contentType: "" };

export default function FormFile(props: FormFileProps) {
	const [value, setValue] = useState({ questionId: props.pergunta.id!, files: props.resposta?.files ?? [fileEmpty] });
	const [loading, setLoading] = useState(-1);

	function handleRemover(index: number) {
		let data = { ...value };
		data.files.splice(index, 1);
		setValue(data);
		props.onChange(data);
	}

	function handleAdicionar(event: any, index: number) {
		let file = event.target.files[0];
		if (file) {
			setLoading(index);
			let reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onloadend = () => {
				if (typeof reader.result === "string") {
					let data = { ...value };
					data.files[index] = {
						name: file.name,
						file: reader.result,
						contentType: file.type,
					};
					if (multiple === true && Number(props.pergunta.maxAnswers) > data.files.length && index === data.files.length - 1) {
						data.files.push(fileEmpty);
					}
					setValue(data);
					props.onChange(data);
					setLoading(-1);
				}
			};
		}
	}

	const multiple = Number(props.pergunta.maxAnswers) > 1;

	return (
		<div className={`d-flex flex-wrap gap-2 ${multiple ? "" : "justify-content-center"}`}>
			{value.files.map((file, fileIndex) => {
				return (
					<Form.Label
						className="mb-0"
						key={fileIndex}
						title={file.name}
						onClick={(event) => {
							if (file.name && multiple) {
								event.preventDefault();
								handleRemover(fileIndex);
							}
						}}
						style={{ cursor: "pointer", width: multiple ? 150 : 350, height: multiple ? 150 : 350, borderStyle: "dashed", aspectRatio: 1 }}
					>
						<div className={`d-flex justify-content-center align-items-center`} style={{ width: "100%", height: "100%" }}>
							{loading === fileIndex ? (
								<Spinner className="mb-2" />
							) : (
								<>
									{file.name && (
										<span className="text-muted fw-normal" style={{ wordBreak: "break-all", maxHeight: "100%", overflow: "hidden", textOverflow: "ellipsis" }}>
											{file.name}
										</span>
									)}
									<Icon path={file.name ? mdiClose : mdiUpload} size={2} />
								</>
							)}
						</div>

						<Form.Control
							className="d-none"
							type="file"
							onChange={(e) => {
								handleAdicionar(e, fileIndex);
							}}
						/>
					</Form.Label>
				);
			})}
		</div>
	);
}
