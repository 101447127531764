import { useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import Icon from "@mdi/react";
import { mdiChevronRight, mdiViewDashboardOutline } from "@mdi/js";

import Layout from "../../components/Layout";
import ListCard from "../../components/ListCard";
import { useAuth } from "../../context/AuthContext";
import { screens } from "../../config/screens";

export default function Dashboard() {
	const { user } = useAuth();
	const navigate = useNavigate();

	function handleClick(item: string) {
		navigate(item);
	}

	return (
		<Layout>
			<h5 className="mt-4 mb-4 d-flex align-items-center fw-light">
				<Icon path={mdiViewDashboardOutline} size={1} className="me-1" /> Dashboard
			</h5>

			<Row className="mb-4">
				{screens
					.filter((screen) => screen.showDashboard)
					.filter(
						(screen) =>
							screen.module === null ||
							user?.modules?.some((module) => {
								return module.id === screen.module;
							})
					)
					.map((screen, screenKey) => {
						return (
							<Col lg={6} key={screenKey} className="mb-2">
								<ListCard
									onClick={() => {
										handleClick(screen.route);
									}}
								>
									<ListCard.Icon>
										<Icon path={screen.icon} size={1} className="text-muted me-3" />
									</ListCard.Icon>
									<ListCard.Content>
										<div className="flex-fill me-2">
											<div className="text-muted fw-lighter">{screen.name}</div>
											<div className="fw-light">{screen.description}</div>
										</div>
									</ListCard.Content>
									<ListCard.Action>
										<Icon path={mdiChevronRight} size={1} className="text-muted m-2" />
									</ListCard.Action>
								</ListCard>
							</Col>
						);
					})}
			</Row>
		</Layout>
	);
}
