import ApiService from "./ApiService";
import {
	ParameterDomainDeleteResponse,
	ParameterDomainGetResponse,
	ParameterDomainPostRequest,
	ParameterDomainPostResponse,
	ParameterDomainPutRequest,
	ParameterDomainPutResponse,
} from "../entities/ParameterDomainEntity";

export default class ParameterDomainService extends ApiService {
	public async get(id: number) {
		return await this.request.get<ParameterDomainGetResponse>(`/api/v1/ParameterDomain/${id}`);
	}
	public async post(id: number, data: ParameterDomainPostRequest) {
		return await this.request.post<ParameterDomainPostResponse>(`/api/v1/ParameterDomain`, data, { params: { parameterId: id } });
	}
	public async put(id: number, data: ParameterDomainPutRequest) {
		return await this.request.put<ParameterDomainPutResponse>(`/api/v1/ParameterDomain/${id}`, data);
	}
	public async delete(id: number) {
		return await this.request.delete<ParameterDomainDeleteResponse>(`/api/v1/ParameterDomain/${id}`);
	}
}
