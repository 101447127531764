import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import GoogleMap, { Map } from "google-maps-react-markers";
import Select from "react-select";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { fromLatLng, fromPlaceId } from "react-geocode";

import { SelectStyle } from "../../config/select";
import { Location } from "../../config/defines";
import { QuestionEntity } from "../../entities/QuestionEntity";
import { RespostaType } from "../../entities/QuestionnaireEntity";
import { useAuth } from "../../context/AuthContext";

type FormGeoProps = {
	pergunta: QuestionEntity;
	resposta: RespostaType | null;
	onChange: (value: RespostaType) => void;
};

export default function FormGeo(props: FormGeoProps) {
	const { geolocation } = useAuth();

	// eslint-disable-next-line
	const [value, setValue] = useState(props.resposta);
	const [select, setSelect] = useState(
		props.resposta && props.resposta.text && props.resposta.complement
			? { label: props.resposta.text, value: { lat: Number(props.resposta.complement.split(";")[0]), lng: Number(props.resposta.complement.split(";")[1]) } }
			: undefined
	);
	const [map, setMap] = useState<google.maps.Map>();
	const [marker, setMarker] = useState<google.maps.Marker>();
	const { placePredictions, getPlacePredictions, isPlacePredictionsLoading } = usePlacesService({
		apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
		debounce: 500,
	});

	useEffect(() => {
		if (geolocation) {
			fromLatLng(geolocation.coords.latitude, geolocation.coords.longitude, process.env.REACT_APP_GOOGLE_MAPS_API_KEY, "pt-BR")
				.then((response) => {
					let location = { lat: response.results[0].geometry.location.lat, lng: response.results[0].geometry.location.lng };
					handleValue({ text: response.results[0].formatted_address, ...location });
				})
				.catch((error) => {
					console.error(error);
				});
		}
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (select && map && marker) {
			let location = { lat: select.value.lat, lng: select.value.lng };
			map.setCenter(location);
			marker.setPosition(location);
		}
	}, [select, map, marker]);

	function handleMapLoad({ map }: { map: Map }) {
		let marker = new google.maps.Marker();
		marker.setPosition(select ? { lat: select?.value.lat, lng: select?.value.lng } : Location);
		marker.setMap(map);
		marker.setDraggable(true);
		marker.addListener("dragend", (e: any) => {
			fromLatLng(e.latLng.lat(), e.latLng.lng(), process.env.REACT_APP_GOOGLE_MAPS_API_KEY, "pt-BR")
				.then((response) => {
					let location = { lat: response.results[0].geometry.location.lat, lng: response.results[0].geometry.location.lng };
					handleValue({ text: response.results[0].formatted_address, ...location });
				})
				.catch((error) => {
					console.error(error);
				});
		});

		setMap(map);
		setMarker(marker);
	}

	function handlePlaceId(placeId: string) {
		fromPlaceId(placeId, process.env.REACT_APP_GOOGLE_MAPS_API_KEY, "pt-BR")
			.then((response) => {
				let location = { lat: response.results[0].geometry.location.lat, lng: response.results[0].geometry.location.lng };
				handleValue({ text: response.results[0].formatted_address, ...location });
			})
			.catch((error) => {
				console.error(error);
			});
	}

	function handleValue(value: { text: string; lat: number; lng: number }) {
		let data = { questionId: props.pergunta.id!, text: value.text, complement: `${value.lat};${value.lng}` };
		setSelect({ label: value.text, value: { lat: value.lat, lng: value.lng } });
		setValue(data);
		props.onChange(data);
	}

	return (
		<div>
			<Select
				placeholder={"Digite aqui o endereço"}
				className="mb-1"
				noOptionsMessage={(value) => {
					return value.inputValue.length === 0 ? "Digite para buscar" : "Nenhuma opção disponível";
				}}
				loadingMessage={() => {
					return (
						<>
							<Spinner size="sm" /> Carregando opções
						</>
					);
				}}
				options={placePredictions.map((prediction) => {
					return { label: prediction.description, value: prediction.place_id };
				})}
				filterOption={() => {
					return true;
				}}
				isLoading={isPlacePredictionsLoading}
				onInputChange={(e) => {
					getPlacePredictions({ input: e, language: "pt-BR", componentRestrictions: { country: "BR" } });
				}}
				onChange={(e: any) => {
					if (e?.value) {
						handlePlaceId(e.value);
					}
				}}
				value={select}
				autoFocus={true}
				styles={SelectStyle}
			/>
			<div className="rounded-3 overflow-hidden border">
				<GoogleMap
					apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
					defaultZoom={15}
					defaultCenter={select ? { lat: select?.value.lat, lng: select?.value.lng } : Location}
					options={{
						fullscreenControl: false,
						streetViewControl: false,
						zoomControlOptions: { position: 21 },
						mapTypeControlOptions: { position: 20 },
						mapTypeId: "roadmap",
					}}
					onGoogleApiLoaded={handleMapLoad}
					style={{ height: 350 }}
				/>
			</div>
		</div>
	);
}
