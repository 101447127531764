import { ReactNode } from "react";
import { Container } from "react-bootstrap";

type MainProps = {
	children: ReactNode | ReactNode[];
};

export default function Main({ children }: MainProps) {
	return (
		<Container fluid className="flex-fill px-3">
			{children}
		</Container>
	);
}
