import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Alert, Button, Card, Col, Form, InputGroup, Row, Spinner } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useInfiniteQuery } from "react-query";
import moment, { Moment } from "moment";
import Icon from "@mdi/react";
import { mdiCardRemoveOutline, mdiFilter, mdiFilterOutline, mdiOfficeBuildingOutline } from "@mdi/js";

import { useToast } from "../../context/ToastContext";
import { PageSize, SkeletonCount } from "../../config/defines";
import { QuestionnaireResponseGetResponse } from "../../entities/QuestionnaireResponseEntity";
import QuestionnaireResponseService from "../../services/QuestionnaireResponseService";

import InfiniteScroll from "../../components/InfiniteScroll";
import AvaliacaoCard from "../../components/AvaliacaoCard";
import Layout from "../../components/Layout";

const toastTitle = "Avaliações";

export default function Avaliacoes() {
	const questionnaireResponseService = new QuestionnaireResponseService();

	const navigate = useNavigate();
	useToast(toastTitle);

	const [filtro, setFiltro] = useState(false);
	const [filtroTexto, setFiltroTexto] = useState("");
	const [filtroDataInicial, setFiltroDataInicial] = useState<Moment | null>();
	const [filtroDataFinal, setFiltroDataFinal] = useState<Moment | null>();

	const [queryFiltro, setQueryFiltro] = useState<{ filtro?: string; periodoInicial?: string; periodoFinal?: string }>();
	const { data, isLoading, isRefetching, isError, isFetchingNextPage, hasNextPage, fetchNextPage } = useInfiniteQuery<QuestionnaireResponseGetResponse[]>(
		["avaliacoesRespostas", queryFiltro],
		({ pageParam = null }) => fetchData(pageParam),
		{ getNextPageParam: (lastPage) => (lastPage.length === PageSize ? lastPage[lastPage.length - 1].id : null) }
	);

	async function fetchData(pagina: number) {
		const resp = await questionnaireResponseService.getQuestionnaireResponses({ "X-Cursor": pagina, "X-PageSize": PageSize });
		return resp.data;
	}

	async function fetchMoreData() {
		fetchNextPage();
	}

	function handleFiltro() {
		setFiltro(!filtro);
	}

	function handleAplicar() {
		setQueryFiltro({
			filtro: filtroTexto,
			periodoInicial: filtroDataInicial?.toISOString(),
			periodoFinal: filtroDataFinal?.toISOString(),
		});
	}

	return (
		<Layout header={{ exibirAplicativos: true }}>
			<h5 className="mt-4 mb-4 d-flex align-items-center fw-light">
				<Icon path={mdiOfficeBuildingOutline} size={1} className="me-1" /> Avaliações
				{isRefetching && <Spinner size="sm" className="ms-1" variant="secondary" />}
				<div className="d-flex gap-2 float-right ms-auto" style={{ marginTop: -10, marginBottom: -10 }}>
					<Button className="shadow-sm rounded-3" variant="light" onClick={handleFiltro}>
						<Icon path={filtro ? mdiFilter : mdiFilterOutline} size={1} />
					</Button>
					<Button
						variant="primary"
						className="rounded-3 shadow-sm"
						onClick={() => {
							navigate("/avaliacaoFormulario/1");
						}}
					>
						Cadastrar
					</Button>
				</div>
			</h5>

			{filtro && (
				<Row>
					<Col>
						<Card className="mb-4">
							<Card.Body>
								<Row>
									<Col>
										<Row>
											<Col md={6}>
												<InputGroup>
													<InputGroup.Text className="bg-white">Filtro</InputGroup.Text>
													<Form.Control
														placeholder="Busca por titulo e alerta"
														value={filtroTexto}
														onChange={(event) => {
															setFiltroTexto(event.target.value);
														}}
														disabled={isLoading}
													/>
												</InputGroup>
											</Col>
											<Col md={6}>
												<InputGroup>
													<InputGroup.Text className="bg-white">Periodo</InputGroup.Text>
													<DatePicker
														placeholderText="Data inicial e final"
														className="form-control"
														calendarClassName="dropdown-menu show shadow-lg"
														weekDayClassName={(date) => {
															return "fw-light";
														}}
														dayClassName={(date) => {
															let btn = "btn-light";
															if (filtroDataInicial && filtroDataFinal && date.getTime() >= filtroDataInicial.valueOf() && date.getTime() <= filtroDataFinal.valueOf()) {
																btn = "btn-primary";
															}
															return `btn btn-sm ${btn} `;
														}}
														popperModifiers={[
															{
																name: "arrow",
																options: {
																	padding: ({ popper, reference, placement }) => ({
																		right: Math.min(popper.width, reference.width) - Math.min(popper.width, reference.width) + 24,
																	}),
																},
															},
														]}
														onChange={(datas) => {
															setFiltroDataInicial(datas[0] ? moment(datas[0]) : null);
															setFiltroDataFinal(datas[1] ? moment(datas[1]).hours(23).minutes(59).seconds(59) : null);
														}}
														startDate={filtroDataInicial?.toDate()}
														endDate={filtroDataFinal?.toDate()}
														dateFormat="dd/MM/yyyy"
														selectsRange
														disabled={isLoading}
													/>
												</InputGroup>
											</Col>
										</Row>
									</Col>
									<Col sm={12} md="auto">
										<Button variant="primary" onClick={handleAplicar} disabled={isLoading}>
											Aplicar
										</Button>
									</Col>
								</Row>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			)}

			<Row className="mb-4">
				{isLoading &&
					Array.from({ length: Number(SkeletonCount) }, (_, index) => {
						return (
							<Col md={12} key={index}>
								<AvaliacaoCard skeleton />
							</Col>
						);
					})}
				{!isLoading && isError && (
					<Col md={12}>
						<Alert variant="secondary" className="text-center">
							Problema na operação. Tente novamente mais tarde
						</Alert>
					</Col>
				)}
				{!isLoading && data?.pages[0] && data?.pages[0].length === 0 && (
					<Col md={12}>
						<Alert variant="light" className="text-center p-4 d-flex justify-content-center align-items-center">
							<Icon path={mdiCardRemoveOutline} size={1} className="me-2" />
							Nenhum registro encontrado
						</Alert>
					</Col>
				)}
				{!isLoading &&
					data?.pages.map((dataPage, dataPageIndex) => {
						return (
							<React.Fragment key={dataPageIndex}>
								{dataPage.map((item, index) => {
									return (
										<Col md={12} key={index}>
											<AvaliacaoCard data={item} />
										</Col>
									);
								})}
							</React.Fragment>
						);
					})}
				<Col md={12} className="text-center">
					<InfiniteScroll more={hasNextPage} load={fetchMoreData} loader={<AvaliacaoCard skeleton />} loading={isFetchingNextPage} />
				</Col>
			</Row>
		</Layout>
	);
}
