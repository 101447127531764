import { mdiAccountGroupOutline, mdiAccountMultipleOutline, mdiCogOutline, mdiLockOutline, mdiOfficeBuildingOutline, mdiViewDashboardOutline } from "@mdi/js";
import { ModuleEntity } from "./defines";

export const screens = [
	{
		module: null,
		name: "Dashboard",
		description: "",
		icon: mdiViewDashboardOutline,
		route: "/dashboard",
		showSidebar: true,
		showDashboard: false,
	},
	{
		module: ModuleEntity.avaliacoes,
		name: "Avaliações",
		description: "Lista de avaliações de edificações",
		icon: mdiOfficeBuildingOutline,
		route: "/avaliacoes",
		showSidebar: true,
		showDashboard: true,
	},
	{
		module: ModuleEntity.configuracoes,
		name: "Configurações",
		description: "Lista de configurações",
		icon: mdiCogOutline,
		route: "/configuracoes",
		showSidebar: true,
		showDashboard: true,
	},
	{
		module: ModuleEntity.acessos,
		name: "Acessos",
		description: "",
		icon: mdiLockOutline,
		route: "/acessos",
		showSidebar: true,
		showDashboard: false,
	},
	{
		module: ModuleEntity.acessos,
		name: "Grupos",
		description: "Lista de Grupos de usuários para gerenciar permissões",
		icon: mdiAccountGroupOutline,
		route: "/grupos",
		showSidebar: false,
		showDashboard: true,
	},
	{
		module: ModuleEntity.acessos,
		name: "Usuários",
		description: "Lista de usuários do sistema",
		icon: mdiAccountMultipleOutline,
		route: "/usuarios",
		showSidebar: false,
		showDashboard: true,
	},
];
