import ApiService from "./ApiService";
import {
	DomainOptionDeleteResponse,
	DomainOptionGetResponse,
	DomainOptionPostRequest,
	DomainOptionPostResponse,
	DomainOptionPutRequest,
	DomainOptionPutResponse,
} from "../entities/DomainOptionEntity";

export default class DomainOptionService extends ApiService {
	public async get(id: number) {
		return await this.request.get<DomainOptionGetResponse>(`/api/v1/DomainOption/${id}`);
	}
	public async post(id: number, data: DomainOptionPostRequest) {
		return await this.request.post<DomainOptionPostResponse>(`/api/v1/DomainOption`, data, { params: { domainId: id } });
	}
	public async put(id: number, data: DomainOptionPutRequest) {
		return await this.request.put<DomainOptionPutResponse>(`/api/v1/DomainOption/${id}`, data);
	}
	public async delete(id: number) {
		return await this.request.delete<DomainOptionDeleteResponse>(`/api/v1/DomainOption/${id}`);
	}
}
