import ApiService from "./ApiService";
import { QuestionDeleteResponse, QuestionGetResponse, QuestionPostRequest, QuestionPostResponse, QuestionPutRequest, QuestionPutResponse } from "../entities/QuestionEntity";

export default class QuestionService extends ApiService {
	public async get(id: number) {
		return await this.request.get<QuestionGetResponse>(`/api/v1/Question/${id}`);
	}
	public async post(id: number, data: QuestionPostRequest) {
		return await this.request.post<QuestionPostResponse>(`/api/v1/Question`, data, { params: { questionGroupId: id } });
	}
	public async put(id: number, data: QuestionPutRequest) {
		return await this.request.put<QuestionPutResponse>(`/api/v1/Question/${id}`, data);
	}
	public async delete(id: number) {
		return await this.request.delete<QuestionDeleteResponse>(`/api/v1/Question/${id}`);
	}
}
