import { ReactNode } from "react";

type BodyProps = {
	children: ReactNode;
};

export default function Body({ children }: BodyProps) {
	return (
		<div className="d-flex flex-column flex-md-row" style={{ minHeight: "100vh" }}>
			{children}
		</div>
	);
}
