import { useState } from "react";
import { Form, Image, Spinner } from "react-bootstrap";
import { mdiUpload } from "@mdi/js";
import Icon from "@mdi/react";

import { RespostaType } from "../../entities/QuestionnaireEntity";
import { QuestionEntity } from "../../entities/QuestionEntity";

type FormImageProps = {
	pergunta: QuestionEntity;
	resposta: RespostaType | null;
	onChange: (value: RespostaType) => void;
};

const fileEmpty = { name: "", file: "", contentType: "" };

export default function FormImage(props: FormImageProps) {
	const [value, setValue] = useState({ questionId: props.pergunta.id!, files: props.resposta?.files ?? [fileEmpty] });
	const [loading, setLoading] = useState(-1);

	function handleRemover(index: number) {
		let data = { ...value };
		data.files.splice(index, 1);
		setValue(data);
		props.onChange(data);
	}

	function handleAdicionar(event: any, index: number) {
		let file = event.target.files[0];
		if (file) {
			setLoading(index);
			let reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onloadend = () => {
				if (typeof reader.result === "string") {
					let data = { ...value };
					data.files[index] = {
						name: file.name,
						file: reader.result,
						contentType: file.type,
					};
					if (multiple === true && Number(props.pergunta.maxAnswers) > data.files.length && index === data.files.length - 1) {
						data.files.push(fileEmpty);
					}
					setValue(data);
					props.onChange(data);
					setLoading(-1);
				}
			};
		}
	}

	const multiple = Number(props.pergunta.maxAnswers) > 1;

	return (
		<div className={`d-flex flex-wrap gap-2 ${multiple ? "" : "justify-content-center"}`}>
			{value.files.map((file, fileIndex) => {
				return (
					<Form.Label
						className="mb-2 p-2 bg-white rounded-3 border-card"
						key={fileIndex}
						title={file.name}
						onClick={(event) => {
							if (file.name && multiple) {
								event.preventDefault();
								handleRemover(fileIndex);
							}
						}}
						style={{ cursor: "pointer", width: multiple ? 150 : 350, height: multiple ? 150 : 350, borderStyle: "dashed", aspectRatio: 1 }}
					>
						<div className={`d-flex justify-content-center align-items-center`} style={{ width: "100%", height: "100%" }}>
							{loading === fileIndex ? (
								<Spinner className="mb-2" />
							) : (
								<>{file.name ? <Image src={file.file} style={{ maxWidth: "100%", maxHeight: "100%", objectFit: "cover" }} /> : <Icon path={mdiUpload} size={2} />}</>
							)}
						</div>

						<Form.Control
							className="d-none"
							type="file"
							accept="image/*"
							onChange={(e) => {
								handleAdicionar(e, fileIndex);
							}}
						/>
					</Form.Label>
				);
			})}
		</div>
	);
}
