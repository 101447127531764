import ApiService from "./ApiService";
import {
	QuestionGroupDeleteResponse,
	QuestionGroupGetResponse,
	QuestionGroupPostRequest,
	QuestionGroupPostResponse,
	QuestionGroupPutRequest,
	QuestionGroupPutResponse,
} from "../entities/QuestionGroupEntity";

export default class QuestionGroupService extends ApiService {
	public async get(id: number) {
		return await this.request.get<QuestionGroupGetResponse>(`/api/v1/QuestionGroup/${id}`);
	}
	public async post(id: number, data: QuestionGroupPostRequest) {
		return await this.request.post<QuestionGroupPostResponse>(`/api/v1/QuestionGroup/`, data, { params: { questionnaireId: id } });
	}
	public async put(id: number, data: QuestionGroupPutRequest) {
		return await this.request.put<QuestionGroupPutResponse>(`/api/v1/QuestionGroup/${id}`, data);
	}
	public async delete(id: number) {
		return await this.request.delete<QuestionGroupDeleteResponse>(`/api/v1/QuestionGroup/${id}`);
	}
}
